import { Source } from "../Data/Source";
import { TheClaw } from "../Data/TheClaw";
import { useSubscribableCollection } from "./useSubscribable";
/**
 *
 * Causes re-render when any item in TaskMetadatas changes
 * @param source
 */
export function useTaskMetadatas(source?: Source) {
	return useSubscribableCollection(TheClaw.TaskMetadatas, source);
}
