import React, { RefObject } from "react";
import { TreeContextModel } from "./TreeContextModel";
import { TreeContextAction } from "./TreeContextAction";
import { TreeContextDispatchActionType } from "./TreeContextDispatchActionType";
import { TreeContextProviderProps } from "./TreeContext.Interface";
import { Dictionary } from "../../Data/Dictionary";

function Add(
	data: { ref: RefObject<HTMLElement>; taskEntryGUID: string },
	timeEntryRefs: Dictionary<RefObject<HTMLElement>>
) {
	timeEntryRefs[data.taskEntryGUID] = data.ref;
	return timeEntryRefs;
}

function Remove(
	data: { ref: RefObject<HTMLElement>; taskEntryGUID: string },
	timeEntryRefs: Dictionary<RefObject<HTMLElement>>
) {
	delete timeEntryRefs[data.taskEntryGUID];
	return timeEntryRefs;
}

export const TreeContext = React.createContext({
	state: new TreeContextModel(),
	dispatch: {} as React.Dispatch<TreeContextAction>,
});

const TreeContextReducer = (state: TreeContextModel, action: TreeContextAction) => {
	switch (action.type) {
		case TreeContextDispatchActionType.SetActiveTaskRef:
			return { ...state, activeTaskRef: action.payload };
		case TreeContextDispatchActionType.SetTimeEntryRefs:
			return { ...state, timeEntryRefs: action.payload };
		case TreeContextDispatchActionType.AddRef:
			return {
				...state,
				timeEntryRefs: Add(action.payload, state.timeEntryRefs ? state.timeEntryRefs : {}),
			};
		case TreeContextDispatchActionType.RemoveRef:
			return {
				...state,
				timeEntryRefs: Remove(action.payload, state.timeEntryRefs ? state.timeEntryRefs : {}),
			};
		// Task list y and timeline y are filled from useScroll hooks (could easily be replaced with a onScroll or handler)
		// The two hooks are in the timeline container, and the task entry controller.
		// The currnet sole purpose of these is to properly update the conencting lines when scrolling.
		default:
			return { ...state };
	}
};

export const TreeContextProvider = (props: TreeContextProviderProps) => {
	const [state, dispatch] = React.useReducer(TreeContextReducer, {
		timeEntryRefs: null,
		activeTaskRef: null,
	});

	const value = { state, dispatch };

	return <TreeContext.Provider value={value}>{props.children}</TreeContext.Provider>;
};
