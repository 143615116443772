import { DateTime, Duration } from "luxon";
import { Guid } from "../Data/Guid";
import { ITimeEntry } from "../Data/Models/ITimeEntry";
import { KeyHelper } from "../Data/KeyHelper";
import { InstanceManager } from "../Data/InstanceManager";
import { TheClaw } from "../Data/TheClaw";
export class TimeCalculationHelpers {
	static GetTotalDayTime(dayOffset: number, dayStartOffsetHours: number): Duration {
		const todaysTimeEntries =
			TheClaw.TimeEntries.GetSetDaysEntries(
				InstanceManager.timeSource.GetLocalTime().minus({ days: dayOffset }),
				dayStartOffsetHours
			) || [];
		return Duration.fromMillis(
			todaysTimeEntries.reduce(
				(accumulator: number, timeEntry: ITimeEntry) =>
					accumulator +
					(timeEntry.endedWhen ? timeEntry.endedWhen : InstanceManager.timeSource.GetLocalTime()).diff(
						timeEntry.startedWhen,
						"milliseconds"
					).milliseconds,
				0
			)
		);
	}
	static GetTotalWeekTime(dayStartOffsetHours: number): Duration {
		const todaysTimeEntries =
			TheClaw.TimeEntries.GetDaysEntries(
				InstanceManager.timeSource.GetLocalTime().startOf("week"),
				InstanceManager.timeSource.GetLocalTime().endOf("week"),
				dayStartOffsetHours
			) || [];
		return Duration.fromMillis(
			todaysTimeEntries.reduce(
				(accumulator: number, timeEntry: ITimeEntry) =>
					accumulator +
					(timeEntry.endedWhen ? timeEntry.endedWhen : InstanceManager.timeSource.GetLocalTime()).diff(
						timeEntry.startedWhen,
						"milliseconds"
					).milliseconds,
				0
			)
		);
	}
	static GetGroupsDayOffset(timeEntrySetGuid: Guid, currentTime: DateTime) {
		const timeEntry = TheClaw.TimeEntries.GetForGroup(timeEntrySetGuid)
			.sortBy((x) => x.startedWhen)
			.first(undefined);
		if (timeEntry) {
			const dayDiff = currentTime
				.toLocal()
				.startOf("day")
				.diff(timeEntry.startedWhen.toLocal().startOf("day"), "days").days;
			if (dayDiff !== Math.ceil(dayDiff)) {
				throw new Error(
					"Day offset calculation yielded fractional day, which means there's something wrong with the calculation logic"
				);
			}
			return dayDiff;
		}
		// Returns -1 if fail since the checks that will use this groups day offset will check every day when given -1
		return -1;
	}

	static GroupInSameDay(timeEntrySetGuid: Guid, timeToCheck: DateTime) {
		const timeEntry = TheClaw.TimeEntries.GetForGroup(timeEntrySetGuid)
			.sortBy((x) => x.startedWhen)
			.first(undefined);

		if (timeEntry) {
			// Beware: hasSame uses the timezone of the left-hand value
			// See https://github.com/moment/luxon/issues/584
			return timeToCheck.hasSame(timeEntry.startedWhen, "day");
		}
		return false;
	}

	static GetTotalGroupTime(TimeEntrySetGuid: Guid, dayStartOffsetHours: number, useRounding?: boolean): Duration {
		// NOTE: A negative 'forSetDayOffset' will check all days rather than just a set day
		// 		An undefined 'forSetDayOffset' will return current day only
		const currentTime = InstanceManager.timeSource.GetLocalTime();
		const forSetDayOffset = TimeCalculationHelpers.GetGroupsDayOffset(TimeEntrySetGuid, currentTime);
		const todaysTimeEntries =
			forSetDayOffset > 0
				? TheClaw.TimeEntries.GetSetDaysEntries(currentTime.minus({ days: forSetDayOffset }), dayStartOffsetHours)
				: TheClaw.TimeEntries.GetTodaysEntries(dayStartOffsetHours);
		let groupTime = todaysTimeEntries
			.filter((timeEntry) => timeEntry.timeEntrySetGuid === TimeEntrySetGuid)
			.reduce(
				(accumulator: Duration, timeEntry: ITimeEntry) =>
					accumulator.plus(
						(timeEntry.endedWhen ? timeEntry.endedWhen : currentTime).diff(timeEntry.startedWhen, "seconds")
					),
				Duration.fromMillis(0)
			)
			.shiftTo("seconds");

		// Disabled due to bugs
		// TODO: Reinstate rounding if needed
		// if (useRounding) {
		// 	const timeEntrySet = TheClaw.Groups.Get(TimeEntrySetGuid);
		// 	const task = timeEntrySet ? TheClaw.Tasks.Get(KeyHelper.GetTimeEntrySetTaskKey(timeEntrySet)) : undefined;
		// 	const integrationSetting = task ? TheClaw.IntegrationSettings.Get(task.integrationGuid) : undefined;
		// 	if (integrationSetting && integrationSetting.roundingInterval) {
		// 		// time left over rounding interval in seconds
		// 		const remainder = Math.floor(groupTime.seconds % integrationSetting.roundingInterval);
		// 		if (integrationSetting.roundingThreshold && remainder < integrationSetting.roundingThreshold) {
		// 			// If there is a rounding threshold and the remainder is less than it, round down to nearest rounding interval
		// 			groupTime = groupTime.minus(Duration.fromObject({ seconds: remainder }));
		// 		} else {
		// 			// If there is no rounding threshold, or the remainder is greater than it, round up to nearest rounding interval
		// 			groupTime = groupTime.minus(
		// 				Duration.fromObject({ seconds: remainder + (remainder > 0 ? integrationSetting.roundingInterval : 0) })
		// 			);
		// 		}
		// 	}
		// }
		return groupTime;
	}
}
