import { ITimeEntry } from "../Data/Models/ITimeEntry";
import { Guid } from "../Data/Guid";
import { InstanceManager } from "../Data/InstanceManager";
import { ITimeEntrySet } from "../Data/Models/ITimeEntrySet";
import { v4 as uuid } from "uuid";
import { TheClaw } from "../Data/TheClaw";
import { DateTime } from "luxon";

declare global {
	interface Window {
		makeOverrunTimeEntry: () => void;
	}
}

function NewGroup(TimeEntrySetGuid?: Guid) {
	if (InstanceManager.auth.UserId) {
		// TODO: Fix new group values

		const newGroup: ITimeEntrySet = {
			name: "wootikins",
			timeEntrySetGuid: TimeEntrySetGuid ? TimeEntrySetGuid : uuid(),
			createdWhen: InstanceManager.timeSource.GetUtcTime(),
			lastUpdatedWhen: InstanceManager.timeSource.GetUtcTime(),
			userId: InstanceManager.auth.UserId,
			timeEntrySetStatusId: 0, // ??
			timeEntrySetBillingStatusId: 0, // ??
			metadata: "", // ??
			exportAttemptCount: 0,
			exportMaxAttemptsReached: false,
		};

		TheClaw.Groups.Set(newGroup.timeEntrySetGuid, newGroup, "woot");

		return newGroup;
	}
}

function CreateNewTimeEntry(
	TimeEntrySetGuid?: Guid,
	StartedWhen: DateTime = InstanceManager.timeSource.GetUtcTime(),
	EndedWhen?: DateTime
) {
	const thisTimeEntrySetGuid = TimeEntrySetGuid ? TimeEntrySetGuid : uuid();
	const newTimeEntryGuid = uuid();

	const localTime = InstanceManager.timeSource.GetLocalTime();

	const newEntry: ITimeEntry = {
		timeEntryGuid: newTimeEntryGuid,
		timeEntrySetGuid: thisTimeEntrySetGuid,
		startedWhen: StartedWhen.toLocal(),
		endedWhen: EndedWhen?.toLocal(),
		lastUpdatedWhen: InstanceManager.timeSource.GetUtcTime(),
		comment: "",
		timeZone: localTime.zoneName,
		timeOffsetMinutes: localTime.offset,
	};

	let createNewGroup = true;
	if (TimeEntrySetGuid) {
		const group = TheClaw.Groups.Get(TimeEntrySetGuid);
		if (group) {
			createNewGroup = false;
		}
	}

	if (createNewGroup) NewGroup(thisTimeEntrySetGuid);

	TheClaw.TimeEntries.Set(newEntry.timeEntryGuid, newEntry, "woot");

	return newEntry;
}

window.makeOverrunTimeEntry = () => {
	CreateNewTimeEntry(
		undefined,
		InstanceManager.timeSource
			.GetLocalTime()
			.startOf("day")
			.minus({ hours: 2 }),
		undefined
	);
};
