import { SubscribableCollection } from "./Subscribable";
import { DateTime, Interval } from "luxon";
import { ITimeEntry } from "./Models/ITimeEntry";
import { Guid } from "./Guid";
import { List } from "immutable";
import { InstanceManager } from "./InstanceManager";

export class TimeEntrySubscribable extends SubscribableCollection<ITimeEntry> {
	public Remove(key: string, source: string): void {
		const item = super.Get(key);
		if (!item) throw new Error(`Unable to load time entry with key '${key}' to remove`);
		item.lastUpdatedWhen = item.deletedWhen = InstanceManager.timeSource.GetUtcTime();
		super.Set(key, item, source);
	}

	private LastAllNotDeleted: List<ITimeEntry> | undefined;
	private LastAllNotDeletedDate: DateTime | undefined;
	public AllNotDeleted(): List<ITimeEntry> {
		if (
			this.LastAllNotDeleted &&
			this.LastAllNotDeletedDate &&
			+this.LastAllNotDeletedDate === +this.LastUpdatedWhen &&
			this.LastAllNotDeletedDate.hasSame(InstanceManager.timeSource.GetLocalTime(), "day")
		) {
			return this.LastAllNotDeleted;
		}

		this.LastAllNotDeleted = List(this.All().filter((te) => !te.deletedWhen));
		this.LastAllNotDeletedDate = this.LastUpdatedWhen;

		return this.LastAllNotDeleted;
	}

	public GetRunningEntry(dayStartOffsetHours: number): ITimeEntry | undefined {
		// If there are more than one entry with no endedWhen, we've got bigger issues
		return this.GetTodaysEntries(dayStartOffsetHours)
			.filter((x) => !x.endedWhen)
			.first();
	}

	private LastGetTodaysLatestEntry: ITimeEntry | undefined;
	private LastGetTodaysLatestEntryDate: DateTime | undefined;
	private LastGetTodaysLatestEntryOffset: number | undefined;
	public GetTodaysLatestEntry(dayStartOffsetHours: number): ITimeEntry | undefined {
		if (
			this.LastGetTodaysLatestEntryDate &&
			this.LastGetTodaysLatestEntryOffset &&
			+this.LastGetTodaysLatestEntryDate === +this.LastUpdatedWhen &&
			this.LastGetTodaysLatestEntryOffset === dayStartOffsetHours &&
			this.LastGetTodaysLatestEntryDate.hasSame(InstanceManager.timeSource.GetLocalTime(), "day")
		)
			return this.LastGetTodaysLatestEntry;

		const newLocal = this.GetTodaysEntries(dayStartOffsetHours).sort(
			(a, b) => a.startedWhen.diff(b.startedWhen).milliseconds
		);
		this.LastGetTodaysLatestEntry = newLocal.last();
		this.LastGetTodaysLatestEntryDate = this.LastUpdatedWhen;
		this.LastGetTodaysLatestEntryOffset = dayStartOffsetHours;

		return this.LastGetTodaysLatestEntry;
	}

	public GetTodaysEarliestEntry(dayStartOffsetHours: number): ITimeEntry | undefined {
		return this.GetTodaysEntries(dayStartOffsetHours)
			.sort((a, b) => a.startedWhen.diff(b.startedWhen).milliseconds)
			.first();
	}

	// This interacts oddly with edit mode time entries for some reason.
	private LastGetTodaysEntries: List<ITimeEntry> | undefined;
	private LastGetTodaysEntriesDate: DateTime | undefined;
	private LastGetTodaysEntriesOffset: number | undefined;
	public GetTodaysEntries(dayStartOffsetHours: number): List<ITimeEntry> {
		if (
			this.LastGetTodaysEntries &&
			this.LastGetTodaysEntriesDate &&
			this.LastGetTodaysEntriesOffset &&
			+this.LastGetTodaysEntriesDate === +this.LastUpdatedWhen &&
			this.LastGetTodaysEntriesOffset === dayStartOffsetHours &&
			this.LastGetTodaysEntriesDate.hasSame(InstanceManager.timeSource.GetLocalTime(), "day")
		)
			return this.LastGetTodaysEntries;

		const startOfDay = InstanceManager.timeSource.GetLocalTime().startOf("day").plus({ hours: dayStartOffsetHours });
		const endOfDay = InstanceManager.timeSource.GetLocalTime().endOf("day").plus({ hours: dayStartOffsetHours });
		this.LastGetTodaysEntries = this.AllNotDeleted().filter(
			(timeEntry) =>
				!timeEntry.endedWhen || Interval.fromDateTimes(startOfDay, endOfDay).contains(timeEntry.startedWhen)
		);
		this.LastGetTodaysEntriesDate = this.LastUpdatedWhen;
		this.LastGetTodaysEntriesOffset = dayStartOffsetHours;

		return this.LastGetTodaysEntries;
	}

	public GetSetDaysEntries(date: DateTime, dayStartOffsetHours: number): List<ITimeEntry> {
		const startOfDay = date.toLocal().startOf("day").plus({ hours: dayStartOffsetHours });
		const endOfDay = date.toLocal().endOf("day").plus({ hours: dayStartOffsetHours });
		return this.getTimeEntriesBetween(startOfDay, endOfDay);
	}

	public GetDaysEntries(startDay: DateTime, endDay: DateTime, dayStartOffsetHours: number): List<ITimeEntry> {
		const startOfDay = startDay.toLocal().startOf("day").plus({ hours: dayStartOffsetHours });
		const endOfDay = endDay.toLocal().endOf("day").plus({ hours: dayStartOffsetHours });
		return this.getTimeEntriesBetween(startOfDay, endOfDay);
	}

	private getTimeEntriesBetween(start: DateTime, end: DateTime): List<ITimeEntry> {
		return this.AllNotDeleted().filter((timeEntry) =>
			Interval.fromDateTimes(start, end).contains(timeEntry.startedWhen)
		);
	}

	public GetForGroup(timeEntrySetGuid: Guid) {
		return this.AllNotDeleted().filter((timeEntry) => timeEntry.timeEntrySetGuid === timeEntrySetGuid);
	}

	public GetFirstInGroup(timeEntrySetGuid: Guid) {
		return this.GetForGroup(timeEntrySetGuid)
			.sortBy((timeEntry) => timeEntry.startedWhen)
			.first(undefined);
	}
}
