import React from "react";
import { ForgottenTimeEntryContextModel } from "./ForgottenTimeEntryContextModel";
import { ForgottenTimeEntryContextAction } from "./ForgottenTimeEntryContextAction";
import { ForgottenTimeEntryContextDispatchActionType } from "./ForgottenTimeEntryContextDispatchActionType";
import { ForgottenTimeEntryContextProviderProps } from "./ForgottenTimeEntryContext.Interface";

export const ForgottenTimeEntryContext = React.createContext({
	state: new ForgottenTimeEntryContextModel(),
	dispatch: {} as React.Dispatch<ForgottenTimeEntryContextAction>,
});

function ModalReducer(state: ForgottenTimeEntryContextModel, action: ForgottenTimeEntryContextAction) {
	switch (action.type) {
		case ForgottenTimeEntryContextDispatchActionType.SetModalTimeEntry:
			return { ...state, modalTimeEntry: action.payload };
		default:
			return { ...state };
	}
}

export const ForgottenTimeEntryContextProvider = (props: ForgottenTimeEntryContextProviderProps) => {
	const [state, dispatch] = React.useReducer(ModalReducer, {
		modalTimeEntry: undefined,
	});
	const value = { state, dispatch };

	return <ForgottenTimeEntryContext.Provider value={value}>{props.children}</ForgottenTimeEntryContext.Provider>;
};
