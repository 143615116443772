import React from "react";
import { ITask } from "../../../Data/Models/ITask";
import { ISearchResult } from "../../../Data/Models/ISearchResult";

export const TaskExternalLink = (props: { task: ITask | ISearchResult; inverseColor?: boolean }) => {
	return props.task.linkUrl ? (
		<a
			className={"external-link " + (props.inverseColor ? "inverse-color" : "")}
			href={props.task.linkUrl}
			target="_blank"
			rel="noopener noreferrer"
		>
			<i className="fas fa-external-link"></i>
		</a>
	) : (
		<></>
	);
};
