import React, { useState, useRef } from "react";
import { ViewContextModel } from "./ViewContextModel";
import { ViewContextAction } from "./ViewContextActions";
import { ViewContextDispatchActionType } from "./ViewContextDispatchActionType";
import { ViewContextProviderProps } from "./ViewContext.Interface";
import { LogInView } from "../../Components/Views/LogInView/LogInView";
import { ForgottenTimeEntryView } from "../../Components/Views/ForgottenTimeEntryView/ForgottenTimeEntryView";
import { DashboardView } from "../../Components/Views/DashboardView/DashboardView";
import { CommentsView } from "../../Components/Views/CommentsView/CommentsView";
import { EditTimelineView } from "../../Components/Views/EditTimelineView/EditTimelineView";
import { TasklistView } from "../../Components/Views/TasklistView/TasklistView";
import { ExportView } from "../../Components/Views/ExportView/ExportView";
import { RoundingSettingsView } from "../../Components/Views/RoundingSettingsView/RoundingSettingsView";
import { useLoginState } from "../../useLoginState";
import { LoginResultEnum } from "../../Data/LoginResultEnum";
import { useDbReadyEvent } from "../../Hooks/useDbReadyEvent";
import { InstanceManager } from "../../Data/InstanceManager";
import { useCheckForForgottenTimeEntries } from "../../Hooks/useCheckForForgottenTimeEntries";
import { Ticker } from "../../Hooks/useTime";
import nameof from "ts-nameof.macro";
import { useWindowFocus } from "../../Hooks/useWindowFocus";

export enum Views {
	None = "None",
	LogInView = "LogInView",
	ForgottenTimeEntryView = "ForgottenTimeEntryView",
	DashboardView = "DashboardView",
	CommentsView = "CommentsView",
	ExportView = "ExportView",
	EditTimelineView = "EditTimelineView",
	TasklistView = "TasklistView",
}

const TimerReducer = (state: ViewContextModel, action: ViewContextAction): ViewContextModel => {
	switch (action.type) {
		// case ViewContextDispatchActionType.setCurrentView:
		// 	return { ...state, currentView: action.payload };
		case ViewContextDispatchActionType.LogInView:
			Ticker.PauseTick();
			return { ...state, currentView: <LogInView />, viewName: Views.LogInView, showLoadingSpinner: false };
		case ViewContextDispatchActionType.ForgottenTimeEntryView:
			Ticker.PauseTick();
			return {
				...state,
				currentView: <ForgottenTimeEntryView />,
				viewName: Views.ForgottenTimeEntryView,
				showLoadingSpinner: false,
			};

		// #region Tabs
		case ViewContextDispatchActionType.DashboardView:
			Ticker.ResumeTick();
			return { ...state, currentView: <DashboardView />, viewName: Views.DashboardView, showLoadingSpinner: false };
		case ViewContextDispatchActionType.CommentsView:
			Ticker.ResumeTick();
			return { ...state, currentView: <CommentsView />, viewName: Views.CommentsView, showLoadingSpinner: false };
		case ViewContextDispatchActionType.ExportView:
			Ticker.ResumeTick();
			return { ...state, currentView: <ExportView />, viewName: Views.ExportView, showLoadingSpinner: false };
		// #endregion

		case ViewContextDispatchActionType.EditTimelineView:
			Ticker.PauseTick();
			return { ...state, currentView: <EditTimelineView />, viewName: Views.EditTimelineView };
		case ViewContextDispatchActionType.TasklistView:
			Ticker.PauseTick();
			return { ...state, currentView: <TasklistView />, viewName: Views.TasklistView };
		case ViewContextDispatchActionType.RoundingSettingsView: // NOTE: This is temporary until the account screen is finished
			Ticker.PauseTick();
			return { ...state, currentView: <RoundingSettingsView />, viewName: Views.EditTimelineView };
		case ViewContextDispatchActionType.ShowTabs:
			return { ...state, showTabs: true };
		case ViewContextDispatchActionType.HideTabs:
			return { ...state, showTabs: false };
		case ViewContextDispatchActionType.ShowFullscreenLoadingMessage:
			return { ...state, showLoadingSpinner: true, loadingMessage: action.message };
		case ViewContextDispatchActionType.HideFullscreenLoadingMessage:
			return { ...state, showLoadingSpinner: false, loadingMessage: undefined };
		case ViewContextDispatchActionType.SetRefreshing:
			Ticker.SetTick(action.refreshing || false);
			return {
				...state,
				refreshing: action.refreshing,
				showLoadingSpinner: action.refreshing ? action.refreshing : state.refreshing,
				loadingMessage: action.refreshing ? "authenticating" : state.loadingMessage,
			};
		default:
			return { ...state };
	}
};

export const ViewContext = React.createContext({
	state: new ViewContextModel(),
	dispatch: {} as React.Dispatch<ViewContextAction>,
});

export const ViewContextProvider = (props: ViewContextProviderProps) => {
	const [state, dispatch] = React.useReducer(TimerReducer, {
		currentView: <></>,
		viewName: Views.None,
		showTabs: false,
		showLoadingSpinner: true,
		refreshing: false,
		loadingMessage: "loading",
	});

	const value = { state, dispatch };

	const [, loginStatus, check] = useLoginState();
	useWindowFocus(check);

	const [dbReady, setDbReady] = useState(false);
	useDbReadyEvent(() => setDbReady(true));

	const CheckForForgottenTimeEntries = useCheckForForgottenTimeEntries(nameof(ViewContextProvider));

	const loggingIn = useRef(false);
	function LogIn() {
		if (loggingIn.current) return;

		loggingIn.current = true;

		if (navigator.storage && navigator.storage.persist)
			navigator.storage
				.persist()
				.then((result) => {
					if (result) console.log("Storage persisted 🥳🎉");
					else console.log("Storage not persisted 😭");
				})
				.catch((err) => {
					console.error("Storage persist request failed 🙅", err);
				});

		dispatch({
			type: ViewContextDispatchActionType.ShowFullscreenLoadingMessage,
			message: "syncing",
		});

		InstanceManager.syncManager.SyncImmediately().then(() => {
			if (!CheckForForgottenTimeEntries()) {
				dispatch({
					type: ViewContextDispatchActionType.DashboardView,
				});
			} else {
				dispatch({
					type: ViewContextDispatchActionType.ForgottenTimeEntryView,
				});
			}

			loggingIn.current = false;
		});
	}

	switch (loginStatus) {
		case LoginResultEnum.Success:
			if ((state.viewName === Views.None || state.viewName === Views.LogInView || state.refreshing) && dbReady) {
				if (state.refreshing) dispatch({ type: ViewContextDispatchActionType.SetRefreshing, refreshing: false });
				LogIn();
			}
			break;
		case LoginResultEnum.Refreshing:
			if (!state.refreshing) {
				dispatch({ type: ViewContextDispatchActionType.SetRefreshing, refreshing: true });
			}

			break;
		case LoginResultEnum.LoggedOut:
		case LoginResultEnum.BadCredentials:
		case LoginResultEnum.RefreshTokenInvalid:
		case LoginResultEnum.OtherError:
			if (state.viewName !== Views.LogInView) {
				dispatch({ type: ViewContextDispatchActionType.LogInView });
			}
			break;
	}

	return <ViewContext.Provider value={value}>{props.children}</ViewContext.Provider>;
};
