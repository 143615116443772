import { Source } from "../Data/Source";
import { TheClaw } from "../Data/TheClaw";
import { useSubscribableCollection, IUseSubscribableCollectionType } from "./useSubscribable";
import { ITimeEntry } from "../Data/Models/ITimeEntry";
import { Guid } from "../Data/Guid";
import { TimeEntrySubscribable } from "../Data/TimeEntrySubscribable";
import { useContext } from "react";
import { TimelineContext } from "../Context/TimelineContext/TimelineContext";
import { List } from "immutable";
import { DateTime } from "luxon";

export interface IUseTimeEntries extends IUseSubscribableCollectionType<ITimeEntry> {
	AllNotDeleted(): List<ITimeEntry>;
	GetLatestEntry(): ITimeEntry | undefined;
	GetRunningEntry(): ITimeEntry | undefined;
	GetEarliestEntry(): ITimeEntry | undefined;
	GetTodaysEntries(): List<ITimeEntry>;
	GetSetDaysEntries(date: DateTime): List<ITimeEntry>;
	GetForGroup(timeEntrySetGuid: Guid): List<ITimeEntry>;
	GetTodaysLatestEntry(): ITimeEntry | undefined;
	GetFirstInGroup(timeEntrySetGuid: Guid): ITimeEntry | undefined;
}

/**
 *
 * Causes re-render when any item in Tasks changes
 * @param source
 */
export function useTimeEntries(
	source?: Source,
	dataSource: TimeEntrySubscribable = TheClaw.TimeEntries
): IUseTimeEntries {
	const subscribable = useSubscribableCollection(dataSource, source);
	const timelineContext = useContext(TimelineContext);

	const offsetHours = timelineContext.state.timelineStartTimeOffsetHours || 0;
	return {
		...subscribable,
		// Task-specific functions can be added here
		AllNotDeleted: () => dataSource.AllNotDeleted(),
		GetLatestEntry: () => dataSource.GetTodaysLatestEntry(offsetHours),
		GetRunningEntry: () => dataSource.GetRunningEntry(offsetHours),
		GetEarliestEntry: () => dataSource.GetTodaysEarliestEntry(offsetHours),
		GetTodaysEntries: () => dataSource.GetTodaysEntries(offsetHours),
		GetSetDaysEntries: (date: DateTime) => dataSource.GetSetDaysEntries(date, offsetHours),
		GetForGroup: (timeEntrySetGuid: Guid) => dataSource.GetForGroup(timeEntrySetGuid),
		GetTodaysLatestEntry: () => dataSource.GetTodaysLatestEntry(offsetHours),
		GetFirstInGroup: (timeEntrySetGuid: Guid) => dataSource.GetFirstInGroup(timeEntrySetGuid),
	};
}
