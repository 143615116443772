import React, { useContext, useEffect } from "react";
import "./EditTimelineView.scss";
import { EditModeTimeline } from "../../../Containers/EditModeTimeline/EditModeTimeline";
import { ViewContext } from "../../../Context/ViewContext/ViewContext";
import { ViewContextDispatchActionType } from "../../../Context/ViewContext/ViewContextDispatchActionType";

export function EditTimelineView() {
	const viewContext = useContext(ViewContext);

	useEffect(() => {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.HideTabs,
		});
	}, []);

	return (
		<>
			<EditModeTimeline />
			{/* <ContextMenu /> */}
		</>
	);
}
