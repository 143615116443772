export enum TimelineContextDispatchActionType {
	IncreaseScale,
	DecreaseScale,
	SetCurrentScale,
	SetTimelineStartTimeOffset,
	ScrollToBottom,
	ToggleTimelineSortMode,
	ScrollToDate,
	ScrollToTimeEntry,
	SetCurrentDayOffset,
	SetEditingComment,
}
