import { Subscriber } from "./Subscriber";
import { Source } from "./Source";
import { Map, List, Set } from "immutable";
import { DateTime } from "luxon";
import { SpecialCause } from "./SpecialCause";
export type SubscribableKey = string; // | string[];

export class SubscribableCollection<TModel> {
	protected Subscribers: Set<Subscriber<string>>;
	protected Values: Map<string, TModel>;
	protected LastUpdatedWhen: DateTime;

	constructor(initialSubscribers: Subscriber<string>[], initalValues: Map<string, TModel> = Map()) {
		this.Subscribers = Set(initialSubscribers);
		this.Values = initalValues;
		this.LastUpdatedWhen = DateTime.utc();
	}

	public All(): List<TModel> {
		return this.Values.toList();
	}
	public Get(key: string): TModel | undefined {
		return this.Values.get(key, undefined);
	}
	public Set(key: string, value: TModel, source: Source, cause?: SpecialCause): void {
		const isUpdate = this.Values.has(key);
		this.Values = this.Values.set(key, value);
		this.LastUpdatedWhen = DateTime.utc();
		this.Notify(source, isUpdate ? DataActionEnum.Update : DataActionEnum.Create, Set([key]), cause);
	}
	public Remove(key: string, source: Source, cause?: SpecialCause): void {
		console.warn(`PROPER DELETION of key: ${key} initiated by source: ${source}`);
		this.Values = this.Values.delete(key);
		this.LastUpdatedWhen = DateTime.utc();
		this.Notify(source, DataActionEnum.Delete, Set([key]), cause);
	}
	public RemoveAll(source?: Source): void {
		this.Values = Map();
		this.LastUpdatedWhen = DateTime.utc();
		if (source) this.Notify(source, DataActionEnum.DeleteAll);
	}

	public BulkSet(data: Map<string, TModel>, source?: Source, notify?: boolean, specialCause?: SpecialCause) {
		const keys = data.keySeq().toSet();
		if (keys.count() === 0) return;
		this.Values = this.Values.merge(data);
		this.LastUpdatedWhen = DateTime.utc();
		if (source && notify) {
			this.Notify(source, DataActionEnum.BulkSet, keys, specialCause);
		}
	}

	public Subscribe(subscriber: Subscriber<string>) {
		this.Subscribers = this.Subscribers.add(subscriber);
	}
	public UnSubscribe(toUnSubscribe: Subscriber<string>) {
		this.Subscribers = this.Subscribers.delete(toUnSubscribe);
	}
	public Notify(source: Source, action: DataActionEnum, keys: Set<string> = Set(), specialCause?: SpecialCause) {
		this.Subscribers.forEach((subscriber) => {
			if (
				subscriber.source !== source &&
				(!Array.isArray(source) || !source.includes(subscriber.source)) &&
				(!subscriber.key || keys?.includes(subscriber.key)) &&
				(specialCause === undefined ||
					subscriber.allCauses ||
					subscriber.includeSpecialCauses?.includes(specialCause)) &&
				(!specialCause || !subscriber.excludeSpecialCauses?.includes(specialCause))
			)
				subscriber.callback(action, keys);
		});
	}
}

export enum DataActionEnum {
	Create,
	Update,
	Delete,
	DeleteAll,
	BulkSet,
}
