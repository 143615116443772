import React from "react";
import { DateSeparatorProps } from "./DateSeparator.Interface";
import { DateTime } from "luxon";

// TODO: Don't add Guids of exporting groups to selected groups for exporting.
// TODO: Check for last changed date and use that to put groups that have been changed since last export back into export page.

export function DateSeparator(props: DateSeparatorProps) {
	function OpenCommentsViewAtGroup() {
		props.OpenCommentsViewAtGroup(props.groupGuid);
	}

	return (
		<div className="date-separator-wrapper">
			<button className="date-separator" onClick={OpenCommentsViewAtGroup}>
				{props.groupStartTime.toLocaleString(DateTime.DATE_HUGE)}
			</button>
		</div>
	);
}
