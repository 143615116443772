import React from "react";
import "./Dashboard.scss";
import { useTimeEntries } from "../../Hooks/useTimeEntries";
import { Timer } from "../../Components/Library/Timer/Timer";
import { Halo, HaloShape } from "../../Components/Library/Halo/Halo";
import "../../Components/Library/EditableLabel/EditableLabel.scss";

import nameof from "ts-nameof.macro";
import { TheHook } from "../../Hooks/TheHook/TheHook";
import { useTimeControls } from "../../Hooks/useTimeControls";

import { HaloTimeLabelWrapper } from "../../Components/Library/Halo/HaloTimeLabelWrapper";
import { useTimerCalulatedData } from "../../Hooks/useTimerCalulatedData";
import { useCalculatedTimes } from "../../Hooks/useCalculatedTimes";

enum PlayingStatus {
	isPlaying = " is-playing",
	notPlaying = " not-playing",
}

export const Dashboard = () => {
	const TimeEntries = useTimeEntries(nameof(Dashboard));
	const theHook = TheHook(nameof(Dashboard));
	const timeControls = useTimeControls(nameof(Dashboard));

	const { isPlaying, currentTimeEntryGUID } = useTimerCalulatedData(nameof(Dashboard));

	const { groupTotalTime, todayTotalTime, weekTotalTime } = useCalculatedTimes(nameof(Dashboard));

	const dayStarted = !!currentTimeEntryGUID;

	function EditCurrentTimeEntry() {
		if (currentTimeEntryGUID) {
			const currentTimeEntry = TimeEntries.Get(currentTimeEntryGUID);
			theHook.OpenEditTimeline(0, currentTimeEntry);
		}
	}

	return (
		<div id={"dashboard-wrapper"}>
			<div className="dashboard-halo-wrapper">
				<div className="halo-time-labels-wrapper">
					<HaloTimeLabelWrapper />
				</div>
				<div className="dashboard-halo-container">
					<div className="halo-graphic">
						<Halo haloShape={HaloShape.halo} />
					</div>

					<div className="halo-details">
						<div className="total-time">
							<div className={"halo-total-label"}>TODAY:</div>
							<Timer timeToDisplay={todayTotalTime} className={"halo-total-time"} isDecimalFormat={true} />
						</div>
						<Timer timeToDisplay={groupTotalTime} className={"halo-timer-running-group"} />
						<div className="total-time">
							<div className={"halo-total-label"}>WEEK:</div>
							<Timer timeToDisplay={weekTotalTime} className={"halo-total-time"} isDecimalFormat={true} />
						</div>
					</div>
				</div>

				<section className="timer-controls-wrapper">
					<div className="timer-controls-container">
						{/* TO DO - Implement 'edit' button */}

						{/* EDIT BUTTON */}
						<button
							className={"edit circle icon-only"}
							onClick={EditCurrentTimeEntry}
							title="Edit time entry"
							disabled={!dayStarted}
						></button>

						{/* PLAY/PAUSE BUTTON */}
						<button
							onClick={() => timeControls.TogglePlay()}
							className={"play circle icon-only" + (isPlaying ? PlayingStatus.isPlaying : PlayingStatus.notPlaying)}
							title={isPlaying ? "Pause task" : "Play task"}
						/>

						{/* TIME SWITCH BUTTON */}
						<button
							onClick={() => timeControls.SwitchTo()}
							className={"switch circle icon-only"}
							title="Switch to new task"
							disabled={!dayStarted}
						/>
					</div>
				</section>
			</div>
		</div>
	);
};
