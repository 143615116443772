import React from "react";
import { ExportContextModel } from "./ExportContextModel";
import { ExportContextAction } from "./ExportContextAction";
import { ExportContextDispatchActionType } from "./ExportContextActionType";
import { ExportContextProviderProps } from "./ExportContext.Interface";
import { Guid } from "../../Data/Guid";

export const ExportContext = React.createContext({
	state: new ExportContextModel(),
	dispatch: {} as React.Dispatch<ExportContextAction>,
});

function EditModeReducer(state: ExportContextModel, action: ExportContextAction) {
	switch (action.type) {
		case ExportContextDispatchActionType.AddExportRecord:
			return { ...state, selectedExportRecords: AddExportRecord(action.payload, state.selectedExportRecords) };
		case ExportContextDispatchActionType.RemoveExportRecord:
			return { ...state, selectedExportRecords: RemoveExportRecord(action.payload, state.selectedExportRecords) };
		case ExportContextDispatchActionType.ClearExportRecord:
			return { ...state, selectedExportRecords: new Set<Guid>() };
		default:
			return { ...state };
	}
}

function AddExportRecord(newRecord: Guid, selectedExportRecords: Set<Guid>) {
	const newSet = new Set(selectedExportRecords).add(newRecord);
	return newSet;
}

function RemoveExportRecord(newRecord: Guid, selectedExportRecords: Set<Guid>) {
	const newSet = new Set(selectedExportRecords);
	newSet.delete(newRecord);
	return newSet;
}

export const ExportContextProvider = (props: ExportContextProviderProps) => {
	const [state, dispatch] = React.useReducer(EditModeReducer, {
		selectedExportRecords: new Set<Guid>(),
	});
	const value = { state, dispatch };

	return <ExportContext.Provider value={value}>{props.children}</ExportContext.Provider>;
};
