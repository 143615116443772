import React from "react";
import { SearchContextModel } from "./SearchContextModel";
import { SearchContextAction } from "./SearchContextActions";
import { SearchContextDispatchActionType } from "./SearchContextDispatchActionType";
import { SearchContextProviderProps } from "./SearchContext.Interface";
import { InstanceManager } from "../../Data/InstanceManager";
import { ISearchResult } from "../../Data/Models/ISearchResult";
import { TaskHelper } from "../../Data/TaskHelper";
import { TheClaw } from "../../Data/TheClaw";
import { KeyHelper } from "../../Data/KeyHelper";
import { SpecialCause } from "../../Data/SpecialCause";

export enum FilterModes {
	noFilter = "mode-search",
	search = "mode-search",
	recent = "mode-orderby-recent",
	mostUsed = "mode-orderby-most-used",
	dueDate = "mode-orderby-due-date",
	new = "mode-orderby-new",
	favourites = "mode-filter-favourites",
	todo = "mode-orderby-todo",
	ascending = "mode-orderby-task-asc",
	descending = "mode-orderby-task-desc",
}

export enum NumberToShowFilters {
	all = "DISPLAY-ALL",
	todayOnly = "DISPLAY-TODAY",
	thisWeekOnly = "DISPLAY-WEEK",
	thisMonthOnly = "DISPLAY-MONTH",
}

export enum TaskCardSizes {
	small = "small",
	medium = "medium",
	large = "large",
}

function updateTasksFromSearchResults(results?: ISearchResult[]) {
	if (Array.isArray(results)) {
		for (const result of results) {
			const existingTask = TheClaw.Tasks.Get(KeyHelper.GetSearchResultTaskKey(result));
			if (existingTask) {
				TaskHelper.TaskifySearchResult(result, SpecialCause.AutoTaskifySearchResult);
			}
		}
	}
}

const SearchReducer = (state: SearchContextModel, action: SearchContextAction): SearchContextModel => {
	switch (action.type) {
		case SearchContextDispatchActionType.receiveResults:
			updateTasksFromSearchResults(action.payload);
			return { ...state, searchResults: action.payload, isSearching: false };
		case SearchContextDispatchActionType.showSearching:
			return { ...state, searchResults: [], isSearching: true };
		case SearchContextDispatchActionType.receiveNewResults:
			updateTasksFromSearchResults(action.payload);
			return { ...state, newResults: action.payload, isSearching: false };
		case SearchContextDispatchActionType.showFetchingNew:
			return { ...state, newResults: [], isSearching: true };
		case SearchContextDispatchActionType.setTaskCardSize:
			return { ...state, taskCardSize: action.payload };
		case SearchContextDispatchActionType.setSearchFilter:
			return { ...state, filterMode: action.payload };
		case SearchContextDispatchActionType.setSearchTerm:
			return { ...state, searchTerm: action.payload };
		case SearchContextDispatchActionType.setActiveTaskKey:
			return { ...state, activeTaskKey: action.payload };
		default:
			return state;
	}
};

export const SearchContext = React.createContext({
	state: new SearchContextModel(),
	dispatch: {} as React.Dispatch<SearchContextAction>,
} as SearchContextType);

export interface SearchContextType {
	state: SearchContextModel;
	dispatch: React.Dispatch<SearchContextAction>;
}

function dispatchMiddleware(dispatch: (action: SearchContextAction) => void) {
	return (action: SearchContextAction) => {
		switch (action.type) {
			case SearchContextDispatchActionType.search:
				dispatch({ type: SearchContextDispatchActionType.showSearching });
				dispatch({ type: SearchContextDispatchActionType.setSearchTerm, payload: action.payload });
				InstanceManager.int.Search(action.payload).then((results) => {
					dispatch({ type: SearchContextDispatchActionType.receiveResults, payload: results });
				});
				break;
			case SearchContextDispatchActionType.setSearchFilter:
				dispatch({ type: SearchContextDispatchActionType.setSearchFilter, payload: action.payload });
				if (action.payload === FilterModes.new) {
					dispatch({ type: SearchContextDispatchActionType.showFetchingNew });
					InstanceManager.int.NewTasks().then((results) => {
						dispatch({ type: SearchContextDispatchActionType.receiveNewResults, payload: results });
					});
				}
				break;
			default:
				return dispatch(action);
		}
	};
}

// This context provider uses a middleware wrapper to call the async search
// Meaning that the dispatchMiddleware always gets called first (simply calling the dispatch method if there's no async stuff to start)
// When the search results come back, it calls the regular dispatch method which is responsible for mutating the state
export const SearchContextProvider = (props: SearchContextProviderProps) => {
	const [state, dispatch] = React.useReducer(SearchReducer, {
		searchTerm: undefined,
		searchResults: [],
		isSearching: false,
		taskCardSize: TaskCardSizes.medium,
		filterMode: FilterModes.noFilter,
		activeTaskKey: undefined,
		newResults: [],
	});

	const value = { state, dispatch: dispatchMiddleware(dispatch) };

	return <SearchContext.Provider value={value}>{props.children}</SearchContext.Provider>;
};
