import { useContext } from "react";
import { DateTime, Duration } from "luxon";
import { Guid } from "../Data/Guid";
import { useTimeEntries } from "./useTimeEntries";
import nameof from "ts-nameof.macro";
import { TheHook } from "./TheHook/TheHook";
import { TimelineContext } from "../Context/TimelineContext/TimelineContext";
import { useTimerCalulatedData } from "./useTimerCalulatedData";
import { TimeBuffers } from "../GlobalUtils/Constants";
import { CheckBuffer } from "../GlobalUtils/TimeUtils";
import { InstanceManager } from "../Data/InstanceManager";
import { PlayheadContext } from "../Context/PlayheadContext/PlayheadContext";
import { PlayheadContextDispatchActionType } from "../Context/PlayheadContext/PlayheadContextDispatchActionType";
import { TimeCalculationHelpers } from "./TimeCalculationHelpers";

export function useTimeControls(source: string) {
	const timelineContext = useContext(TimelineContext);
	const playheadContext = useContext(PlayheadContext);
	const TimeEntries = useTimeEntries(nameof(source));
	const theHook = TheHook(source);

	const { isPlaying, currentTimeEntryGUID } = useTimerCalulatedData(source);

	function TogglePlay(isPlayingOverride?: boolean) {
		if (isPlayingOverride ? isPlayingOverride : isPlaying) {
			PauseTime();
		} else {
			const lastEntry = TimeEntries.GetLatestEntry();
			if (lastEntry && lastEntry.endedWhen && CheckBuffer(lastEntry.endedWhen, TimeBuffers.switchBufferTime)) {
				theHook.SetTimeEntry({
					...lastEntry,
					endedWhen: undefined,
				});
			} else {
				const timeEntrySet = currentTimeEntryGUID ? TimeEntries.Get(currentTimeEntryGUID) : undefined;
				const groupGuid = timeEntrySet ? timeEntrySet.timeEntrySetGuid : undefined;

				theHook.NewTimeEntry(undefined, undefined, groupGuid);
			}
		}
	}

	function PauseTime() {
		const lastEntry = TimeEntries.GetLatestEntry();
		if (lastEntry && !lastEntry.endedWhen) {
			theHook.SetTimeEntry({
				...lastEntry,
				endedWhen: InstanceManager.timeSource.GetLocalTime(),
			});
		}
	}

	function GetTotalGroupTime(TimeEntrySetGuid: Guid, _forSetDayOffset?: number, useRounding?: boolean): Duration {
		return TimeCalculationHelpers.GetTotalGroupTime(
			TimeEntrySetGuid,
			timelineContext.state.timelineStartTimeOffsetHours,
			useRounding
		);
	}

	function GetTotalDayTime(forSetDayOffset?: number): Duration {
		return TimeCalculationHelpers.GetTotalDayTime(
			forSetDayOffset ? forSetDayOffset : timelineContext.state.currentDayOffset,
			timelineContext.state.timelineStartTimeOffsetHours
		);
	}

	function SwitchTo(taskGuid?: Guid) {
		if (taskGuid) {
			theHook.SwitchToTask(taskGuid);
		} else {
			theHook.SwitchToNew();
		}
		playheadContext.dispatch({ type: PlayheadContextDispatchActionType.updateLastSwitchTime });
	}

	function GetGroupsDayOffset(timeEntrySetGuid: Guid, currentTime: DateTime) {
		return TimeCalculationHelpers.GetGroupsDayOffset(timeEntrySetGuid, currentTime);
	}

	return {
		TogglePlay,
		PauseTime,
		GetTotalGroupTime,
		GetTotalDayTime,
		SwitchTo,
		GetGroupsDayOffset,
	};
}
