import React, { useState, useContext } from "react";
import { TimeEntryProps } from "./TimeEntry.Interface";
import "./TimeEntry.scss";
import { defaultScale, TimelineContext } from "../../../Context/TimelineContext/TimelineContext";
import { KeyHelper } from "../../../Data/KeyHelper";
import { GlobalSettingsContext } from "../../../Context/GlobalSettingsContext/GlobalSettingsContext";
import { DraggableCore, DraggableData, DraggableEvent } from "react-draggable";
import { GetRelativeClickCoordinates } from "../../../GlobalUtils/PositionUtils";
import { CalcTimePosition, CalcPositionTime } from "../../../GlobalUtils/TimeUtils";
import { useGroups } from "../../../Hooks/useGroups";
import { useTasks } from "../../../Hooks/useTasks";
import { useContextMenuParams, ContextMenu } from "../ContextMenu/ContextMenu";
import { useTime } from "../../../Hooks/useTime";
import { InstanceManager } from "../../../Data/InstanceManager";
import nameof from "ts-nameof.macro";

enum LinkedClassName {
	linked = " mode-linked",
	unlinked = " mode-unlinked",
}

enum HighlightedClassName {
	highlighted = " highlighted",
	notHighlighted = "",
}

// Once the edit mode bugs have been sorted out, return the setTops and setHeights to the mouse up top tab /bottom tag functions ot make it feel smoother.
// and uncomment the useEffect with conditions for setting height and top to make it more efficient.

export const TimeEntry = (props: TimeEntryProps) => {
	const globalSettingsContext = useContext(GlobalSettingsContext);
	const timelineContext = useContext(TimelineContext);
	const groups = useGroups(nameof(TimeEntry));
	const tasks = useTasks(nameof(TimeEntry));

	const isLinked = !!groups.Get(props.timeEntry.timeEntrySetGuid)?.taskExternalId;

	const scale = timelineContext.state.currentScale || defaultScale;
	const dayStartOffsetHours = timelineContext.state.timelineStartTimeOffsetHours || 0;

	const [topDelta, setTopDelta] = useState(0);
	const [bottomDelta, setBottomDelta] = useState(0);
	const [bodyDelta, setBodyDelta] = useState(0);

	const unchangedStartPos = CalcTimePosition(
		props.timeEntry.startedWhen,
		dayStartOffsetHours,
		scale,
		timelineContext.state.currentDayOffset
	);
	const unchangedEndPos = CalcTimePosition(
		props.timeEntry.endedWhen || InstanceManager.timeSource.GetLocalTime(),
		dayStartOffsetHours,
		scale,
		timelineContext.state.currentDayOffset
	);
	const timeEntryIsRunning = !props.timeEntry.endedWhen;

	const top = unchangedStartPos + topDelta + bodyDelta;
	const bottom = unchangedEndPos + bottomDelta + bodyDelta;
	const height = bottom - top;

	const tmpStartTime = CalcPositionTime(top, dayStartOffsetHours, scale, timelineContext.state.currentDayOffset);
	const tmpEndTime = CalcPositionTime(bottom, dayStartOffsetHours, scale, timelineContext.state.currentDayOffset);

	useTime();

	function endEdit() {
		setTopDelta(0);
		setBottomDelta(0);
		setBodyDelta(0);
		setBeingEdited(false);
	}

	// --------------Dragging Handles---------------

	function OnTopDrag(_event: DraggableEvent, data: DraggableData) {
		setTopDelta(topDelta + data.deltaY);
	}

	function OnBottomDrag(_event: DraggableEvent, data: DraggableData) {
		setBottomDelta(bottomDelta + data.deltaY);
	}

	function OnBodyDrag(_event: DraggableEvent, data: DraggableData) {
		setBodyDelta(bodyDelta + data.deltaY);
	}

	function OnTopDragStart() {
		setTopDelta(0);
		setBodyDelta(0);
		setBeingEdited(true);
	}

	function OnBottomDragStart() {
		setBottomDelta(0);
		setBeingEdited(true);
	}

	function OnBodyDragStart() {
		setBodyDelta(0);
		setBeingEdited(true);
	}

	function TopTagMouseUp() {
		let newStartTime = tmpStartTime;
		let newEndTime = !timeEntryIsRunning ? tmpEndTime : undefined;

		// Need to make sure you can drag top bellow the bottom
		// Setting height here is to stop flickering from relying on the useEffect

		if (timelineContext.state.currentDayOffset === 0) {
			if (newStartTime > InstanceManager.timeSource.GetLocalTime()) {
				newStartTime = InstanceManager.timeSource.GetLocalTime().minus({ minutes: 10 });
			}
			if (newEndTime && newEndTime > InstanceManager.timeSource.GetLocalTime()) {
				newEndTime = InstanceManager.timeSource.GetLocalTime().minus({ second: 1 });
			}
		}
		props.theHook.DragCollision(props.timeEntry.timeEntryGuid, newStartTime, newEndTime);

		endEdit();
	}

	function BottomTagMouseUp() {
		// Need to make sure you can't drag bottom above top.
		// If you drag the end time of a currently timed entry down, should it set the end time?
		let newEndTime = !timeEntryIsRunning ? tmpEndTime : undefined;

		if (
			newEndTime &&
			timelineContext.state.currentDayOffset === 0 &&
			newEndTime > InstanceManager.timeSource.GetLocalTime()
		) {
			newEndTime = InstanceManager.timeSource.GetLocalTime().minus({ second: 1 });
		}

		props.theHook.DragCollision(props.timeEntry.timeEntryGuid, props.timeEntry.startedWhen, newEndTime);

		endEdit();
	}

	// ----------------Time Entry Dragging----------------

	function BodyMouseUp() {
		let newEndTime = !timeEntryIsRunning ? tmpEndTime : undefined;
		let newStartTime = tmpStartTime;

		if (
			newEndTime &&
			timelineContext.state.currentDayOffset === 0 &&
			newEndTime > InstanceManager.timeSource.GetLocalTime()
		) {
			newStartTime = InstanceManager.timeSource
				.GetLocalTime()
				.minus({ minutes: height / timelineContext.state.currentScale });
			newEndTime = InstanceManager.timeSource.GetLocalTime();
		}

		props.theHook.DragCollision(props.timeEntry.timeEntryGuid, newStartTime, newEndTime);

		endEdit();
	}

	// --------------------------------------------------------
	function DeleteThisTimeEntry() {
		props.theHook.RemoveTimeEntry(props.timeEntry.timeEntryGuid);
	}

	function InsertBreakInThisTimeEntry(position: number) {
		const timeForPos = CalcPositionTime(
			position,
			timelineContext.state.currentDayOffset || 0,
			timelineContext.state.currentScale || 1,
			timelineContext.state.currentDayOffset
		);

		props.theHook.InsertBreakInTimeEntry(props.timeEntry.timeEntryGuid, timeForPos);
	}

	function SplitThisTimeEntry(position: number) {
		const timeForPos = CalcPositionTime(
			position,
			timelineContext.state.currentDayOffset || 0,
			timelineContext.state.currentScale || 1,
			timelineContext.state.currentDayOffset
		);

		props.theHook.SplitTimeEntry(props.timeEntry.timeEntryGuid, timeForPos);
	}

	const { contextMenuParams, setMenuCoords, setMenuOptions, setShowContextMenu } = useContextMenuParams();

	function HandleContextMenu(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		e.preventDefault();
		e.stopPropagation();
		const coords = GetRelativeClickCoordinates(e.nativeEvent, props.scrollRef);

		const menuOptions = [
			{ display: "Split", callback: () => InsertBreakInThisTimeEntry(coords.y) },
			{ display: "Split & Switch", callback: () => SplitThisTimeEntry(coords.y) },
			{ display: "Delete", callback: () => DeleteThisTimeEntry() },
		];

		setMenuCoords(coords);
		setMenuOptions(menuOptions);
		setShowContextMenu(true);
	}

	function GetTaskName() {
		const myGroup = groups.Get(props.timeEntry.timeEntrySetGuid);
		if (myGroup) {
			if (myGroup.taskIntegrationGuid) {
				const taskGuid = KeyHelper.GetTimeEntrySetTaskKey(myGroup);
				const task = tasks.Get(taskGuid);
				if (task) {
					return task.name;
				}
			}
			if (myGroup.name) {
				return myGroup.name;
			}
		}
		// TODO: Handle this issue
		return globalSettingsContext.state.defaultGroupName;
	}

	const [beingEdited, setBeingEdited] = useState(false);

	return (
		<>
			<DraggableCore
				// Draggable for time entry body
				handle=".body-handle"
				onStart={OnBodyDragStart}
				onDrag={OnBodyDrag}
				onStop={BodyMouseUp}
				disabled={props.timeEntry.endedWhen ? false : true}
				offsetParent={props.scrollRef}
			>
				<div
					className={"time-unit-wrapper"}
					style={{
						top: top.toFixed() + "px",
						height: height.toFixed() + "px",
					}}
				>
					<div
						className={
							"time-unit " +
							(isLinked ? LinkedClassName.linked : LinkedClassName.unlinked) +
							HighlightedClassName.highlighted +
							" edit-mode" +
							(beingEdited ? " being-edited" : "")
						}
					>
						<div onContextMenu={HandleContextMenu} className="time-entry-block body-handle">
							<div className="time-entry-name-wrapper">
								<div className="time-entry-name">{GetTaskName()}</div>
							</div>
						</div>
						<DraggableCore
							// Draggable for top time tag
							handle=".top-handle"
							onStart={OnTopDragStart}
							onDrag={OnTopDrag}
							onStop={TopTagMouseUp}
							offsetParent={props.scrollRef}
						>
							<div className="draggable-tag top top-handle">
								<span className="icon" />
								<span className="time-stamp">{tmpStartTime.toFormat("HH:mm")}</span>
							</div>
						</DraggableCore>
						{props.timeEntry.endedWhen ? (
							<DraggableCore
								// Draggable for top time tag
								handle=".bottom-handle"
								onStart={OnBottomDragStart}
								onDrag={OnBottomDrag}
								onStop={BottomTagMouseUp}
								disabled={props.timeEntry.endedWhen ? false : true}
								offsetParent={props.scrollRef}
							>
								<div className="draggable-tag bottom bottom-handle">
									<span className="time-stamp">{tmpEndTime.toFormat("HH:mm")}</span>
									<span className="icon" />
								</div>
							</DraggableCore>
						) : (
							<></>
						)}
					</div>
				</div>
			</DraggableCore>
			{ContextMenu(contextMenuParams)}
		</>
	);
};
