import { useEffect, useState } from "react";
import { SubscribableCollection, SubscribableKey } from "../Data/Subscribable";
import { Source } from "../Data/Source";
import { Subscriber } from "../Data/Subscriber";
import { useForceUpdate, useForceUpdateWithTimestamp } from "./useForceUpdate";
import { Guid } from "../Data/Guid";
import { v4 as uuid } from "uuid";
import { List } from "immutable";
import { DateTime } from "luxon";
import { SpecialCause } from "../Data/SpecialCause";

export interface IUseSubscribableCollectionType<T> {
	Get(key: string): T | undefined;
	All(): List<T>;
	Set(key: string, value: T): void;
	Remove(key: string): void;
	RemoveAll(): void;
	timestamp: DateTime;
}

export function useSubscribableCollection<T>(
	subscribable: SubscribableCollection<T>,
	source?: Source,
	includeSpecialCauses?: SpecialCause[]
): IUseSubscribableCollectionType<T> {
	const { forceUpdate, timestamp } = useForceUpdateWithTimestamp();
	const [internalSource] = useState(() => `${source || "unnamed"}_${uuid()}`);

	useEffect(() => {
		const subscriber: Subscriber<string> = {
			callback: forceUpdate,
			source: internalSource,
			includeSpecialCauses,
		};

		subscribable.Subscribe(subscriber);
		return () => {
			subscribable.UnSubscribe(subscriber);
		};
	}, []);

	return {
		Get: (key: string) => subscribable.Get(key),
		All: () => subscribable.All(),
		Set: (key: string, value: T) => subscribable.Set(key, value, internalSource),
		Remove: (key: string) => subscribable.Remove(key, internalSource),
		RemoveAll: () => subscribable.RemoveAll(internalSource),
		timestamp,
	};
}
