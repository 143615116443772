import { useEffect, useContext, useCallback, useState } from "react";
import { UndoRedoContext } from "../../Context/UndoRedoContext/UndoRedoContext";
import { UndoRedoContextDispatchActionType } from "../../Context/UndoRedoContext/UndoRedoContextDispatchActionType";
import { HistoryModel } from "./HistoryModel";
import { Source } from "../../Data/Source";
import { ITimeEntry } from "../../Data/Models/ITimeEntry";
import { ITimeEntrySet } from "../../Data/Models/ITimeEntrySet";
import { EditModeContext } from "../../Context/EditModeContext/EditModeContext";
import { EditModeContextDispatchActionType } from "../../Context/EditModeContext/EditModeContextDispatchActionType";
import { List } from "immutable";

export interface HistoryStateModel {
	past: EditHistoryModel[];
	present: EditHistoryModel;
	future: EditHistoryModel[];
}

export interface EditHistoryModel {
	presentTimeEntries: List<ITimeEntry>;
	presentGroups: List<ITimeEntrySet>;
}

export function useHistory(source: Source): HistoryModel {
	const undoRedo = useContext(UndoRedoContext);
	const editModeContext = useContext(EditModeContext);

	const [canUndo, setCanUndo] = useState<boolean>(false);
	const [canRedo, setCanRedo] = useState<boolean>(false);

	//const canUndo = undoRedo.state.past.length !== 0;
	//const canRedo = undoRedo.state.future.length !== 0;

	useEffect(() => {
		if (undoRedo.state.past) {
			setCanUndo(undoRedo.state.past.length > 0);
		}
	}, [undoRedo.state.past ? undoRedo.state.past.length : false]);

	useEffect(() => {
		if (undoRedo.state.future) {
			setCanRedo(undoRedo.state.future.length > 0);
		}
	}, [undoRedo.state.future ? undoRedo.state.future.length : false]);

	function UndoRedoTrigger() {
		editModeContext.dispatch({
			type: EditModeContextDispatchActionType.UndoRedoTriggerToggle,
			payload: undefined,
		});
	}

	const undo = useCallback(() => {
		if (canUndo) {
			undoRedo.dispatch({
				type: UndoRedoContextDispatchActionType.Undo,
				payload: undefined,
			});

			UndoRedoTrigger();
			console.log("history undo triggered, source: " + source);
		}
	}, [canUndo]);

	const forceUndo = useCallback(() => {
		undoRedo.dispatch({
			type: UndoRedoContextDispatchActionType.Undo,
			payload: undefined,
		});

		UndoRedoTrigger();
	}, [canUndo]);

	const redo = useCallback(() => {
		if (canRedo) {
			undoRedo.dispatch({
				type: UndoRedoContextDispatchActionType.Redo,
				payload: undefined,
			});

			UndoRedoTrigger();
			console.log("history redo triggered, source: " + source);
		}
	}, [canRedo]);

	const set = useCallback((newPresent) => {
		undoRedo.dispatch({
			type: UndoRedoContextDispatchActionType.Set,
			payload: newPresent,
		});
		console.log("history set triggered, source: " + source);
	}, []);

	const clear = useCallback(() => {
		undoRedo.dispatch({
			type: UndoRedoContextDispatchActionType.Clear,
			payload: undefined,
		});
		console.log("history clear triggered, source: " + source);
	}, []);

	return {
		state: {
			past: undoRedo.state.past,
			present: undoRedo.state.present,
			future: undoRedo.state.future,
		} as HistoryStateModel,
		set,
		undo,
		forceUndo,
		redo,
		clear,
		canUndo,
		canRedo,
	};
}
