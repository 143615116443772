import React from "react";
import { CommentsSummaryProps } from "./CommentsSummary.Interface";
import "./CommentsSummary.scss";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import { DateTime } from "luxon";
import nameof from "ts-nameof.macro";
import { TimeEntryComment } from "../TimeEntryComment/TimeEntryComment";
import { List } from "immutable";

export const CommentsSummary = (props: CommentsSummaryProps) => {
	const TimeEntries = useTimeEntries(nameof(CommentsSummary));

	function FormatTime(time?: DateTime) {
		return time ? time.toLocaleString(DateTime.TIME_SIMPLE) : "NOW";
	}

	return (
		<section className="group-comments-summary" ref={props.ref}>
			{(TimeEntries.GetTodaysEntries() && props.TimeEntrySetGuid
				? TimeEntries.GetTodaysEntries()!
						.filter(
							(timeEntry) =>
								// Displays comments of all time entries in selected group
								timeEntry.timeEntrySetGuid === props.TimeEntrySetGuid && timeEntry.comment
						)
						.sort((a, b) => a.startedWhen.diff(b.startedWhen).milliseconds)
				: List()
			).map((timeEntry) => {
				return (
					<div className="time-entry-wrapper" key={"Time-Entry-Comment-" + timeEntry.timeEntryGuid}>
						<div className="comment-time-label">
							{FormatTime(timeEntry.startedWhen) + " - " + FormatTime(timeEntry.endedWhen)}
						</div>
						<TimeEntryComment MyTimeEntry={timeEntry} editable={true} />
					</div>
				);
			})}
		</section>
	);
};
