import React, { useState } from "react";
import { HaloTimeLabelProps } from "./HaloTimeLabel.Interface";
import "./HaloTimeLabel.scss";
import { DateTime } from "luxon";
import { useForm } from "../../../../Hooks/Forms/useForm";
import { useField } from "../../../../Hooks/Forms/useField";
import { Field } from "../../Field";

enum AMPM {
	AM = "AM",
	PM = "PM",
}

export const HaloTimeLabel = (props: HaloTimeLabelProps) => {
	const [editing, setEditing] = useState<boolean>(false);
	const [isAmPm, setIsAmPm] = useState<AMPM>(() =>
		props.timeToDisplay && props.timeToDisplay.hour > 11 ? AMPM.PM : AMPM.AM
	);

	function OpenTimeEdit() {
		setEditing(true);
	}

	function SaveTime(newHours: string, newMinutes: string) {
		// TODO: Check that end time isn't before end time.

		if (props.timeToDisplay && props.SetCustomTime) {
			const newHoursNum =
				newHours === ""
					? props.timeToDisplay.hour > 12
						? props.timeToDisplay.hour - 12
						: props.timeToDisplay.hour
					: parseInt(newHours) + (isAmPm === AMPM.PM ? 12 : 0);

			const newMinsNum = newMinutes === "" ? props.timeToDisplay.minute : parseInt(newMinutes);

			// console.log('new hours: ' + newHours + ' new minutes: ' + newMinutes)

			const newTime = DateTime.fromObject({
				hour: newHoursNum,
				minute: newMinsNum,
				second: props.timeToDisplay.second,
			});

			props.SetCustomTime(newTime);
		}

		setEditing(false);
	}

	const form = useForm({
		onSubmit: async (formData, valid) => {
			if (!valid) return;

			SaveTime(formData.hours, formData.minutes);
		},
	});

	const hoursField = useField("hours", form, {
		defaultValue: "",
		validations: [
			// formData => {
			// 	return formData.hours.length === 0 && "Missing hour";
			// },
			(formData) => formData.hours.length > 0 && !/\d+$/.test(formData.hours) && "Not valid hour",
			(formData) => formData.hours.length > 0 && Number(formData.hours) > 12 && "Not valid hour",
		],
		fieldsToValidateOnChange: [],
	});

	const minutesField = useField("minutes", form, {
		defaultValue: "",
		validations: [
			// formData => {
			// 	return formData.minutes.length === 0 && "Missing minutes";
			// },
			(formData) => formData.minutes.length > 0 && !/\d+$/.test(formData.minutes) && "Not valid minutes",
			(formData) => formData.minutes.length > 0 && Number(formData.minutes) > 59 && "Not valid minutes",
		],
		fieldsToValidateOnChange: [],
	});

	function SetPm() {
		setIsAmPm(AMPM.PM);
	}

	function SetAm() {
		setIsAmPm(AMPM.AM);
	}

	function CancelEditing() {
		setEditing(false);
	}

	return (
		<div className={"halo-time-label-wrapper " + props.className}>
			{props.SetCustomTime && editing ? (
				<form className="" onSubmit={form.onSubmit}>
					<button className="click-off-cancel" type="submit"></button>
					<div className={"halo-time-label-editing "}>
						<div className="hour-input-wrapper">
							<Field
								{...hoursField}
								formSubmitted={form.submitted}
								placeholder={
									props.timeToDisplay
										? props.timeToDisplay.hour > 12
											? props.timeToDisplay.hour - 12
											: props.timeToDisplay.hour
										: 0
								}
								type="text"
								className="hour-input"
								maxLength={2}
							/>
						</div>
						:
						<div className="minute-input-wrapper">
							<Field
								{...minutesField}
								formSubmitted={form.submitted}
								placeholder={props.timeToDisplay ? props.timeToDisplay.minute : 0}
								type="text"
								className="minute-input"
								maxLength={2}
							/>
						</div>
						<div className="am-pm-toggle">
							<button className="am-button" type="button" onClick={SetAm} disabled={isAmPm === AMPM.AM}>
								AM
							</button>
							<button className="pm-button" type="button" onClick={SetPm} disabled={isAmPm === AMPM.PM}>
								PM
							</button>
							<button className="cancel-editing-button" onClick={CancelEditing}>
								cancel
							</button>
						</div>
					</div>
				</form>
			) : (
				<div className={"halo-time-label"} onClick={OpenTimeEdit}>
					{props.timeToDisplay ? props.timeToDisplay.toLocaleString(DateTime.TIME_SIMPLE) : ""}
				</div>
			)}
		</div>
	);
};
