import { ISearchResult } from "./Models/ISearchResult";
import { KeyHelper } from "./KeyHelper";
import { ISearchResultTag } from "./Models/ISearchResultTag";
import { Guid } from "./Guid";
import { ITag } from "./Models/ITag";
import { ITask } from "./Models/ITask";
import { TheClaw } from "./TheClaw";
import { InstanceManager } from "./InstanceManager";
import { SpecialCause } from "./SpecialCause";

export class TaskHelper {
	// HACK: This is clearly crap, as it doesn't deal with tags
	public static SearchResultAsTask(aResult: ISearchResult) {
		return TaskHelper.updateTaskFromSearchResult(aResult);
	}

	public static ObjectIsTask(obj: unknown): obj is ITask {
		return typeof obj === "object" && obj !== null && "externalId" in obj && "integrationGuid" in obj && "name" in obj;
	}

	public static async TaskifySearchResult(aResult: ISearchResult, specialCause?: SpecialCause) {
		const taskKey = KeyHelper.GetSearchResultTaskKey(aResult);

		const existingTask = TheClaw.Tasks.Get(taskKey);
		const aTask = TaskHelper.updateTaskFromSearchResult(aResult, existingTask);
		TheClaw.Tasks.Set(taskKey, aTask, "TaskHelper", specialCause);

		const taskMetadata = TheClaw.TaskMetadatas.Get(taskKey);
		if (!taskMetadata)
			TheClaw.TaskMetadatas.Set(
				taskKey,
				{
					externalId: aResult.externalId,
					integrationGuid: aTask.integrationGuid,
					isFavorite: false,
					usageCount: 1,
					isTodo: false,
					createdWhen: InstanceManager.timeSource.GetUtcTime(),
					lastUpdatedWhen: InstanceManager.timeSource.GetUtcTime(),
				},
				"TaskHelper",
				specialCause
			);
		const tags = await Promise.all(
			aResult.tags.map((tag) => {
				return this.tagFromSearchResultTag(
					tag,
					aTask.integrationGuid,
					TheClaw.Tags.Get(KeyHelper.GetSearchResultTagKey(tag, aTask.integrationGuid))
				);
			})
		);
		const existingTags = TheClaw.TaskTagLinks.All().filter((x) => KeyHelper.GetTaskTagLinkTaskKey(x) === taskKey);
		for (const tag of tags) {
			TheClaw.Tags.Set(KeyHelper.GetTagKey(tag), tag, "TaskHelper", specialCause);
			if (!existingTags.some((x) => x.tagExternalId === tag.externalId)) {
				const newLink = {
					integrationGuid: tag.integrationGuid,
					tagExternalId: tag.externalId,
					taskExternalId: aTask.externalId,
				};
				TheClaw.TaskTagLinks.Set(KeyHelper.GetTaskTagLinkKey(newLink), newLink, "TaskHelper", specialCause);
			}
		}
		const tagLinksToDelete = existingTags.filter((x) => !tags.some((y) => y.externalId === x.tagExternalId));
		for (const tagLinkToDelete of tagLinksToDelete) {
			TheClaw.TaskTagLinks.Remove(KeyHelper.GetTaskTagLinkKey(tagLinkToDelete), "TaskHelper", specialCause);
		}
		return aTask;
	}

	public static SearchResultifyTask(aTask: ITask) {
		const tagLinks = TheClaw.TaskTagLinks.All().filter(
			(tt) => tt.integrationGuid == aTask.integrationGuid && tt.taskExternalId == aTask.externalId
		);
		const tags = TheClaw.Tags.All().filter(
			(tag) => tag.integrationGuid == aTask.integrationGuid && tagLinks.some((tl) => tl.tagExternalId == tag.externalId)
		);

		const aResult: ISearchResult = {
			...aTask,
			tags: tags.toArray(),
		};

		// console.log("tags: ", aResult.tags)
		return aResult;
	}

	private static tagFromSearchResultTag(t: ISearchResultTag, integrationGuid: Guid, existingTag?: ITag): ITag {
		const retval =
			existingTag ||
			({
				integrationGuid,
				externalId: t.externalId,
				createdWhen: InstanceManager.timeSource.GetUtcTime(),
			} as ITag);

		retval.lastUpdatedWhen = InstanceManager.timeSource.GetUtcTime();
		retval.tagTypeCodeName = t.tagTypeCodeName;
		retval.value = t.value;

		return retval;
	}

	private static updateTaskFromSearchResult(aResult: ISearchResult, existingTask?: ITask): ITask {
		const retval =
			existingTask ||
			({
				integrationGuid: aResult.integrationGuid,
				externalId: aResult.externalId,
				externalCreatedWhen: aResult.externalCreatedWhen,
				createdWhen: InstanceManager.timeSource.GetUtcTime(),
			} as ITask);

		retval.name = aResult.name;
		retval.status = aResult.status;
		retval.dueWhen = aResult.dueWhen;
		retval.assignee = aResult.assignee;
		retval.description = aResult.description;
		retval.linkUrl = aResult.linkUrl;

		retval.metadata = JSON.stringify({
			// TODO: task metadata
		});

		if (!existingTask || JSON.stringify(existingTask) !== JSON.stringify(retval)) {
			retval.lastUpdatedWhen = InstanceManager.timeSource.GetUtcTime();
		}

		return retval;
	}
}
