import React from "react";
import { ModalContextModel } from "./ModalContextModel";
import { ModalContextAction } from "./ModalContextAction";
import { ModalContextDispatchActionType } from "./ModalContextDispatchActionType";
import { ModalContextProviderProps } from "./ModalContext.Interface";
import { Ticker } from "../../Hooks/useTime";

export const ModalContext = React.createContext({
	state: new ModalContextModel(),
	dispatch: {} as React.Dispatch<ModalContextAction>,
});

function ModalReducer(state: ModalContextModel, action: ModalContextAction) {
	switch (action.type) {
		case ModalContextDispatchActionType.SetModalComponent:
			return { ...state, modalComponent: action.payload };
		case ModalContextDispatchActionType.OpenModal:
			Ticker.PauseTick();
			return { ...state, showModal: true };
		case ModalContextDispatchActionType.CloseModal:
			Ticker.ResumeTick();
			return { ...state, modalComponent: undefined, showModal: false, preventClose: false };
		case ModalContextDispatchActionType.SetPreventClose:
			return { ...state, preventClose: action.payload };
		case ModalContextDispatchActionType.SetCustomCloseCallback:
			return { ...state, customCloseCallback: action.payload };
		case ModalContextDispatchActionType.SetCustomClassName:
			return { ...state, customClassName: action.payload };
		default:
			return { ...state };
	}
}

export const ModalContextProvider = (props: ModalContextProviderProps) => {
	const [state, dispatch] = React.useReducer(ModalReducer, {
		modalComponent: undefined,
		showModal: false,
		preventClose: false,
		customCloseCallback: undefined,
		customClassName: "",
	});
	const value = { state, dispatch };

	return <ModalContext.Provider value={value}>{props.children}</ModalContext.Provider>;
};
