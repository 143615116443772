import React, { useState, useEffect, useContext } from "react";
import { EditTimeModalProps } from "./EditTimeModal.Interface";
import "./EditTimeModal.scss";
import { useForm } from "../../../Hooks/Forms/useForm";
import { useField } from "../../../Hooks/Forms/useField";
import { Field } from "../Field";
import { DateTime } from "luxon";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import nameof from "ts-nameof.macro";
import { ITimeEntry } from "../../../Data/Models/ITimeEntry";
import { TheHook } from "../../../Hooks/TheHook/TheHook";
import { ModalContext } from "../../../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../../../Context/ModalContext/ModalContextDispatchActionType";
import { TimelineContext } from "../../../Context/TimelineContext/TimelineContext";
import { TimelineContextDispatchActionType } from "../../../Context/TimelineContext/TimelineContextDispatchActionType";
import { useGroups } from "../../../Hooks/useGroups";
import { KeyHelper } from "../../../Data/KeyHelper";
import { useTasks } from "../../../Hooks/useTasks";
import { EditModeTimeline } from "../../../Containers/EditModeTimeline/EditModeTimeline";
import { InstanceManager } from "../../../Data/InstanceManager";

enum WarningType {
	None = "none",
	Adjusted = "adjusted", // appears if any time entry would be adjusted by the new times
	Remove = "remove", // only appears if a time entry would be removed by the new times
	Split = "split", // only appears if a time entry would be split by the new times
	AfterCurrent = "after", // appears if the start or end time is after current time
	Invalid = "invalid", // appears if the start time is after the end time, or vise versa
}

// NEED TO CHECK FOR CURRENTLY RUNNING TIME ENTRY AND NOT ALLOW THEM TO EDIT END TIME WITHOUT PAUSING.
// NEED TO CHECK FOR START TIME BEING AFTER END TIME AND VICE VERSA

export const EditTimeModal = (props: EditTimeModalProps) => {
	const timeEntries = useTimeEntries(nameof(EditTimeModal));
	const Groups = useGroups(nameof(EditTimeModal));
	const Tasks = useTasks(nameof(EditTimeModal));
	const theHook = TheHook(nameof(EditTimeModal));

	const modalContext = useContext(ModalContext);
	const timelineContext = useContext(TimelineContext);

	const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
	const [startTimeWarningType, setStartTimeWarningType] = useState<string>(WarningType.None);
	const [endTimeWarningType, setEndTimeWarningType] = useState<string>(WarningType.None);

	// Make start and end bold
	useEffect(() => {
		setWarningMessage("");

		// Warning messages in order of priority (adjustment -> split -> remove -> invalid)
		// These can definitely be simplified by having a single modified message instead of separate ones for each warning.
		if (startTimeWarningType === WarningType.Adjusted && endTimeWarningType === WarningType.Adjusted) {
			setWarningMessage("Changing the start and end times of this time entry will override adjacent entries");
		} else if (startTimeWarningType === WarningType.Adjusted) {
			setWarningMessage("Changing the start time of this time entry will override adjacent entries");
		} else if (endTimeWarningType === WarningType.Adjusted) {
			setWarningMessage("Changing the end time of this time entry will override adjacent entries");
		}

		if (startTimeWarningType === WarningType.Split && endTimeWarningType === WarningType.Split) {
			setWarningMessage("Changing the start and end times of this time entry will split an entry into two");
		} else if (startTimeWarningType === WarningType.Split) {
			setWarningMessage("Changing the start time of this time entry will Split an entry into two");
		} else if (endTimeWarningType === WarningType.Split) {
			setWarningMessage("Changing the end time of this time entry will Split an entry into two");
		}

		if (startTimeWarningType === WarningType.Remove && endTimeWarningType === WarningType.Remove) {
			setWarningMessage("Changing the start and end times of this time entry will remove adjacent entries");
		} else if (startTimeWarningType === WarningType.Remove) {
			setWarningMessage("Changing the start time of this time entry will remove adjacent entries");
		} else if (endTimeWarningType === WarningType.Remove) {
			setWarningMessage("Changing the end time of this time entry will remove adjacent entries");
		}

		if (startTimeWarningType === WarningType.Invalid || endTimeWarningType === WarningType.Invalid) {
			setWarningMessage("New Start time cannot be after End time");
		}

		if (startTimeWarningType === WarningType.AfterCurrent && endTimeWarningType === WarningType.AfterCurrent) {
			setWarningMessage("New Start and End times cannot be after current time");
		} else if (startTimeWarningType === WarningType.AfterCurrent) {
			setWarningMessage("New Start time cannot be after current time");
		} else if (endTimeWarningType === WarningType.AfterCurrent) {
			setWarningMessage("New End time cannot be after current time");
		}
	}, [startTimeWarningType, endTimeWarningType]);

	function SaveTime(startTime: string, endTime?: string) {
		if (
			startTimeWarningType !== WarningType.AfterCurrent ||
			(endTimeWarningType !== WarningType.AfterCurrent && (startTime || endTime))
		) {
			let confirm = false;
			if (startTimeWarningType === WarningType.Remove || endTimeWarningType === WarningType.Remove) {
				confirm = window.confirm("This will delete time entries. Continue?");
			} else {
				confirm = true;
			}

			// if (!confirm && (startTimeWarningType !== WarningType.None || endTimeWarningType !== WarningType.None)) {
			// 	confirm = window.confirm("This will alter existing time entries. Continue?");
			// } else {
			// 	confirm = true;
			// }

			if (confirm) {
				const date = props.timeEntry.startedWhen.toFormat("yyyy-LL-dd");

				const newStartTime = DateTime.fromISO(date + "T" + startTime);
				const newEndTime = endTime ? DateTime.fromISO(date + "T" + endTime) : undefined;

				theHook.DragCollision(props.timeEntry.timeEntryGuid, newStartTime, newEndTime);
				return true;
			}
		}

		return false;
	}

	function ValidateTime(formData: any) {
		setStartTimeWarningType(WarningType.None);
		setEndTimeWarningType(WarningType.None);

		if (formData.startTime && formData.endTime) {
			const date = props.timeEntry.startedWhen.toFormat("yyyy-LL-dd");

			const newStartTime = DateTime.fromISO(date + "T" + formData.startTime);
			const newEndTime = formData.endTime ? DateTime.fromISO(date + "T" + formData.endTime) : undefined;

			if (newEndTime && newStartTime > newEndTime) {
				setStartTimeWarningType(WarningType.Invalid);
			}

			const localTime = InstanceManager.timeSource.GetLocalTime();

			timeEntries
				.GetSetDaysEntries(localTime.minus({ days: timelineContext.state.currentDayOffset }))
				.forEach((te: ITimeEntry) => {
					if (te.timeEntryGuid !== props.timeEntry.timeEntryGuid) {
						if (!newEndTime) return;
						if (newStartTime > localTime || newEndTime > localTime) {
							// Checks if start or end time is after current time
							if (newStartTime > localTime) {
								setStartTimeWarningType(WarningType.AfterCurrent);
							}
							if (newEndTime > localTime) {
								setEndTimeWarningType(WarningType.AfterCurrent);
							}
						} else if (newStartTime > newEndTime) {
							setStartTimeWarningType(WarningType.Invalid);
						} else if (newEndTime < newStartTime) {
							setStartTimeWarningType(WarningType.Invalid);
						} else if (te.endedWhen && newStartTime < te.startedWhen && newEndTime > te.endedWhen) {
							// Checks for removes
							if (props.timeEntry.startedWhen < te.startedWhen) {
								setEndTimeWarningType(WarningType.Remove);
							} else {
								setStartTimeWarningType(WarningType.Remove);
							}
						} else if (te.endedWhen && newStartTime > te.startedWhen && newEndTime < te.endedWhen) {
							// Checks for splits
							setStartTimeWarningType(WarningType.Split);
							setEndTimeWarningType(WarningType.Split);
						} else if (te.endedWhen && newStartTime < te.endedWhen && newEndTime > te.endedWhen) {
							// Checks for end time adjustments
							setStartTimeWarningType(WarningType.Adjusted);
						} else if (newStartTime < te.startedWhen && newEndTime > te.startedWhen) {
							// Checks for start time adjustments
							setEndTimeWarningType(WarningType.Adjusted);
						}
					}
				});

			console.log("new start time: ", newStartTime.toLocaleString());
			console.log("new end time: ", newEndTime && newEndTime.toLocaleString());
		}

		return "";
	}

	const form = useForm({
		onSubmit: (formData, valid) => {
			if (!valid) return;

			if (SaveTime(formData.startTime, formData.endTime)) {
				CloseModal();
			}
		},
	});

	const startTimeField = useField("startTime", form, {
		defaultValue: props.timeEntry.startedWhen.toISOTime(),
		validations: [
			(formData) => ValidateTime(formData),
			(formData) => {
				return formData.startTime.length === 0 && "Missing start time";
			},
		],
		fieldsToValidateOnChange: [],
	});

	const endTimeField = useField("endTime", form, {
		defaultValue: props.timeEntry.endedWhen ? props.timeEntry.endedWhen.toISOTime() : undefined,
		validations: [
			(formData) => ValidateTime(formData),
			(formData) => {
				return !!props.timeEntry.endedWhen && formData.endTime.length === 0 && "Missing end time";
			},
		],
		fieldsToValidateOnChange: [],
	});

	function OpenInTimeline() {
		timelineContext.dispatch({
			type: TimelineContextDispatchActionType.ScrollToTimeEntry,
			payload: props.timeEntry,
		});

		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetModalComponent,
			payload: (
				<>
					<EditModeTimeline />
				</>
			),
		});
		modalContext.dispatch({
			type: ModalContextDispatchActionType.OpenModal,
			payload: true,
		});

		CloseModal();
	}

	function CloseModal() {
		modalContext.dispatch({
			type: ModalContextDispatchActionType.CloseModal,
			payload: undefined,
		});
	}

	function GetTaskName() {
		const myGroup = Groups.Get(props.timeEntry.timeEntrySetGuid);
		if (myGroup) {
			if (myGroup.taskIntegrationGuid) {
				const taskGuid = KeyHelper.GetTimeEntrySetTaskKey(myGroup);
				const task = Tasks.Get(taskGuid);
				if (task) {
					return task.name;
				}
			}
			if (myGroup.name) {
				return myGroup.name;
			}
		}
		// TODO: Handle this issue
		return "Unassigned";
	}

	return (
		<section className="edit-time-modal">
			<div className="total-time">
				{(props.timeEntry.endedWhen
					? props.timeEntry.endedWhen.diff(props.timeEntry.startedWhen, "hours").hours
					: 0
				).toFixed(1)}
				h
			</div>
			<div className="task-name">{GetTaskName()}</div>
			<button className="edit-in-timeline" onClick={OpenInTimeline}>
				Edit in timeline
			</button>
			<div className="separator" />
			<form className="set-day-time" onSubmit={form.onSubmit}>
				{startTimeWarningType === WarningType.Remove ||
				startTimeWarningType === WarningType.AfterCurrent ||
				startTimeWarningType === WarningType.Invalid
					? "!!! "
					: startTimeWarningType === WarningType.Split
					? "!! "
					: startTimeWarningType === WarningType.Adjusted
					? "! "
					: ""}
				<br />
				<Field
					type="time"
					label="Start Time"
					{...startTimeField}
					formSubmitted={form.submitted}
					className="starting-hour-input"
				/>
				<br />
				{endTimeWarningType === WarningType.Remove ||
				endTimeWarningType === WarningType.AfterCurrent ||
				endTimeWarningType === WarningType.Invalid
					? "!!! "
					: endTimeWarningType === WarningType.Split
					? "!! "
					: endTimeWarningType === WarningType.Adjusted
					? "! "
					: ""}

				<Field
					type="time"
					label="End Time"
					{...endTimeField}
					formSubmitted={form.submitted}
					className="ending-hour-input"
					disabled={!props.timeEntry.endedWhen}
				/>
				<div
					className={
						"warning-message" +
						(startTimeWarningType === WarningType.Remove || endTimeWarningType === WarningType.Remove
							? WarningType.Remove
							: startTimeWarningType === WarningType.Adjusted || endTimeWarningType === WarningType.Adjusted
							? WarningType.Adjusted
							: WarningType.None)
					}
				>
					{warningMessage ? warningMessage : undefined}
				</div>
				<button
					className="save-time"
					type="submit"
					disabled={
						endTimeWarningType === WarningType.AfterCurrent ||
						startTimeWarningType === WarningType.AfterCurrent ||
						startTimeWarningType === WarningType.Invalid ||
						endTimeWarningType === WarningType.Invalid
					}
				>
					Save
				</button>
				<button className="cancel" onClick={CloseModal}>
					Cancel
				</button>
			</form>
		</section>
	);
};
