import { useEffect, useRef } from "react";

export function useInterval(callback: () => void, delay: number) {
	const savedCallback = useRef<(() => void) | null>(null);

	useEffect(() => {
		savedCallback.current = callback;
	});

	useEffect(() => {
		function tick() {
			if (savedCallback.current) savedCallback.current();
		}

		const id = setInterval(tick, delay);
		return () => clearInterval(id);
	}, [delay]);
}
