import { Source } from "../Data/Source";
import { TheClaw } from "../Data/TheClaw";
import { ITimeEntrySet } from "../Data/Models/ITimeEntrySet";
import { IUseSubscribableCollectionType, useSubscribableCollection } from "./useSubscribable";
import { GroupSubscribable } from "../Data/GroupSubscribable";
import { List } from "immutable";
import { SpecialCause } from "../Data/SpecialCause";

export interface IUseGroups extends IUseSubscribableCollectionType<ITimeEntrySet> {
	AllNotDeleted(): List<ITimeEntrySet>;
}

/**
 *
 * Causes re-render when any item in Tasks changes
 * @param source
 */
export function useGroups(
	source?: Source,
	specialCauses?: SpecialCause[],
	dataSource: GroupSubscribable = TheClaw.Groups
): IUseGroups {
	const subscribable = useSubscribableCollection(dataSource, source, specialCauses);
	return {
		...subscribable,
		AllNotDeleted: () => dataSource.AllNotDeleted(),
	};
}
