import React, { useContext, useEffect } from "react";
import "./CommentsView.scss";
import { TimelineContext, timelineNotesSortModeEnum } from "../../../Context/TimelineContext/TimelineContext";
import { CommentsPage } from "../../../Containers/CommentsPage/CommentsPage";
import { ViewContext } from "../../../Context/ViewContext/ViewContext";
import { ViewContextDispatchActionType } from "../../../Context/ViewContext/ViewContextDispatchActionType";
import { TimelineContextDispatchActionType } from "../../../Context/TimelineContext/TimelineContextDispatchActionType";
import { ModalContext } from "../../../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../../../Context/ModalContext/ModalContextDispatchActionType";
import { AddTimeEntryModal } from "../../Library/AddTimeEntryModal/AddTimeEntryModal";

export function CommentsView() {
	const viewContext = useContext(ViewContext);
	const timelineContext = useContext(TimelineContext);
	const modalContext = useContext(ModalContext);

	useEffect(() => {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.ShowTabs,
		});
	}, []);

	function ToggleNotesSortMode() {
		timelineContext.dispatch({
			type: TimelineContextDispatchActionType.ToggleTimelineSortMode,
			payload: null,
		});
	}

	function CreateNewTimeEntry() {
		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetModalComponent,
			payload: <AddTimeEntryModal />,
		});

		modalContext.dispatch({
			type: ModalContextDispatchActionType.OpenModal,
			payload: undefined,
		});
	}

	return (
		<>
			{/*&<section
					id={timeDisplay === timeDisplayType.timeline ?
						timeDisplayId.timelineId : timeDisplayId.timeNotesId}

					className={timeDisplay === timeDisplayType.timeline ?
						timeDisplayClassName.timelineClassName : timeDisplayClassName.timeNotesClassName}
					>*/}

			<section id="comments-view-wrapper">
				<section id="comments-view-controls-header">
					<div
						id="comments-toggle"
						className={
							"toggle-wrapper toggle-2-state light-theme sz-md " +
							(timelineContext.state.timelineSortMode == timelineNotesSortModeEnum.sortByTime
								? "time-view-active toggle-option-1-active"
								: "group-view-active  toggle-option-2-active")
						}
					>
						<button
							id="orderby-time"
							className={
								"toggle-option toggle-option-1 " +
								(timelineContext.state.timelineSortMode == timelineNotesSortModeEnum.sortByTime ? "active" : "")
							}
							title="Order by time"
							aria-label="Order by time"
							onClick={ToggleNotesSortMode}
							disabled={timelineContext.state.timelineSortMode === timelineNotesSortModeEnum.sortByTime}
						/>
						<button
							id={"orderby-group"}
							className={
								"toggle-option toggle-option-2 " +
								(timelineContext.state.timelineSortMode == timelineNotesSortModeEnum.sortByGroup ? "active" : "")
							}
							title="Order by task group"
							aria-label="Order by task group"
							onClick={ToggleNotesSortMode}
							disabled={timelineContext.state.timelineSortMode === timelineNotesSortModeEnum.sortByGroup}
						/>

						<div className="active-indicator" />
						<div className="toggle-background" aria-hidden="true" />
					</div>
				</section>
				{/*<TimeViewHeader SetTimeDisplayNotes={SetTimeDisplayNotes} SetTimeDisplayTimeline={SetTimeDisplayTimeline} />*/}

				{/*<Timeline LinkGroupToActiveTask={LinkGroupToActiveTask} GroupUnlink={GroupUnlink} />*/}
				<CommentsPage />

				<button
					className="add-time-entry primary icon-only circle sz-3"
					title="Add new time entry"
					onClick={CreateNewTimeEntry}
				>
					<span className="sr-only">Add new time entry</span>
				</button>
			</section>
			{/*<Tasklist />*/}
		</>
	);
}
