import { cm } from "./ConnectionManager";
import { ISearchResponseModel } from "./Models/ISearchResponseModel";
import { ITokenIntegrationModel } from "./AuthManager";
import { ISearchResult } from "./Models/ISearchResult";
import { IGetTasksRequestModelV2 } from "./Models/IGetTasksRequestModelV2";
import { IGetTasksResponseModel } from "./Models/IGetTasksResponseModel";
import { ITask } from "./Models/ITask";
import { IUpdateTaskRequestModel } from "./Models/IUpdateTaskRequestModel";
import { TheClaw } from "./TheClaw";
import { ITaskRequestModelV2 } from "./Models/ITaskRequestModelV2";
import { InstanceManager } from "./InstanceManager";
import { ensureTaskDates, ensureTagDates, toUtcDate } from "./ChronometricDB";
import { DateTime } from "luxon";

// TODO: Split into search/tasks/export APIs
export class IntegrationApiConnection {
	public async Search(searchTerm: string) {
		const integrations = InstanceManager.auth.GetIntegrations();

		if (!integrations) {
			throw Error("jwt data unavailable");
		}

		const responses = await Promise.all(
			integrations.map(async (ig) => {
				try {
					const resp = await cm.Fetch(`${ig.integrationUrl}search`, {
						method: "POST",
						body: JSON.stringify({ searchTerm }),
						headers: {
							"Content-type": "application/json",
						},
					});
					return (await resp.json()) as ISearchResponseModel;
				} catch (err) {
					return null;
				}
			})
		);

		return responses.reduce((acc, curr) => {
			if (curr) {
				acc = acc.concat(curr.results.map(ensureSearchResultDates));
			}
			return acc;
		}, [] as ISearchResult[]);
	}

	public async NewTasks() {
		const integrations = InstanceManager.auth.GetIntegrations();

		if (!integrations) {
			throw Error("jwt data unavailable");
		}

		const responses = await Promise.all(
			integrations.map(async (ig) => {
				try {
					const resp = await cm.Fetch(`${ig.integrationUrl}new`, {
						method: "POST",
						headers: {
							"Content-type": "application/json",
						},
					});
					return (await resp.json()) as ISearchResponseModel;
				} catch (err) {
					return null;
				}
			})
		);

		return responses.reduce((acc, curr) => {
			if (curr) {
				acc = acc.concat(curr.results.map(ensureSearchResultDates));
			}
			return acc;
		}, [] as ISearchResult[]);
	}

	public async GetTasks(
		integration: ITokenIntegrationModel,
		tasks: ITaskRequestModelV2[],
		from: DateTime,
		clientId: string
	) {
		const response = await cm.Fetch(`${integration.integrationUrl}Tasks/ListV2`, {
			method: "POST",
			body: JSON.stringify({
				tasks,
				from: from.toUTC(),
				clientId,
			} as IGetTasksRequestModelV2),
			headers: {
				"Content-type": "application/json",
			},
		});
		const responseObj = (await response.json()) as IGetTasksResponseModel;
		responseObj.tasks = responseObj.tasks.map(ensureTaskDates);
		responseObj.tags = responseObj.tags.map(ensureTagDates);
		return responseObj;
	}

	public async UpdateTasks(integration: ITokenIntegrationModel, items: ITask[], clientId?: string) {
		for (const item of items) {
			const tagLinks = TheClaw.TaskTagLinks.All().filter(
				(tt) => tt.integrationGuid === item.integrationGuid && tt.taskExternalId === item.externalId
			);
			const tags = TheClaw.Tags.All()
				.filter(
					(tag) =>
						tag.integrationGuid === item.integrationGuid && tagLinks.some((tl) => tl.tagExternalId === tag.externalId)
				)
				.toArray();

			// TODO: Bulk update
			const response = await cm.Fetch(`${integration.integrationUrl}Tasks/Update`, {
				method: "POST",
				body: JSON.stringify({
					task: item,
					tags,
					clientId,
				} as IUpdateTaskRequestModel),
				headers: {
					"Content-type": "application/json",
				},
			});
			if (!response.ok) {
				throw Error(`${response.status} ${response.statusText}`);
			}
		}
	}
}

function ensureSearchResultDates(sr: ISearchResult) {
	sr.dueWhen = sr.dueWhen ? toUtcDate(sr.dueWhen) : undefined;
	sr.externalCreatedWhen = toUtcDate(sr.externalCreatedWhen);
	return sr;
}
