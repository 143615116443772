import { useContext } from "react";
import { useTimeEntryChecker } from "./useTimeEntryChecker";
import { ForgottenTimeEntryContext } from "../Context/ForgottenTimeEntryContext/ForgottenTimeEntryContext";
import { ForgottenTimeEntryContextDispatchActionType } from "../Context/ForgottenTimeEntryContext/ForgottenTimeEntryContextDispatchActionType";

export function useCheckForForgottenTimeEntries(source: string) {
	const forgottenTimeEntryChecker = useTimeEntryChecker(source);
	const forgottenTimeContext = useContext(ForgottenTimeEntryContext);

	return () => {
		const forgotEntry = forgottenTimeEntryChecker.CheckTodaysEntries();
		if (!forgotEntry) {
			return false;
		}

		forgottenTimeContext.dispatch({
			type: ForgottenTimeEntryContextDispatchActionType.SetModalTimeEntry,
			payload: forgotEntry,
		});
		return true;
	};
}
