import React from "react";
import { EditModeContextModel } from "./EditModeContextModel";
import { EditModeContextAction } from "./EditModeContextAction";
import { EditModeContextDispatchActionType } from "./EditModeContextDispatchActionType";
import { EditModeContextProviderProps } from "./EditModeContext.Interface";

// Need to remove the menu stuff from this context and put it in a more generic context.

export const EditModeContext = React.createContext({
	state: new EditModeContextModel(),
	dispatch: {} as React.Dispatch<EditModeContextAction>,
});

function EditModeReducer(state: EditModeContextModel, action: EditModeContextAction): EditModeContextModel {
	switch (action.type) {
		case EditModeContextDispatchActionType.SetMouseUpCallbackName:
			return { ...state, mouseUpCallbackName: action.payload };
		case EditModeContextDispatchActionType.UndoRedoTriggerToggle:
			return { ...state, undoRedoTrigger: !state.undoRedoTrigger };
		case EditModeContextDispatchActionType.setEditModeDayOffset:
			return { ...state, editModeDayOffset: action.payload };
		default:
			return { ...state };
	}
}

export const EditModeContextProvider = (props: EditModeContextProviderProps) => {
	const [state, dispatch] = React.useReducer(EditModeReducer, {
		mouseUpCallbackName: undefined,
		editModeDayOffset: 0,
		undoRedoTrigger: false,
	});
	const value = { state, dispatch };

	return <EditModeContext.Provider value={value}>{props.children}</EditModeContext.Provider>;
};
