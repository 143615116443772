import { TheClaw } from "../Data/TheClaw";
import { useEffect, useRef } from "react";
import { Subscriber } from "../Data/Subscriber";
export function useTimeEntrySetUpdateEvent(source: string, callback: () => void) {
	const groupSubscriber = useRef<Subscriber<string>>();

	useEffect(() => {
		const groupSub: Subscriber<string> = {
			callback: (_action, _key) => callback(),
			source,
		};
		TheClaw.Groups.Subscribe(groupSub);
		groupSubscriber.current = groupSub;

		callback();

		return () => {
			if (groupSubscriber.current) TheClaw.Groups.UnSubscribe(groupSubscriber.current);
		};
	}, [source, callback]);
}
