import React from "react";
import "./RoundingSettingsView.scss";
// NOTE: This view is temporary and is only until the account screen (and thus integration settings) are completed.

export function IntegrationButton(props: {
	integrationGuid: string;
	selectedIntegration: string | undefined;
	SetSelectedIntegration: (integrationGuid: string | undefined) => void;
}) {
	function HandleClick() {
		props.SetSelectedIntegration(
			props.selectedIntegration === props.integrationGuid ? undefined : props.integrationGuid
		);
	}

	return (
		<button className="integration-select-button" onClick={HandleClick}>
			Integration: {props.integrationGuid}
		</button>
	);
}
