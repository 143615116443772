export enum ViewContextDispatchActionType {
	// setCurrentView,
	LogInView,
	ForgottenTimeEntryView,
	DashboardView,
	CommentsView,
	EditTimelineView,
	TasklistView,
	ExportView,
	ShowTabs,
	HideTabs,
	ShowFullscreenLoadingMessage,
	HideFullscreenLoadingMessage,
	SetRefreshing,
	RoundingSettingsView, // NOTE: This is temporary until the account screen is finished
}
