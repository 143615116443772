import React from "react";
import { TaskSummaryHeaderProps } from "./TaskSummaryHeader.Interface";
import "../TaskSummary/TaskSummary.scss";
import { useTaskTagLinks } from "../../../../Hooks/useTaskTagsLinks";
import nameof from "ts-nameof.macro";
import { useTags, JiraTagTypeCodeNames } from "../../../../Hooks/useTags";
import { TaskExternalLink } from "../../GroupHeader/taskExternalLink";

export const TaskSummaryHeader = (props: TaskSummaryHeaderProps) => {
	const taskTagLinks = useTaskTagLinks(nameof(TaskSummaryHeader));
	const tags = useTags(nameof(TaskSummaryHeader));

	const links = taskTagLinks
		.All()
		.filter((tt) => tt.integrationGuid === props.Task?.integrationGuid && tt.taskExternalId === props.Task.externalId);

	const taskTags = tags
		.All()
		.filter(
			(t) => t.integrationGuid === props.Task?.integrationGuid && links.some((l) => l.tagExternalId === t.externalId)
		);

	const projectTag = taskTags.find((x) => x.tagTypeCodeName === JiraTagTypeCodeNames.project);

	return (
		<>
			{props.Task ? (
				<>
					{/* TO DO - Add Project Name (or integration?)  */}
					<div className="task-title">
						<h2 className="task-project" title={projectTag?.value}>
							{projectTag?.value}
						</h2>
						<div className="task-name-wrapper">
							<h3 className="task-name" title={props.Task.name}>
								{props.Task.name}
							</h3>
							<TaskExternalLink task={props.Task} inverseColor={true} />
						</div>
					</div>
					<button className="unlink-button" onClick={props.OpenTaskListModal} />
				</>
			) : (
				<></>
			)}
		</>
	);
};
