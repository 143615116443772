export class ViewContextModel {
	public currentView?: JSX.Element;
	public viewName?: string;
	public showTabs?: boolean;
	public showLoadingSpinner?: boolean;
	public refreshing?: boolean;
	public loadingMessage?: string;
	constructor(mcm?: Partial<ViewContextModel>) {
		Object.assign(this, mcm);
	}
}
