import React from "react";
import "./TasklistView.scss";
import { Playhead } from "../../Library/Playhead/Playhead";
import { Tasklist } from "../../../Containers/Tasklist/Tasklist";

export function TasklistView() {
	return (
		<div id="app-wrapper" className={"mode-dashboard-hide"}>
			<main className="">
				<section id="tasklist" className="tasklist">
					<Tasklist />
				</section>
				<Playhead />
			</main>
		</div>
	);
}
