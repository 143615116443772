import { useState } from "react";
import { DateTime } from "luxon";

export const useForceUpdate = () => {
	const [, setState] = useState(() => DateTime.utc());
	return () => {
		setState(DateTime.utc());
	};
};

export const useForceUpdateWithTimestamp = () => {
	const [state, setState] = useState(() => DateTime.utc());
	return {
		forceUpdate: () => {
			setState(DateTime.utc());
		},
		timestamp: state,
	};
};
