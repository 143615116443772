import { useMemo } from "react";
import { useTime } from "../../../Hooks/useTime";
import { DateTime, Duration } from "luxon";
import { useTimerCalulatedData } from "../../../Hooks/useTimerCalulatedData";
import { ITimeEntry } from "../../../Data/Models/ITimeEntry";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import { formatDurationElements } from "./useGroupTime";

export function useCompressedTimeEntryTime(compressedTimeEntry: ITimeEntry[], source: string) {
	const time = useTime();
	const { isPlaying } = useTimerCalulatedData(source);
	const timeEntries = useTimeEntries(source);
	return useMemo(() => {
		const timeInSeconds = compressedTimeEntry.reduce(
			(acc, curr) => acc.plus((curr.endedWhen || DateTime.local()).diff(curr.startedWhen, "seconds")),
			Duration.fromMillis(0)
		);
		return formatDurationElements(timeInSeconds);
	}, [isPlaying, isPlaying ? time : undefined, timeEntries.timestamp]);
}
