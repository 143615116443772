import React, { useState, useRef, useEffect, useContext } from "react";
import { TaskProps } from "./Task.Interface";
import "./Task.scss";
import "../GroupIndicators/group-indicators.scss";
import { KeyHelper } from "../../../Data/KeyHelper";
import { useForceUpdate } from "../../../Hooks/useForceUpdate";
import { JiraTagTypeCodeNames } from "../../../Hooks/useTags";
import { TasklistFuntion } from "../../../Repeaters/TaskRepeater/TaskRepeater";
import { TaskCardSizes, SearchContext } from "../../../Context/SearchContext/SearchContext";
import nameof from "ts-nameof.macro";
import { useTimeControls } from "../../../Hooks/useTimeControls";
import { useGroups } from "../../../Hooks/useGroups";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import { useTaskMetadatas } from "../../../Hooks/useTaskMetadatas";
import { useTimerCalulatedData } from "../../../Hooks/useTimerCalulatedData";
import { InstanceManager } from "../../../Data/InstanceManager";
import { TaskExternalLink } from "../GroupHeader/taskExternalLink";

// need to update treelines ref and tasklist y on scroll and when task filters get changed

export const Task = (props: TaskProps) => {
	const buttonRef = useRef<HTMLButtonElement>(null);
	const forceUpdate = useForceUpdate();
	const [showExpanded, setShowExpanded] = useState<boolean>(false);
	const searchContext = useContext(SearchContext);
	const timeControls = useTimeControls(nameof(Task));
	const groups = useGroups();
	const timeEntries = useTimeEntries();
	const taskMetadatas = useTaskMetadatas();
	const isActiveTask = searchContext.state.activeTaskKey === KeyHelper.GetTaskKey(props.task);
	const projectName =
		props.task.tags.find((x) => x.tagTypeCodeName === JiraTagTypeCodeNames.project)?.value || "No project name";
	const customerName =
		props.task.tags.find((x) => x.tagTypeCodeName === JiraTagTypeCodeNames.customer)?.value || "No customer name";
	const { isPlaying } = useTimerCalulatedData(nameof(Task));
	const metaData = taskMetadatas.Get(KeyHelper.GetSearchResultTaskKey(props.task));
	const isFavourite = metaData && metaData.isFavorite ? true : false;

	function LinkTo() {
		props.LinkTo(props.task);
	}

	function TogglePlay() {
		if (isActiveTask) {
			timeControls.TogglePlay();
			if (!isPlaying && props.CloseModal) {
				props.CloseModal();
			}
		} else {
			props.SwitchToThisTask(props.task);
			if (props.CloseModal) {
				props.CloseModal();
			}
		}
	}

	function ToggleFavouriteTask() {
		if (isFavourite) {
			props.UnFavouriteTask(props.task);
			forceUpdate();
		} else {
			props.FavouriteTask(props.task);
			forceUpdate();
		}
	}

	function ToggleExpandTaskInfo() {
		setShowExpanded(!showExpanded);

		// if (showExpanded === false) {
		// 	setShowExpanded(!showExpanded);
		// } else {
		// 	setTimeout(() => {
		// 		setShowExpanded(!showExpanded);
		// 	}, 500);
		// }
	}

	const taskRunningTimeHours = groups
		.AllNotDeleted()
		.filter((x) => KeyHelper.GetTimeEntrySetTaskKey(x) === KeyHelper.GetTaskKey(props.task))
		.reduce(
			(prev, curr) =>
				prev +
				timeEntries
					.GetForGroup(curr.timeEntrySetGuid)
					.reduce(
						(prevTE, currTE) =>
							prevTE +
							(currTE.endedWhen || InstanceManager.timeSource.GetLocalTime()).diff(currTE.startedWhen, "hours").hours,
						0
					),
			0
		)
		.toFixed(1);

	return (
		<section
			className={
				"task-list-item-wrapper size-" +
				searchContext.state.taskCardSize +
				// (isActiveTask ? " mode-active" : "") +
				(showExpanded ? " mode-expanded" : " mode-minimised") +
				(props.tasklistFunction === TasklistFuntion.play
					? isActiveTask && isPlaying
						? " mode-pause"
						: " mode-play"
					: isActiveTask
					? " mode-unlink"
					: " mode-link")
			}
		>
			<div className="task-cta-wrapper">
				<button
					onClick={props.tasklistFunction === TasklistFuntion.link ? LinkTo : TogglePlay}
					ref={buttonRef}
					className={
						"task-button " +
						(props.tasklistFunction === TasklistFuntion.play
							? isActiveTask && isPlaying
								? " icon-only pause"
								: " icon-only play"
							: isActiveTask
							? " unlink"
							: " link")
					}
				>
					<span className="task-button-label">
						{props.tasklistFunction === TasklistFuntion.play
							? isActiveTask && isPlaying
								? ""
								: ""
							: isActiveTask
							? "Unlink"
							: "Link"}
					</span>
				</button>
			</div>

			<button
				className={"favourite icon-only sz-1 " + (isFavourite ? " isFavourite" : " notFavourite")}
				onClick={ToggleFavouriteTask}
				title={(isFavourite ? "Remove task from " : "Add task to ") + "favourites"}
			>
				<span className="sr-only">{(isFavourite ? "Remove task from " : "Add task to ") + "favourites"}</span>
			</button>

			<div className="task-title-wrapper">
				<div className="task-detail task-name">
					<h2>{props.task.name}</h2>
					<TaskExternalLink task={props.task} inverseColor={true} />
					{/* <a href={props.task.linkUrl}>Go to Task</a> */}
				</div>
			</div>

			<div className="task-details-wrapper ">
				<div className="project-name task-detail">
					<span className="icon" title="Project Name" />
					{projectName}
				</div>

				<div
					className="customer-name task-detail md-show-hide"
					aria-hidden={
						searchContext.state.taskCardSize === TaskCardSizes.medium ||
						searchContext.state.taskCardSize === TaskCardSizes.large ||
						showExpanded
							? "false"
							: "true"
					}
				>
					<span className="icon" title="Customer Name" />
					{customerName}
				</div>

				<div
					className="task-due-date task-detail lg-show-hide"
					aria-hidden={searchContext.state.taskCardSize === TaskCardSizes.large || showExpanded ? "false" : "true"}
				>
					<span className="icon" title="Task Due Date" />
					{props.task.dueWhen ? props.task.dueWhen.toString() : "Not set"}
				</div>
				<div
					className="project-metrics task-detail lg-show-hide"
					aria-hidden={searchContext.state.taskCardSize === TaskCardSizes.large || showExpanded ? "false" : "true"}
				>
					<div className="synced-time">
						<span className="icon" title="Task Exported Time" />
						<span className="time">
							TODO
							<span className="time-unit-indicator">h</span>
						</span>
					</div>
					<div className="running-time ">
						<span className="icon" title="Task Running TIme" />
						<span className="time">
							{taskRunningTimeHours}
							<span className="time-unit-indicator">h</span>
						</span>
					</div>
					<div className="health health-positive">
						<span className="icon" title="Task Health" />
						<span className="time-remaining">
							TODO
							<span className="time-unit-indicator">h</span>
						</span>
					</div>
				</div>

				<button
					className={"task-info icon-only sz-1 " + (showExpanded ? " expanded" : "")}
					title={(showExpanded ? "Hide " : "View more ") + "task information"}
					onClick={ToggleExpandTaskInfo}
				>
					<span className="sr-only">{(showExpanded ? "Hide " : "View more ") + "task information"}</span>
				</button>
			</div>

			<div className="task-description-wrapper" aria-hidden={showExpanded ? "false" : "true"}>
				<div className="task-description-title">
					<span className="icon" title="Task Description" />
					Description
				</div>
				<div className="task-description-content">{props.task.description}</div>
			</div>
		</section>
	);
};
