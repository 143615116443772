import React, { useState, useContext, useRef, useEffect } from "react";
import "./DashboardComments.scss";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import nameof from "ts-nameof.macro";
import { Dashboard } from "../../../Containers/Dashboard/Dashboard";
import { useForm } from "../../../Hooks/Forms/useForm";
import { Field, FieldTypes } from "../../Library/Field";
import { CommentsSummary } from "../../Library/CommentsSummary/CommentsSummary";
import { TheHook } from "../../../Hooks/TheHook/TheHook";
import { useField } from "../../../Hooks/Forms/useField";
import { GlobalSettingsContext } from "../../../Context/GlobalSettingsContext/GlobalSettingsContext";
import { ITimeEntry } from "../../../Data/Models/ITimeEntry";
import { TimelineContext } from "../../../Context/TimelineContext/TimelineContext";
import { useTimerCalulatedData } from "../../../Hooks/useTimerCalulatedData";
// import { useWindowSize } from "react-use";

export function DashboardComments() {
	const globalSettings = useContext(GlobalSettingsContext);
	const timelineContext = useContext(TimelineContext);
	const TimeEntries = useTimeEntries(nameof(Dashboard));
	const theHook = TheHook(nameof(Dashboard));
	const [newComment, setNewComment] = useState<string>("");
	const [commentsNumber, setCommentsNumber] = useState<number>(0);
	const [commentsExpanded, setCommentsExpanded] = useState<boolean>(true); //TO DO - Logic to set expand/collapse for comments section

	const [scrollOverlayVisible, setScrollOverlayVisible] = useState<boolean>(false);
	const bottomOfCommentsRef = useRef<HTMLDivElement>(null);

	// useEffect(() => {
	// 	if(commentsRef && commentsRef.current){
	// 		setScrollOverlayVisible(commentsRef.current.scrollHeight > commentsRef.current.offsetHeight);
	// 		console.log('scrollHeight:', commentsRef.current.scrollHeight)
	// 		console.log('offsetHeight:', commentsRef.current.offsetHeight)
	// 	}
	// });

	function ToggleExpandComments() {
		setCommentsExpanded(!commentsExpanded);
	}

	// useEffect(()=>{
	// 	ScrollToBottom()
	// },[])

	useEffect(() => {
		ScrollToBottom();
	}, [bottomOfCommentsRef, bottomOfCommentsRef.current]);

	const { currentTimeEntryGUID, currentTimeEntrySetGUID } = useTimerCalulatedData(nameof(DashboardComments));

	useEffect(() => {
		const commentNum = GetCommentsNum();
		setCommentsNumber(commentNum);
		if (commentNum === 0) {
			setScrollOverlayVisible(false);
		}
	}, [currentTimeEntrySetGUID, newComment, timelineContext.state.editingComment]);

	function ScrollToBottom() {
		if (bottomOfCommentsRef && bottomOfCommentsRef.current) {
			bottomOfCommentsRef.current.scrollIntoView();
		}
	}

	// //#region Comment Panel Auto-Scrolling
	// const commentsPanelRef = useRef<HTMLDivElement>(null);
	// const { height } = useWindowSize();
	// const currentGroupTimeEntries = currentGroupGuid && TimeEntries.GetForGroup(currentGroupGuid);
	// useEffect(() => {
	// 	if (commentsPanelRef.current)
	// 		commentsPanelRef.current.scrollTop =
	// 			commentsPanelRef.current.scrollHeight - commentsPanelRef.current.clientHeight;
	// }, [currentGroupTimeEntries, height]);
	// //#endregion

	function scrollAreaEnd(e: React.UIEvent<HTMLDivElement>) {
		const node = e.currentTarget;
		if (node) {
			setScrollOverlayVisible(node.scrollHeight - Math.ceil(node.scrollTop) !== node.clientHeight);
		}
	}

	function GetCommentsNum() {
		return TimeEntries.GetTodaysEntries()
			.filter((te) => te.timeEntrySetGuid === currentTimeEntrySetGUID && te.comment)
			.count();
	}

	const commentForm = useForm({
		onSubmit: (formData, valid) => {
			if (!valid) {
				console.debug("that comment wasnt valid");
				return;
			}

			// AddComment(formData.comment)
			AddComment(newComment);
			setNewComment("");
		},
	});

	function AddComment(comment: string) {
		if (currentTimeEntryGUID && comment.length !== 0) {
			const currentTimeEntry = TimeEntries.Get(currentTimeEntryGUID);

			if (currentTimeEntry) {
				if (globalSettings.state.compactComments && !currentTimeEntry.comment) {
					const todaysEntriesOrdered = TimeEntries.GetTodaysEntries()
						.sort((a, b) => a.startedWhen.diff(b.startedWhen).milliseconds)
						.toArray();

					let tmpEntry: ITimeEntry | undefined = currentTimeEntry;

					// TODO: Replace with immutable.js-friendly implementation
					while (
						tmpEntry &&
						todaysEntriesOrdered[0] &&
						!tmpEntry.comment &&
						todaysEntriesOrdered[0].timeEntrySetGuid === currentTimeEntry.timeEntrySetGuid
					) {
						tmpEntry = todaysEntriesOrdered.shift();
					}

					if (tmpEntry) {
						theHook.AddComment(tmpEntry, comment);
					}
				} else {
					theHook.AddComment(currentTimeEntry, comment);
				}
			} else {
				console.debug("The current time entry was not found");
			}
		} else {
			console.debug("Cannot add comment without a current time entry");
		}
	}

	const newCommentField = useField("comment", commentForm, {
		defaultValue: "",
		validations: [
			// () => {
			//     return newComment.length === 0 && "";
			// }
			// // Doesn't work for whatever reason
		],
		fieldsToValidateOnChange: [],
	});

	return (
		<>
			<section className={"group-comments-wrapper " + (commentsExpanded ? "expanded" : "collapsed")}>
				<div className="group-comments-container">
					<div className="group-comments-header-wrapper">
						<div className="icon" />
						<h3>{commentsNumber === 0 ? "Add a comment" : "Comments"}</h3>

						{commentsNumber > 0 ? (
							<>
								<button
									className="comment-toggle"
									onClick={ToggleExpandComments}
									title={commentsExpanded ? "Hide comments" : "Show comments"}
								>
									<span className="sr-only">{commentsExpanded ? "Hide" : "Show"} comments</span>
								</button>
							</>
						) : (
							<></>
						)}
					</div>

					{/* TODO: Change to show comments pannel without current time entry guid, but it shows an empty panel or something */}
					{currentTimeEntryGUID && commentsNumber > 0 ? (
						<>
							<div className="group-comments-content-wrapper" onScroll={scrollAreaEnd}>
								<CommentsSummary TimeEntrySetGuid={currentTimeEntrySetGUID} />
								<div className="bottom-of-comments" ref={bottomOfCommentsRef}></div>
							</div>
						</>
					) : (
						<></>
					)}

					{currentTimeEntryGUID ? (
						<>
							<div className="add-comment-wrapper">
								<div className={"overflow-overlay " + "visible-" + scrollOverlayVisible} />
								<form className="add-comment-form" onSubmit={commentForm.onSubmit}>
									<fieldset>
										<Field
											{...newCommentField}
											fieldType={FieldTypes.textarea}
											value={newComment}
											onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
												setNewComment(event.target.value)
											}
											formSubmitted={commentForm.submitted}
											type="textarea"
											disabled={!currentTimeEntryGUID}
										/>
										{/* <input
											className="add-comment-button button primary pill sz-2"
											type="submit"
											value="Add Comment"
											disabled={editModeContext.state.editModeStatus || !currentTimeEntryGUID}
										/> */}
										<button
											className="add-comment-button pill button sz-2"
											type="submit"
											title="Add comment"
											disabled={!currentTimeEntryGUID}
										>
											<span className="icon" /> <span className="label">Add comment</span>
										</button>
									</fieldset>
								</form>
							</div>
						</>
					) : (
						<></>
					)}
				</div>
			</section>
		</>
	);
}
