import React from "react";
import { TabOptionProps } from "./TabOption.Interface";
import "./TabOption.scss";

export const TabOption = (props: TabOptionProps) => {
	return (
		<button
			className={"tab-option " + props.icon + (props.active ? " active" : "")}
			onClick={props.onClick}
			aria-label={props.label}
		></button>
	);
};
