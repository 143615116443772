import * as signalR from "@aspnet/signalr";
import nameof from "ts-nameof.macro";
import { ITimeHub } from "./Models/ITimeHub";
import { ITimeEntry } from "./Models/ITimeEntry";
import { ITimeEntryUpdateRequestModel } from "./Models/ITimeEntryUpdateRequestModel";
import { ITimeEntrySet } from "./Models/ITimeEntrySet";
import { ITimeEntrySetUpdateRequestModel } from "./Models/ITimeEntrySetUpdateRequestModel";
import { ITaskMetadata } from "./Models/ITaskMetadata";
import { ITaskMetadataUpdateRequest } from "./Models/ITaskMetadataUpdateRequest";
import { ITimeEntrySetResponseModel } from "./Models/ITimeEntrySetResponseModel";
import { TimeHubClientMethods } from "./TimeHubClientMethods";
import { InstanceManager } from "./InstanceManager";
import { IUpdateTimeDataRequestModel } from "./Models/IUpdateTimeDataRequestModel";
import { Instant } from "./Instant";

const ITimeHubType = {} as ITimeHub;

export class TimeHubConnection implements ITimeHub {
	private connection?: signalR.HubConnection;
	private connecting?: Promise<void>;
	private failedConnectionAttempts = 0;

	constructor(private timeHubClientMethods: TimeHubClientMethods) {}

	private createConnection() {
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl("/hub", { accessTokenFactory: () => InstanceManager.auth.Token as string })
			.build();
		this.connection.on(nameof(this.timeHubClientMethods.TriggerSync), () => this.timeHubClientMethods.TriggerSync());
		this.connection.onclose(() => this.Connect());
	}

	public async Connect() {
		if (this.connecting) return this.connecting;
		try {
			if (!this.connection) this.createConnection();
			if (this.connection!.state === signalR.HubConnectionState.Connected) return;
			this.connecting = this.connection!.start();
			this.connecting.catch((err) => {
				throw err;
			});
			await this.connecting;
			this.failedConnectionAttempts = 0;
		} finally {
			this.connecting = undefined;
			if (!this.connection || this.connection.state !== signalR.HubConnectionState.Connected) {
				++this.failedConnectionAttempts;
				const retryInMs = Math.min(100000, Math.pow(10, this.failedConnectionAttempts + 2));
				console.log("Failed to connect, retrying in " + retryInMs / 1000 + "s");
				setTimeout(() => this.Connect(), retryInMs);
			}
		}
	}

	public async GetServerTimeAsync(): Promise<Instant> {
		await this.Connect();
		if (!this.connection) throw new Error("Connection undefined somehow");
		return this.connection.invoke(nameof(ITimeHubType.GetServerTimeAsync));
	}

	public async TriggerSyncOnOtherClients(): Promise<void> {
		await this.Connect();
		if (!this.connection) throw new Error("Connection undefined somehow");
		await this.connection.invoke(nameof(ITimeHubType.TriggerSyncOnOtherClients));
	}

	public async UpsertTimeEntryAsync(request: ITimeEntryUpdateRequestModel): Promise<void> {
		await this.Connect();
		if (!this.connection) throw new Error("Connection undefined somehow");
		await this.connection.invoke(nameof(ITimeHubType.UpsertTimeEntryAsync), request);
	}
	public async ListTimeEntrySetsAsync(from: Instant, clientId?: string): Promise<ITimeEntrySet[]> {
		await this.Connect();
		if (!this.connection) throw new Error("Connection undefined somehow");
		return this.connection.invoke(nameof(ITimeHubType.ListTimeEntrySetsAsync), from.toJSON(), clientId);
	}
	public async ListTimeEntrySetsAsyncV2(from: Instant): Promise<ITimeEntrySetResponseModel> {
		await this.Connect();
		if (!this.connection) throw new Error("Connection undefined somehow");
		return this.connection.invoke(nameof(ITimeHubType.ListTimeEntrySetsAsyncV2), from.toJSON());
	}
	public async UpsertTimeEntrySetAsync(request: ITimeEntrySetUpdateRequestModel): Promise<void> {
		await this.Connect();
		if (!this.connection) throw new Error("Connection undefined somehow");
		await this.connection.invoke(nameof(ITimeHubType.UpsertTimeEntrySetAsync), request);
	}
	public async ListTaskMetadataAsync(from: Instant, clientId?: string): Promise<ITaskMetadata[]> {
		await this.Connect();
		if (!this.connection) throw new Error("Connection undefined somehow");
		return this.connection.invoke(nameof(ITimeHubType.ListTaskMetadataAsync), from.toJSON(), clientId);
	}
	public async UpsertMetadataAsync(request: ITaskMetadataUpdateRequest): Promise<void> {
		await this.Connect();
		if (!this.connection) throw new Error("Connection undefined somehow");
		await this.connection.invoke(nameof(ITimeHubType.UpsertMetadataAsync), request);
	}

	public start() {
		this.Connect().catch((err) => {
			throw err;
		});
	}

	public stop() {
		if (this.connection)
			this.connection.stop().catch((err) => {
				throw err;
			});
	}

	public async ListTimeEntriesAsync(from: Instant, clientId?: string): Promise<ITimeEntry[]> {
		await this.Connect();
		if (!this.connection) throw new Error("Connection undefined somehow");
		return this.connection.invoke(nameof(ITimeHubType.ListTimeEntriesAsync), from, clientId);
	}

	public async UpdateTimeDataAsync(request: IUpdateTimeDataRequestModel): Promise<void> {
		await this.Connect();
		if (!this.connection) throw new Error("Connection undefined somehow");
		await this.connection.invoke(nameof(ITimeHubType.UpdateTimeDataAsync), request);
	}
}
