import { TimeApiConnection } from "./TimeApiConnection";
import { Set, Range } from "immutable";
import { Instant } from "./Instant";
import { DateTime } from "luxon";

const SEND_RECV_RATIO = 5;
const MAX_OFFSET_MS = 1 * 60 * 60 * 1000; // 1 hour
const MIN_OFFSET_MS = 10;

export class TimeSource {
	private currentOffsetMs = 0;
	private testingTime: Instant | null = null;
	private testingOffsetMs: number | null = null;

	constructor(private readonly timeApi: TimeApiConnection) {
		this.LoadOffset();
	}

	private async GetTimeOffset() {
		const timeBefore = Instant.utc();
		const serverTime = await this.timeApi.GetServerTime();
		const timeAfter = Instant.utc();
		const requestDuration = timeAfter.diff(timeBefore);
		const offsetDuration = timeAfter
			.minus(requestDuration.milliseconds / SEND_RECV_RATIO)
			.diff(serverTime, "milliseconds");

		return offsetDuration < requestDuration ? 0 : offsetDuration.milliseconds;
	}

	public get CurrentOffsetMs() {
		return this.currentOffsetMs;
	}

	public GetUtcTime() {
		return this.testingTime !== null
			? this.testingTime.setZone("utc")
			: this.testingOffsetMs !== null
			? Instant.utc().minus(this.testingOffsetMs)
			: Instant.utc().minus(this.currentOffsetMs);
	}

	public GetLocalTime() {
		return this.GetUtcTime().setZone("local");
	}

	private async SerialPromiseSet<T>(count: number, callback: () => Promise<T>) {
		let retval = Set();
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const _i of Range(0, count)) {
			retval = retval.add(await callback());
		}
		return retval;
	}

	public async UpdateTimeOffset() {
		const iterations = 3;
		const offsets = await this.SerialPromiseSet(iterations, () => this.GetTimeOffset());
		let offsetMsAvg = offsets.reduce((acc, offsetMs) => acc + offsetMs, 0) / iterations;
		const offsetChange = this.currentOffsetMs - offsetMsAvg;

		if (offsetMsAvg > MAX_OFFSET_MS) throw new Error("Clock is too different from server's clock");
		else if (offsetMsAvg < MIN_OFFSET_MS) offsetMsAvg = 0;

		this.currentOffsetMs = offsetMsAvg;
		this.StoreOffset();

		if (offsetChange > 0) {
			this.AnnounceOffsetUpdate();
		}
	}

	private AnnounceOffsetUpdate() {
		document.dispatchEvent(new Event("offsetUpdate"));
		console.debug("Offset update announced", this.currentOffsetMs);
	}
	private StoreOffset() {
		localStorage.setItem("offset", this.currentOffsetMs.toString());
	}

	private LoadOffset() {
		this.currentOffsetMs = parseFloat(localStorage.getItem("offset") || "0");
	}

	public CrossMidnight() {
		this.testingTime = null;
		this.testingOffsetMs =
			DateTime.local()
				.endOf("day")
				.minus({ seconds: 1 })
				.diffNow("milliseconds").milliseconds * -1;
	}
}
