export enum SearchContextDispatchActionType {
	search,
	showSearching,
	setTaskCardSize,
	setSearchFilter,
	receiveResults,
	setActiveTaskKey,
	setSearchTerm,
	receiveNewResults,
	showFetchingNew,
}
