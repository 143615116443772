import { TaskSubscribable } from "./TaskSubscribable";
import { TimeEntrySubscribable } from "./TimeEntrySubscribable";
import { GroupSubscribable } from "./GroupSubscribable";
import { TagSubscribable } from "./TagSubscribable";
import { ITaskTagLink } from "./Models/ITaskTagLink";
import { SubscribableCollection } from "./Subscribable";
import { Guid } from "./Guid";
import { ITaskMetadata } from "./Models/ITaskMetadata";
import { IIntegrationSetting } from "./Models/IIntegrationSetting";

// tslint:disable: no-object-literal-type-assertion
export class DataLayer {
	public Tasks = new TaskSubscribable([]);
	public Tags = new TagSubscribable([]);
	// TODO: Add better support for link types - instead of having a {keyA, keyB} type stored at [keyA, keyB], make the key the value
	public TaskTagLinks = new SubscribableCollection<ITaskTagLink>([]);
	public TimeEntries = new TimeEntrySubscribable([]);

	public Groups = new GroupSubscribable([]);

	public TaskMetadatas = new SubscribableCollection<ITaskMetadata>([]);
	public IntegrationSettings = new SubscribableCollection<IIntegrationSetting>([]);

	constructor() {}
}

export const TheClaw = new DataLayer();
