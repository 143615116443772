import React from "react";
import { TasklistModalProps } from "./TasklistModal.Interface";
import "./TasklistModal.scss";
import { Tasklist } from "../../../Containers/Tasklist/Tasklist";

export const TasklistModal = (props: TasklistModalProps) => {
	return (
		<section id="search-wrapper">
			<Tasklist
				modalVersion={true}
				timeEntrySetGuid={props.timeEntrySetGuid}
				linkingTimeEntries={props.linkingTimeEntries}
			/>
		</section>
	);
};
