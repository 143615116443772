import React, { useState, useEffect } from "react";
import { HaloFragmentProps } from "./HaloFragment.Interface";
import "./HaloFragment.scss";
import { useTime } from "../../../../Hooks/useTime";
import { TheClaw } from "../../../../Data/TheClaw";
import { InstanceManager } from "../../../../Data/InstanceManager";

enum stroke {
	linked = "#09D1E0",
	unlinked = "#f89a40",
	grey = "#dfdfdf",
}

export const HaloFragment = (props: HaloFragmentProps) => {
	const [isLinked, setIsLinked] = useState<boolean>(false);

	const strokeWidth = 7; // Not sure what will set this, or how

	const timeEntrySet = TheClaw.Groups.Get(props.timeEntry.timeEntrySetGuid);
	useEffect(() => {
		if (timeEntrySet) {
			setIsLinked(!!timeEntrySet.taskExternalId);
		}
	}, [
		props.timeEntry.timeEntrySetGuid,
		timeEntrySet && timeEntrySet.taskIntegrationGuid && timeEntrySet.taskExternalId,
	]);

	useTime();

	// Needs a new formula that uses end and start times and the difference between this fragments time to position it.

	// Need to remove strokeWidth from both ends of the fragment, that way they will display true time.
	// Minimum 1 thickness
	// Minimum of length for position.

	// If the time entry is the first or last, then don't subtract stroke width

	let dashOffset = 0;
	let dashArray = "";

	if (props.haloLineRef.current) {
		// add strokeWidth to offset the thickness of the halo fragments so they reach the end of the halo
		const lengthOfHalo = props.haloLineRef.current.getTotalLength() + strokeWidth;
		dashOffset = lengthOfHalo * 2;

		const lengthOfTimeEntry = (props.timeEntry.endedWhen
			? props.timeEntry.endedWhen
			: InstanceManager.timeSource.GetLocalTime()
		).diff(props.timeEntry.startedWhen, "minutes").minutes;

		const minutesInCurrentDay = props.MinutesInCurrentDay();
		const percentageOfHalo = lengthOfTimeEntry / minutesInCurrentDay;
		const lengthOfFragment = lengthOfHalo * percentageOfHalo;

		const minutesToEndOfDay = props.endTime
			? props.endTime.diff(
					props.timeEntry.endedWhen ? props.timeEntry.endedWhen : InstanceManager.timeSource.GetLocalTime(),
					"minutes"
			  ).minutes
			: 0;

		const fragmentPosition = lengthOfHalo * (minutesToEndOfDay / minutesInCurrentDay) + lengthOfHalo;

		const adjustedLength = lengthOfFragment - strokeWidth;

		dashArray =
			lengthOfHalo.toString() +
			" " +
			fragmentPosition.toString() +
			" " +
			(adjustedLength > 0 ? adjustedLength : 1).toString();
	}

	return (
		<path
			id={"halo-fragment group-" + props.timeEntry.timeEntrySetGuid}
			d={props.path}
			stroke={isLinked ? stroke.linked : stroke.unlinked}
			strokeLinecap="round"
			strokeWidth={strokeWidth + "px"}
			fill="transparent"
			strokeDasharray={dashArray}
			strokeDashoffset={dashOffset}
		/>
	);
};
