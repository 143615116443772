import React, { useContext, useRef } from "react";
import { HaloProps } from "./Halo.Interface";
import "./Halo.scss";
import nameof from "ts-nameof.macro";
import { HaloFragment } from "./HaloFragment/HaloFragment";
import { DateTime } from "luxon";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import { useTime } from "../../../Hooks/useTime";
import { HaloContext, GetHaloStartTime, GetHaloEndTime } from "../../../Context/HaloContext/HaloContext";

export enum HaloShape {
	halo = "M138.3,5.5C63.7,11.5,5,73.8,5,149.9C5,230,69.9,294.8,150,294.8s145-65,145-145c0-76.2-58.7-138.6-133.3-144.5",
	line = "M10 695 V 10 V",
}

export const Halo = (props: HaloProps) => {
	const haloContext = useContext(HaloContext);
	const TimeEntries = useTimeEntries(nameof(Halo));

	const currentTime = useTime();

	const haloRef = useRef<SVGPathElement>(null);

	function MinutesInCurrentDay() {
		return GetHaloEndTime(haloContext.state.customEndTime, haloContext.state.endTime, currentTime).diff(
			GetHaloStartTime(haloContext.state.customStartTime, haloContext.state.startTime)
		).minutes;
	}

	return (
		<>
			{/* <div className="halo-time-label-wrapper">
				{customEndTime && !CurrentTimeAfterCustomEndTime() ? (
					<HaloTimeLabel className="custom-end-time" timeToDisplay={customEndTime} SetCustomTime={SetCustomEndTime} />
				) : (
					<HaloTimeLabel className="current-time" timeToDisplay={currentTime} SetCustomTime={SetCustomEndTime} />
				)}
				<HaloTimeLabel
					className="start-time"
					timeToDisplay={UseCustomOrActualStartTime()}
					SetCustomTime={SetCustomStartTime}
				/>
			</div> */}
			<div className={"halo-container" + (props.haloShape === HaloShape.line ? " line" : "")}>
				<svg
					id="halo"
					viewBox={props.haloShape === HaloShape.line ? "0 0 32 700" : "0 0 300 300"}
					width="100%"
					height="100%"
				>
					<filter id="softGlow" height="300%" width="300%" x="-75%" y="-75%">
						{/* <!-- Thicken out the original shape --> */}
						<feMorphology operator="dilate" radius="1" in="SourceAlpha" result="thicken" />

						{/* <!-- Use a gaussian blur to create the soft blurriness of the glow --> */}
						<feGaussianBlur in="thicken" stdDeviation="3" result="blurred" />

						{/* <!-- Change the colour --> */}
						<feFlood floodColor="rgba(255,255,255,0.4)" result="glowColor" />

						{/* <!-- Color in the glows --> */}
						<feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored" />

						{/* <!--	Layer the effects together --> */}
						<feMerge>
							<feMergeNode in="softGlow_colored" />
							<feMergeNode in="SourceGraphic" />
						</feMerge>
					</filter>

					<path
						ref={haloRef}
						id="halo-line"
						d={props.haloShape}
						stroke={props.haloShape === HaloShape.line ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.6)"}
						strokeLinecap="round"
						strokeWidth="1px"
						fill="transparent"
						filter="url(#softGlow)"
					/>
					<g id="halo-fragments">
						{TimeEntries.GetTodaysEntries().map((timeEntry) => {
							if (!props.timeEntrySetGuid || props.timeEntrySetGuid === timeEntry.timeEntrySetGuid) {
								return (
									<HaloFragment
										key={"halo-fragment-" + timeEntry.timeEntryGuid}
										haloLineRef={haloRef}
										path={props.haloShape}
										timeEntry={timeEntry}
										MinutesInCurrentDay={MinutesInCurrentDay}
										endTime={GetHaloEndTime(haloContext.state.customEndTime, haloContext.state.endTime, currentTime)}
										startTime={GetHaloStartTime(haloContext.state.customStartTime, haloContext.state.startTime)}
									/>
								);
							}
						})}
					</g>
				</svg>
			</div>
		</>
	);
};
