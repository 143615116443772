import { useState, useEffect } from "react";
import { InstanceManager } from "../Data/InstanceManager";
import { DateTime } from "luxon";

const callbacks: Set<(time: DateTime) => void> = new Set();

export class Ticker {
	public static TickEnabled = false;

	public static PauseTick() {
		Ticker.TickEnabled = false;
	}
	public static ResumeTick() {
		Ticker.TickEnabled = true;
	}
	public static SetTick(tick: boolean) {
		Ticker.TickEnabled = tick;
	}

	private static readonly SECOND_START_OFFSET = 100;
	public static Tick() {
		const initTime = InstanceManager.timeSource.GetLocalTime();
		const msToNexSec = initTime
			.plus({ milliseconds: Ticker.SECOND_START_OFFSET })
			.endOf("second")
			.diff(initTime).milliseconds;
		setTimeout(() => {
			if (Ticker.TickEnabled) {
				const time = InstanceManager.timeSource.GetLocalTime();
				callbacks.forEach((callback) => callback(time));
			}
			Ticker.Tick();
		}, Math.max(msToNexSec, 200));
	}
}

Ticker.Tick();

export function useTime() {
	const [time, setTime] = useState(() => InstanceManager.timeSource.GetLocalTime());

	useEffect(() => {
		callbacks.add(setTime);
		return () => {
			callbacks.delete(setTime);
		};
	}, []);

	return time;
}
