import React, { useContext, useState, useEffect } from "react";
import "./ForgottenTimeEntryView.scss";
import { TheHook } from "../../../Hooks/TheHook/TheHook";
import nameof from "ts-nameof.macro";
import { DateTime } from "luxon";
import { ITimeEntry } from "../../../Data/Models/ITimeEntry";
import { ForgottenTimeEntryContext } from "../../../Context/ForgottenTimeEntryContext/ForgottenTimeEntryContext";
import { TimelineContext } from "../../../Context/TimelineContext/TimelineContext";
import { Field } from "../../Library/Field";
import { useField } from "../../../Hooks/Forms/useField";
import { useForm } from "../../../Hooks/Forms/useForm";
import { ViewContext } from "../../../Context/ViewContext/ViewContext";
import { ViewContextDispatchActionType } from "../../../Context/ViewContext/ViewContextDispatchActionType";
import { KeyHelper } from "../../../Data/KeyHelper";
import { useGroups } from "../../../Hooks/useGroups";
import { InstanceManager } from "../../../Data/InstanceManager";
import isNumber from "is-number";

enum AMPM {
	AM = "AM",
	PM = "PM",
}

// NOTE: This only ever checks for one forgotten time entry, since it shouldn't be possible to have 2 that are currently running.

export const ForgottenTimeEntryView = () => {
	const whenDidyouFinish = useContext(ForgottenTimeEntryContext);
	const timelineContext = useContext(TimelineContext);
	const viewContext = useContext(ViewContext);
	const Groups = useGroups(nameof(ForgottenTimeEntryView));

	const theHook = TheHook(nameof(ForgottenTimeEntryView));
	const [isAmPm, setIsAmPm] = useState(AMPM.PM);
	const [nextDay, setNextDay] = useState(false);
	const [saving, setSaving] = useState(false);
	const [errormsg, setErrormsg] = useState<string | undefined>(undefined);

	useEffect(() => {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.HideTabs,
		});
	}, []);

	// Split time entry at the offset point.
	function SaveTime(hour: string, minute: string) {
		if (!whenDidyouFinish.state.modalTimeEntry) {
			return;
		}

		setSaving(true);
		setErrormsg(undefined);
		try {
			const endTimeDate = whenDidyouFinish.state.modalTimeEntry.startedWhen.plus({
				day: nextDay ? 1 : 0,
			});
			//.toLocaleString(DateTime.DATETIME_MED);

			if (!isNumber(hour)) {
				setErrormsg("Hour needs to be a number");
				return;
			}

			if (!isNumber(minute)) {
				setErrormsg("Minute needs to be a number");
				return;
			}

			const hourNum = parseInt(hour);
			const minNum = parseInt(minute);

			if (hourNum !== parseFloat(hour)) {
				setErrormsg("Hour needs to be a whole number");
				return;
			}

			if (minNum !== parseFloat(minute)) {
				setErrormsg("Minute needs to be a whole number");
				return;
			}

			let hourNum24 = hourNum + (isAmPm === AMPM.PM ? 12 : 0);

			while (hourNum24 >= 24) hourNum24 -= 24;

			const newEndTime = endTimeDate.set({ hour: hourNum24, minute: minNum });

			// Make sure to account for adjustable day end time.
			// If the entry is split then it needs to be accounter for the endof orignal entry, and start of new entry

			if (!newEndTime) {
				setErrormsg("New end time wasn't set properly.");
				return;
			}
			if (newEndTime > InstanceManager.timeSource.GetLocalTime()) {
				setErrormsg("Cannot set end time to after current time.");
				return;
			}
			if (newEndTime < whenDidyouFinish.state.modalTimeEntry.startedWhen) {
				setErrormsg("Cannot set end time to before start time.");
				return;
			}

			if (nextDay) {
				// No double check for at least a day earlier hear, since the after current time check should have caught it.

				const endOfOriginalDay = whenDidyouFinish.state.modalTimeEntry.startedWhen
					.toLocal()
					.endOf("day")
					.plus({ hours: timelineContext.state.timelineStartTimeOffsetHours });

				// Split time entry at midnight.
				const originalTimeEntry: ITimeEntry = {
					...whenDidyouFinish.state.modalTimeEntry,
					endedWhen: endOfOriginalDay.toLocal(),
				};
				theHook.SetTimeEntry(originalTimeEntry);

				const group = Groups.Get(whenDidyouFinish.state.modalTimeEntry.timeEntrySetGuid);

				// Create a new time entry in a new group. Currently it must be a new group sincxe we do not want groups being in multiple days.
				theHook.NewTimeEntry(
					endOfOriginalDay.plus({ second: 1 }),
					newEndTime,
					undefined,
					group ? KeyHelper.GetTimeEntrySetTaskKey(group) : undefined
				);
			} else {
				// Double check the time entry started at least a day earlier before splitting it.
				if (
					whenDidyouFinish.state.modalTimeEntry.startedWhen <
					InstanceManager.timeSource
						.GetLocalTime()
						.startOf("day")
						.plus({ hours: timelineContext.state.timelineStartTimeOffsetHours })
				) {
					const originalTimeEntry: ITimeEntry = {
						...whenDidyouFinish.state.modalTimeEntry,
						endedWhen: newEndTime.toLocal(),
					};

					theHook.SetTimeEntry(originalTimeEntry);
				} else {
					console.debug("Time Entry from current day was send to 'when did you finish' modal");
				}
			}
			CloseView();
		} finally {
			setSaving(false);
		}
	}

	function StillWorking() {
		// If still working, split the time entry at midnight, and leave the end time undefined.
		if (whenDidyouFinish.state.modalTimeEntry) {
			// Double check the time entry started at least a day earlier before splitting it.
			if (
				whenDidyouFinish.state.modalTimeEntry.startedWhen < InstanceManager.timeSource.GetLocalTime().startOf("day")
			) {
				const endOfOriginalDay = whenDidyouFinish.state.modalTimeEntry.startedWhen
					.toLocal()
					.endOf("day")
					.plus({ hours: timelineContext.state.timelineStartTimeOffsetHours });

				const originalTimeEntry: ITimeEntry = {
					...whenDidyouFinish.state.modalTimeEntry,
					endedWhen: endOfOriginalDay.toLocal(),
				};

				theHook.SetTimeEntry(originalTimeEntry);

				if (whenDidyouFinish.state.modalTimeEntry) {
					const group = Groups.Get(whenDidyouFinish.state.modalTimeEntry.timeEntrySetGuid);

					// Create a new time entry in a new group. Currently it must be a new group since we do not want groups being in multiple days.
					theHook.NewTimeEntry(
						endOfOriginalDay.plus({ second: 1 }),
						undefined,
						undefined,
						group ? KeyHelper.GetTimeEntrySetTaskKey(group) : undefined
					);
				}
				CloseView();
			} else {
				console.debug("Time Entry from current day was send to 'when did you finish' modal");
				CloseView();
			}
		}
	}

	function CloseView() {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.DashboardView,
		});
	}

	// Checks that the time entry is still feasibly still being worked on.
	function CanStillBeWorking() {
		if (
			whenDidyouFinish.state.modalTimeEntry &&
			InstanceManager.timeSource
				.GetLocalTime()
				.startOf("day")
				.diff(whenDidyouFinish.state.modalTimeEntry.startedWhen.toLocal().startOf("day"), "days").days <= 1
		) {
			return true;
		}

		return false;
	}

	const form = useForm({
		onSubmit: (formData, valid) => {
			if (!valid) return;

			SaveTime(formData.hours, formData.minutes);
		},
	});

	const hoursField = useField("hours", form, {
		defaultValue: "",
		validations: [
			(formData) => {
				return formData.hours.length === 0 && "Missing hour";
			},
			(formData) => formData.hours.length > 0 && !/\d+$/.test(formData.hours) && "Not valid hour",
			(formData) => formData.hours.length > 0 && Number(formData.hours) > 12 && "Not valid hour",
		],
		fieldsToValidateOnChange: [],
	});

	const minutesField = useField("minutes", form, {
		defaultValue: "",
		validations: [
			(formData) => {
				return formData.minutes.length === 0 && "Missing minutes";
			},
			(formData) => formData.minutes.length > 0 && !/\d+$/.test(formData.minutes) && "Not valid minutes",
			(formData) => formData.minutes.length > 0 && Number(formData.minutes) > 59 && "Not valid minutes",
		],
		fieldsToValidateOnChange: [],
	});

	return (
		<section className="when-did-you-finish">
			<section className="header-section">When did you finish?</section>
			<section className="modal-content-wrapper">
				{whenDidyouFinish.state.modalTimeEntry ? (
					<div className="modal-content">
						<div className="description">
							{"We noticed that you didn't stop this time entry and it kept recording, was this intentional?"}
						</div>

						<form className="set-day-time" onSubmit={form.onSubmit}>
							{/* Display started time */}
							<div className="start-time">
								You begun this task at{" "}
								{whenDidyouFinish.state.modalTimeEntry.startedWhen.toLocaleString(DateTime.DATETIME_MED)}
							</div>
							<fieldset>
								Finished at
								<div className="hour-input-wrapper">
									<Field
										{...hoursField}
										formSubmitted={form.submitted}
										label=" "
										type="text"
										className="hour-input"
										maxLength={2}
										disabled={saving}
									/>
								</div>
								:
								<div className="minute-input-wrapper">
									<Field
										{...minutesField}
										formSubmitted={form.submitted}
										label=""
										type="text"
										className="minute-input"
										maxLength={2}
										disabled={saving}
									/>
								</div>
								<div className="am-pm-toggle">
									<button
										className="am-button"
										type="button"
										onClick={() => setIsAmPm(AMPM.AM)}
										disabled={isAmPm === AMPM.AM || saving}
									>
										AM
									</button>
									<button
										className="pm-button"
										type="button"
										onClick={() => setIsAmPm(AMPM.PM)}
										disabled={isAmPm === AMPM.PM || saving}
									>
										PM
									</button>
								</div>
								<div className="date-of-end-time">
									{whenDidyouFinish.state.modalTimeEntry.startedWhen
										.plus({ day: nextDay ? 1 : 0 })
										.toLocaleString(DateTime.DATE_MED)}
								</div>
								<div className="error-message-label">{errormsg}</div>
								<button className="save-time" type="submit" disabled={saving}>
									SAVE
								</button>
							</fieldset>
						</form>

						<button className="swap-to-next-day" onClick={() => setNextDay(!nextDay)}>
							{nextDay ? "Back to original day" : "I finished working the next day"}

							{/* (this sets the day to the next day and prompts them to set an end time) */}
						</button>
						{CanStillBeWorking() ? (
							<button className="still-working" onClick={StillWorking}>
								{"I'm still working on this."}
							</button>
						) : (
							<></>
						)}
					</div>
				) : (
					<div onClick={CloseView}>There is no time entry selected to edit!</div>
				)}
			</section>
		</section>
	);
};
