import React, { useState, useEffect, useRef, RefObject } from "react";
import { ContextMenuProps, menuCoords, menuOption, ContextMenuOrigin } from "./ContextMenu.interface";
import "./ContextMenu.scss";
import { ContextMenuOption } from "./ContextMenuOption/ContextMenuOption";

export function useContextMenuParams(origin: ContextMenuOrigin = ContextMenuOrigin.TopLeft) {
	const [showContextMenu, setShowContextMenu] = useState(false);
	const [menuCoords, setMenuCoords] = useState<menuCoords>({ x: 0, y: 0 });
	const [menuOptions, setMenuOptions] = useState<menuOption[]>([]);

	return {
		contextMenuParams: {
			contextMenuCoords: menuCoords,
			onClose: () => setShowContextMenu(false),
			menuOptions,
			showContextMenu,
			origin,
		} as ContextMenuProps,
		setShowContextMenu,
		setMenuCoords,
		setMenuOptions,
	};
}

export function useOutsideClick(ref: RefObject<HTMLElement>, callback: (event: MouseEvent) => void) {
	useEffect(() => {
		function _handleClick(event: MouseEvent) {
			if (ref && ref.current && event.target) {
				const wasOutside = !ref.current.contains(event.target as HTMLElement);

				if (wasOutside) {
					callback(event);
				}
			}
		}

		document.addEventListener("mousedown", _handleClick);
		return () => {
			document.removeEventListener("mousedown", _handleClick);
		};
	}, []);
}

export const ContextMenu = (props: ContextMenuProps) => {
	const ref = useRef<HTMLDivElement>(null);
	// This places the menu this many pixels away from the mouse, to better simulate a normal context menu
	const positionOffset = 3;

	useOutsideClick(ref, props.onClose);

	function HandleCallback(callback: (e?: any) => void) {
		callback(props.contextMenuCoords ? props.contextMenuCoords.y : undefined);
		props.onClose();
	}

	const yOrigin =
		props.origin === ContextMenuOrigin.TopLeft || props.origin === ContextMenuOrigin.TopRight ? "top" : "bottom";
	const xOrigin =
		props.origin === ContextMenuOrigin.TopLeft || props.origin === ContextMenuOrigin.BottomLeft ? "left" : "right";

	return props.showContextMenu && props.menuOptions ? (
		<div
			ref={ref}
			className="contextMenu"
			style={{
				[yOrigin]: (props.contextMenuCoords ? props.contextMenuCoords.y + positionOffset : 0) + "px",
				[xOrigin]:
					xOrigin === "right" ? "0" : (props.contextMenuCoords ? props.contextMenuCoords.x + positionOffset : 0) + "px",
			}}
		>
			{props.menuOptions.map((menuOption: menuOption) => {
				//Idealy the onClick wouldn't create a new function just to close the menu.
				return <ContextMenuOption key={menuOption.display} HandleCallback={HandleCallback} menuOption={menuOption} />;
			})}
			{/* <div className="contextMenu--separator" /> */}
		</div>
	) : (
		<></>
	);
};
