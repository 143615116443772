import { TheClaw } from "../Data/TheClaw";
import { useEffect, useRef } from "react";
import { Subscriber } from "../Data/Subscriber";
export function useTimeEntryUpdateEvent(source: string, callback: () => void) {
	const timeEntrySubscriber = useRef<Subscriber<string>>();

	useEffect(() => {
		const timeEntrySub: Subscriber<string> = {
			callback: (_action, _key) => callback(),
			source,
		};
		TheClaw.TimeEntries.Subscribe(timeEntrySub);
		timeEntrySubscriber.current = timeEntrySub;

		callback();

		return () => {
			if (timeEntrySubscriber.current) TheClaw.TimeEntries.UnSubscribe(timeEntrySubscriber.current);
		};
	}, [source, callback]);
}
