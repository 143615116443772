import React from "react";
import { ContextMenuOptionProps } from "./ContextMenuOption.interface";

export const ContextMenuOption = (props: ContextMenuOptionProps) => {
	function OnClick() {
		if (props.menuOption.disabled) return;
		props.HandleCallback(props.menuOption.callback);
	}

	return (
		<div
			className={"contextMenu--option " + (props.menuOption.disabled ? " disabled " : "")}
			key={"menu-option-" + props.menuOption.display}
			onClick={OnClick}
		>
			{props.menuOption.display}
		</div>
	);
};
