import React, { useState, FormEvent, useEffect, useContext, useRef } from "react";
import "./LogInView.scss";
import { LoginResultEnum } from "../../../Data/LoginResultEnum";
import { InstanceManager } from "../../../Data/InstanceManager";
import { ViewContext } from "../../../Context/ViewContext/ViewContext";
import { ViewContextDispatchActionType } from "../../../Context/ViewContext/ViewContextDispatchActionType";
import { useLoginState } from "../../../useLoginState";

export function LogInView() {
	const [emailAddress, setEmailAddress] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [failed, setFailed] = useState<boolean>(false);
	const [loggingIn, setLoggingIn] = useState<boolean>(false);
	const viewContext = useContext(ViewContext);
	const [, , check] = useLoginState();

	useEffect(() => {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.HideTabs,
		});

		// Tests for multiple forgotten time entries
		// theHook.NewTimeEntry(
		// 	InstanceManager.timeSource.GetTimeMom()
		// 		.minus({days: 5})
		//
		// );
		// theHook.NewTimeEntry(
		// 	InstanceManager.timeSource.GetTimeMom()
		// 		.minus({days: 6})
		//
		// );
	}, []);

	function handleLogInAttempt(e?: FormEvent) {
		if (e) e.preventDefault();

		setLoggingIn(true);
		InstanceManager.auth.Login(emailAddress, password).then(async (result) => {
			if (result !== LoginResultEnum.Success) {
				console.error("Login failed");
				setFailed(true);
				setLoggingIn(false);
			} else {
				console.info("Logged in successfully", InstanceManager.auth.GetJwtData());
				setFailed(false);
				check();
			}
		});
	}

	return (
		<section className="log-in-screen">
			<form className="log-in-form" onSubmit={handleLogInAttempt}>
				<div className="log-in-title">Log In</div>
				<input
					className="username-text-box"
					type="email"
					value={emailAddress}
					onChange={(event) => setEmailAddress(event.target.value)}
					placeholder="email"
					required
					disabled={loggingIn}
					autoComplete="email"
				/>
				<input
					className="password-text-box"
					type="password"
					value={password}
					onChange={(event) => setPassword(event.target.value)}
					placeholder="password"
					required
					disabled={loggingIn}
					autoComplete="password"
				/>
				<button className="log-in-button button primary pill sz-2" type="submit" disabled={loggingIn}>
					Log In
				</button>
				<div className="failed-log-in-label">{failed ? "Log In Failed" : ""}</div>
			</form>
		</section>
	);
}
