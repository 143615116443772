import React from "react";
import { GroupTaskModalContextModel } from "./GroupTaskModalContextModel";
import { GroupTaskModalContextAction } from "./GroupTaskModalContextAction";
import { GroupTaskModalContextDispatchActionType } from "./GroupTaskModalContextDispatchActionType";
import { GroupTaskModalContextProviderProps } from "./GroupTaskModalContext.Interface";

//This could be merged into timeline context if it isn't being used extensibly by the tasklist, playhead or dashboard

export const GroupTaskModalContext = React.createContext({
	state: new GroupTaskModalContextModel(),
	dispatch: {} as React.Dispatch<GroupTaskModalContextAction>,
});

function ModalReducer(state: GroupTaskModalContextModel, action: GroupTaskModalContextAction) {
	switch (action.type) {
		case GroupTaskModalContextDispatchActionType.SetModalGroup:
			return { ...state, modalGroup: action.payload };
		case GroupTaskModalContextDispatchActionType.SetModalTask:
			return { ...state, modalTask: action.payload };
		case GroupTaskModalContextDispatchActionType.SetModalDayOffset:
			return { ...state, modalDayOffset: action.payload };
		case GroupTaskModalContextDispatchActionType.CloseGroupTaskModal:
			return { ...state, modalDayOffset: 0, modalTask: undefined, modalGroup: undefined };
		default:
			return { ...state };
	}
}

export const GroupTaskModalContextProvider = (props: GroupTaskModalContextProviderProps) => {
	const [state, dispatch] = React.useReducer(ModalReducer, {
		modalGroup: undefined,
		modalTask: undefined,
		modalDayOffset: 0,
	});
	const value = { state, dispatch };

	return <GroupTaskModalContext.Provider value={value}>{props.children}</GroupTaskModalContext.Provider>;
};
