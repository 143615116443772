import { DateTime } from "luxon";

export class HaloContextModel {
	startTime?: DateTime;
	endTime?: DateTime;
	customStartTime?: DateTime;
	customEndTime?: DateTime;
	constructor(mcm?: Partial<HaloContextModel>) {
		Object.assign(this, mcm);
	}
}
