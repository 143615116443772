import { TimeEntrySubscribable } from "../../Data/TimeEntrySubscribable";
import { GroupSubscribable } from "../../Data/GroupSubscribable";

export class EditModeClaw {
	public editModeTimeEntriesSubscribable = new TimeEntrySubscribable([]);
	public editModeGroupSubscribable = new GroupSubscribable([]);
	constructor() {}
}

export const TheEditModeClaw = new EditModeClaw();
