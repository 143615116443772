import { Source } from "../Data/Source";
import { TheClaw } from "../Data/TheClaw";
import { IIntegrationSetting } from "../Data/Models/IIntegrationSetting";
import { IUseSubscribableCollectionType, useSubscribableCollection } from "./useSubscribable";
export interface IUseIntegrationSettings extends IUseSubscribableCollectionType<IIntegrationSetting> {}
/**
 *
 * Causes re-render when any item in Tasks changes
 * @param source
 */

export const DEFAULTROUNDINGINTEVAL = 6 * 60;
export const DEFAULTROUDNINGTHRESHOLD = 2 * 60;

export function useIntegrationSettings(source?: Source) {
	const subscribable = useSubscribableCollection(TheClaw.IntegrationSettings, source);
	return {
		...subscribable,
	};
}
