import { timelineNotesSortModeEnum } from "./TimelineContext";
import { ITimeEntry } from "../../Data/Models/ITimeEntry";
import { DateTime } from "luxon";

export class TimelineContextModel {
	public currentScale = 1;
	public timelineStartTimeOffsetHours = 0;
	public ScrollToBottom = false;
	public timelineSortMode?: timelineNotesSortModeEnum;
	public ScrollToDate?: DateTime;
	public ScrollToTimeEntry?: ITimeEntry;
	public currentDayOffset = 0;
	public editingComment = false;
	public switchDisabled = false;
	public lastSwitch: DateTime | null = null;
	constructor(mcm?: Partial<TimelineContextModel>) {
		Object.assign(this, mcm);
	}
}
