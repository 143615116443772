import { useState, useRef } from "react";
import { InstanceManager } from "./Data/InstanceManager";
import { LoginResultEnum } from "./Data/LoginResultEnum";
import { DateTime } from "luxon";
import { useEffectOnce } from "react-use";
import { Instant } from "./Data/Instant";

export function useLoginState() {
	const [loginStatus, setLoginStatus] = useState(LoginResultEnum.None);
	const timeout = useRef<NodeJS.Timeout>();
	const checkRunning = useRef(false);
	const checkAgain = useRef(false);

	function scheduleNextCheck() {
		const jwtData = InstanceManager.auth.GetJwtData();
		const expiry = jwtData ? DateTime.fromSeconds(jwtData.exp) : Instant.utc().plus({ seconds: 5 });
		if (timeout.current) clearTimeout(timeout.current);
		timeout.current = setTimeout(check, expiry.diffNow("milliseconds").milliseconds);
	}

	async function check() {
		if (checkRunning.current) {
			checkAgain.current = true;
			return;
		}
		checkRunning.current = true;
		const asyncLoggedInStates = InstanceManager.auth.IsUserLoggedIn();
		for await (const val of asyncLoggedInStates) {
			setLoginStatus(val);
		}
		checkRunning.current = false;
		if (checkAgain.current) {
			checkAgain.current = false;
			await check();
		} else scheduleNextCheck();
	}

	useEffectOnce(() => {
		check();

		return () => {
			if (timeout.current) clearTimeout(timeout.current);
		};
	});

	return [loginStatus === LoginResultEnum.Success, loginStatus, () => check()] as [
		boolean,
		LoginResultEnum,
		() => Promise<void>
	];
}
