import React from "react";
import { FilterButtonProps } from "./FilterButton.Interface";

export function FilterButton(props: FilterButtonProps) {
	function HandleClick() {
		props.onClick(props.filterMode);
	}

	return (
		<button
			className={props.className + (props.currentFilterMode === props.filterMode ? " active" : "")}
			disabled={props.disabled}
			onClick={HandleClick}
			title={"List by " + props.text}
		>
			<span className="icon" />
			<span className="label">{props.text}</span>
			<span className="sr-only">{"List by " + props.text}</span>
		</button>
	);
}
