import React, { useContext, useEffect } from "react";
import "./DashboardView.scss";
import { Dashboard } from "../../../Containers/Dashboard/Dashboard";
import { ViewContext } from "../../../Context/ViewContext/ViewContext";
import { ViewContextDispatchActionType } from "../../../Context/ViewContext/ViewContextDispatchActionType";

export function DashboardView() {
	const viewContext = useContext(ViewContext);

	useEffect(() => {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.ShowTabs,
		});
	}, []);

	return <Dashboard />;
}
