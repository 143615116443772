import React from "react";
import { HaloContextModel } from "./HaloContextModel";
import { HaloContextAction } from "./HaloContextAction";
import { HaloContextDispatchActionType } from "./HaloContextDispatchActionType";
import { HaloContextProviderProps } from "./HaloContext.Interface";
import { DateTime } from "luxon";
import { InstanceManager } from "../../Data/InstanceManager";

// Need to remove the menu stuff from this context and put it in a more generic context.
export interface menuCoords {
	x: number;
	y: number;
}

export interface menuOption {
	display: string;
	callback: (e?: any) => void;
	disabled?: boolean;
}

export const HaloContext = React.createContext({
	state: new HaloContextModel(),
	dispatch: {} as React.Dispatch<HaloContextAction>,
});

function HaloContextReducer(state: HaloContextModel, action: HaloContextAction) {
	switch (action.type) {
		case HaloContextDispatchActionType.setStartTime:
			return {
				...state,
				startTime: action.payload,
				endTime: GetHaloStartTime(state.customStartTime, action.payload).plus({ hours: 8 }),
			};
		case HaloContextDispatchActionType.setEndTime:
			return { ...state, endTime: action.payload };
		case HaloContextDispatchActionType.setCustomStartTime:
			return { ...state, customStartTime: action.payload };
		case HaloContextDispatchActionType.setCustomEndTime:
			return { ...state, customEndTime: action.payload };
		default:
			return { ...state };
	}
}

export function GetHaloStartTime(customStartTime: DateTime | undefined, startTime: DateTime | undefined) {
	// // Old functionality only displayed custom start time if it was before first recorded time entry
	// if(customStartTime){
	// 	if ((customStartTime) > (startTime)) {
	// 		return startTime;
	// 	} else {
	// 		return customStartTime;
	// 	}
	// }

	return customStartTime ? customStartTime : startTime ? startTime : InstanceManager.timeSource.GetLocalTime();
}

function CurrentTimeAfterHaloEndTime(
	customEndTime: DateTime | undefined,
	endTime: DateTime | undefined,
	currentTime: DateTime
) {
	if (customEndTime) {
		if (customEndTime > currentTime) {
			return false;
		}
	} else if (endTime) {
		if (endTime > currentTime) {
			return false;
		}
	}

	return true;
}

export function GetHaloEndTime(
	customEndTime: DateTime | undefined,
	endTime: DateTime | undefined,
	currentTime: DateTime
) {
	return CurrentTimeAfterHaloEndTime(customEndTime, endTime, currentTime)
		? currentTime
		: customEndTime
		? customEndTime
		: endTime
		? endTime
		: currentTime;
}

export const HaloContextProvider = (props: HaloContextProviderProps) => {
	const [state, dispatch] = React.useReducer(HaloContextReducer, {
		startTime: undefined, // Start time is calculated currently as the start of the first recoreded time entry for the day
		endTime: undefined, // End time is calculated currently as 8 hours past the first recorded time entry

		customStartTime: undefined, // Custom start and end times are the values input by the user to override the defaults
		customEndTime: undefined,
	});
	const value = { state, dispatch };

	return <HaloContext.Provider value={value}>{props.children}</HaloContext.Provider>;
};
