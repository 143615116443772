import React, { useContext, useEffect } from "react";
import "./Halo.scss";
import { useTime } from "../../../Hooks/useTime";
import { HaloTimeLabel } from "./HaloTimeLabel/HaloTimeLabel";
import { HaloContext, GetHaloStartTime, GetHaloEndTime } from "../../../Context/HaloContext/HaloContext";
import { HaloContextDispatchActionType } from "../../../Context/HaloContext/HaloContextDispatchActionType";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import nameof from "ts-nameof.macro";
import { DateTime } from "luxon";

export const HaloTimeLabelWrapper = () => {
	const haloContext = useContext(HaloContext);
	const currentTime = useTime();
	const TimeEntries = useTimeEntries(nameof(HaloTimeLabelWrapper));

	useEffect(() => {
		const firstTimeEntry = TimeEntries.GetEarliestEntry();
		if (firstTimeEntry) {
			haloContext.dispatch({
				type: HaloContextDispatchActionType.setStartTime,
				payload: firstTimeEntry.startedWhen,
			});
		}
	}, []);

	function SetCustomEndTime(newEndTime: DateTime) {
		// Interperate string as a time and apply it to DateTime
		haloContext.dispatch({
			type: HaloContextDispatchActionType.setCustomEndTime,
			payload: newEndTime,
		});
	}

	function SetCustomStartTime(newStartTime: DateTime) {
		haloContext.dispatch({
			type: HaloContextDispatchActionType.setCustomStartTime,
			payload: newStartTime,
		});
	}

	return (
		<>
			<HaloTimeLabel
				className="end-time"
				timeToDisplay={GetHaloEndTime(haloContext.state.customEndTime, haloContext.state.endTime, currentTime)}
				SetCustomTime={SetCustomEndTime}
			/>

			<HaloTimeLabel
				className="start-time"
				timeToDisplay={GetHaloStartTime(haloContext.state.customStartTime, haloContext.state.startTime)}
				SetCustomTime={SetCustomStartTime}
			/>
		</>
	);
};
