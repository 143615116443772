import { Source } from "../Data/Source";
import { TheClaw } from "../Data/TheClaw";
import { ITask } from "../Data/Models/ITask";
import { IUseSubscribableCollectionType, useSubscribableCollection } from "./useSubscribable";
import { Guid } from "../Data/Guid";
export interface IUseTasks extends IUseSubscribableCollectionType<ITask> {}
/**
 *
 * Causes re-render when any item in Tasks changes
 * @param source
 */
export function useTasks(source?: Source) {
	const subscribable = useSubscribableCollection(TheClaw.Tasks, source);
	return {
		...subscribable,
	};
}
