import { SubscribableCollection } from "./Subscribable";
import { ITimeEntrySet } from "./Models/ITimeEntrySet";
import { Guid } from "./Guid";
import { List } from "immutable";
import { InstanceManager } from "./InstanceManager";

export class GroupSubscribable extends SubscribableCollection<ITimeEntrySet> {
	public Remove(key: string, source: string): void {
		const item = super.Get(key);
		if (!item) throw `Unable to load time entry set with key '${key}' to remove`;

		item.lastUpdatedWhen = InstanceManager.timeSource.GetUtcTime();
		item.deletedWhen = InstanceManager.timeSource.GetLocalTime();
		super.Set(key, item, source);
	}

	public AllNotDeleted(): List<ITimeEntrySet> {
		return super.All().filter((te) => !te.deletedWhen);
	}
}
