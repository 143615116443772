import { useContext } from "react";
import { TimelineContext } from "../Context/TimelineContext/TimelineContext";
import { TimeCalculationHelpers } from "./TimeCalculationHelpers";
import { useTime } from "./useTime";
import { useTimerCalulatedData } from "./useTimerCalulatedData";
export function useCalculatedTimes(source: string) {
	const { currentTimeEntrySetGUID } = useTimerCalulatedData(source);
	const timelineContext = useContext(TimelineContext);
	useTime();
	const groupTotalTime = currentTimeEntrySetGUID
		? TimeCalculationHelpers.GetTotalGroupTime(
				currentTimeEntrySetGUID,
				timelineContext.state.timelineStartTimeOffsetHours
		  )
		: undefined;
	const todayTotalTime = TimeCalculationHelpers.GetTotalDayTime(0, timelineContext.state.timelineStartTimeOffsetHours);
	const weekTotalTime = TimeCalculationHelpers.GetTotalWeekTime(timelineContext.state.timelineStartTimeOffsetHours);
	return { groupTotalTime, todayTotalTime, weekTotalTime };
}
