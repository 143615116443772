import { JwtResponseModel } from "./JwtResponseModel";
import { LoginResultEnum } from "./LoginResultEnum";
import jwtDecode from "jwt-decode";
import { DateTime } from "luxon";
import { cm } from "./ConnectionManager";
import { JwtModel } from "./JwtModel";
import { Guid } from "./Guid";
import { ITokenResponseModel } from "../../../../Chronometric.API.Auth/ClientApp/src/API/Models/ITokenResponseModel";
import { ITokenRequest } from "../../../../Chronometric.API.Auth/ClientApp/src/API/Models/ITokenRequest";
import { RemoveLastSynced } from "./LocalStorageKeys";
import { InstanceManager } from "./InstanceManager";
import { TimeSource } from "./TimeSource";

export class AuthManager {
	constructor(private readonly timeSource: TimeSource) {}

	public async Logout() {
		this.token = null;
		this.refreshToken = null;
		await InstanceManager.db.delete();
		RemoveLastSynced();
		window.location.reload();
	}

	private _token: string | null = null;
	private get token() {
		if (this._token !== null) return this._token;

		const localStorageToken = localStorage.getItem("cm_token");
		if (localStorageToken !== null) {
			return (this._token = localStorageToken);
		}

		return null;
	}

	private set token(val) {
		this._token = val;
		if (val) localStorage.setItem("cm_token", val);
		else localStorage.removeItem("cm_token");
	}

	private _refreshToken: string | null = null;
	private get refreshToken() {
		if (this._refreshToken !== null) return this._refreshToken;

		const localStorageToken = localStorage.getItem("cm_refresh_token");
		if (localStorageToken !== null) {
			return (this._refreshToken = localStorageToken);
		}

		return null;
	}

	private set refreshToken(val) {
		this._refreshToken = val;
		if (val) localStorage.setItem("cm_refresh_token", val);
		else localStorage.removeItem("cm_refresh_token");
	}

	public async Login(username: string, password: string): Promise<LoginResultEnum> {
		try {
			const response = await cm.Fetch(`${cm.AuthApiBase}/jwt`, {
				method: "POST",
				body: JSON.stringify({ username, password, generateRefreshToken: true } as ITokenRequest),
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (!response.ok) {
				if (response.status === 403) {
					return LoginResultEnum.BadCredentials;
				} else {
					return LoginResultEnum.OtherError;
				}
			}
			const responseJson = (await response.json()) as ITokenResponseModel;
			this.token = responseJson.token;
			this.refreshToken = responseJson.refreshToken;

			return LoginResultEnum.Success;
		} catch (err) {
			console.error(err);
			return LoginResultEnum.OtherError;
		}
	}

	public async Refresh(): Promise<LoginResultEnum> {
		if (!this.refreshToken) return LoginResultEnum.RefreshTokenInvalid;

		try {
			const response = await cm.Fetch(`${cm.AuthApiBase}/jwt/refresh`, {
				method: "POST",
				body: JSON.stringify({ refreshToken: this.refreshToken }),
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (!response.ok) {
				if (response.status === 400) {
					const responseBody = await response.json();
					if ("errors" in responseBody && "RefreshToken" in responseBody.errors)
						return LoginResultEnum.RefreshTokenInvalid;
					else return LoginResultEnum.OtherError;
				} else {
					return LoginResultEnum.OtherError;
				}
			}
			const responseJson = (await response.json()) as JwtResponseModel;
			this.token = responseJson.token;

			return LoginResultEnum.Success;
		} catch (err) {
			console.error(err);
			this.refreshFailures++;
			return LoginResultEnum.OtherError;
		}
	}

	private refreshingResult: Promise<LoginResultEnum> | undefined;
	private refreshing = false;
	public refreshFailures = 0;

	public async *IsUserLoggedIn() {
		const jwtValid = this.IsJwtValid();
		if (jwtValid) yield LoginResultEnum.Success;
		else if (this.refreshToken) {
			if (!this.refreshing || !this.refreshingResult) {
				this.refreshingResult = this.Refresh();
				this.refreshing = true;
			}
			yield LoginResultEnum.Refreshing;
			const refreshResult = await this.refreshingResult;
			this.refreshing = false;
			if (refreshResult === LoginResultEnum.RefreshTokenInvalid) {
				this.refreshToken = null;
			}
			yield refreshResult;
		} else {
			yield LoginResultEnum.LoggedOut;
		}
	}

	public IsJwtValid() {
		const jwtData = this.GetJwtData();
		const jwtValid = jwtData && DateTime.fromSeconds(jwtData.exp).diff(this.timeSource.GetUtcTime()).milliseconds > 0;
		return jwtValid;
	}

	public GetJwtData(): JwtModel | null {
		if (this.token) return jwtDecode(this.token);
		return null;
	}

	public GetIntegrations() {
		const jwtData = this.GetJwtData();

		if (!jwtData) return null;

		const integrations = Array.isArray(jwtData.int) ? jwtData.int : [jwtData.int];

		return integrations.map((int: string) => {
			// tslint:disable-next-line: no-object-literal-type-assertion
			return {
				integrationGuid: int.split("|")[0],
				integrationUrl: int.split("|")[1],
			} as ITokenIntegrationModel;
		});
	}

	public get Token() {
		return this.token;
	}

	public get UserId() {
		const jwtData = this.GetJwtData();

		return (jwtData && jwtData.uid) || null;
	}
}

export interface ITokenIntegrationModel {
	integrationGuid: Guid;
	integrationUrl: string;
}
