import { TimeApiConnection } from "./TimeApiConnection";
import nameof from "ts-nameof.macro";
import { TheClaw } from "./TheClaw";
import { ITokenIntegrationModel } from "./AuthManager";
import { IntegrationApiConnection } from "./IntegrationApiConnection";
import { KeyHelper } from "./KeyHelper";
import _, { isEqual } from "lodash";
import { IGetTasksResponseModel } from "./Models/IGetTasksResponseModel";
import { ITask } from "./Models/ITask";
import { LocalStorageKeys } from "./LocalStorageKeys";
import { InstanceManager } from "./InstanceManager";
import { ITaskMetadata } from "./Models/ITaskMetadata";
import { ITimeEntrySet } from "./Models/ITimeEntrySet";
import { ITimeEntry } from "./Models/ITimeEntry";
import { v4 as uuid } from "uuid";
import { ITaskRequestModelV2 } from "./Models/ITaskRequestModelV2";
import { Guid } from "./Guid";
import { SubscribableCollection } from "./Subscribable";
import { List, Map } from "immutable";
import { TimeSource } from "./TimeSource";
import { Instant } from "./Instant";
import { ensureTaskMetadataDates, ensureTimeEntryDates, ensureTimeEntrySetDates } from "./ChronometricDB";
import { SpecialCause } from "./SpecialCause";

const DEFAULT_SYNC_INTERVAL_MS = 5 * 60 * 1000;
const SHORT_SYNC_INTERVAL_MS = 1000;

interface ILastUpdatedWhenComparable {
	lastUpdatedWhen?: Instant;
}

function CalculateChanges<TItem extends ILastUpdatedWhenComparable, TKey extends string | [string, string]>(
	fromServer: List<TItem>,
	fromClient: List<TItem>,
	keyGetter: (item: TItem) => TKey,
	clientTable: TItem[],
	keyComparer: (a: TKey, b: TKey) => boolean = isEqual
): {
	toClient: List<TItem>;
	toServer: List<TItem>;
} {
	const serverKeys = fromServer.map((tes) => keyGetter(tes));
	const clientKeys = fromClient.map((tes) => keyGetter(tes));

	const commonKeys = clientKeys.filter((key) => serverKeys.some((k) => keyComparer(k, key)));
	let keysForServer = clientKeys.filter((key) => !commonKeys.some((k) => keyComparer(k, key)));
	let keysForClient = serverKeys.filter((key) => !commonKeys.some((k) => keyComparer(k, key)));

	// Intentionally not using .modify() as it has a bug in Safari
	for (const key of commonKeys) {
		const serverItem = fromServer.find((tes) => keyComparer(keyGetter(tes), key));
		const clientItem = clientTable.find((tes) => keyComparer(keyGetter(tes), key));

		if (!serverItem || !clientItem) {
			throw Error("Something has gone terribly wrong");
		}

		if (serverItem.lastUpdatedWhen && clientItem.lastUpdatedWhen) {
			if (serverItem.lastUpdatedWhen > clientItem.lastUpdatedWhen) {
				keysForClient = keysForClient.push(key);
			} else if (serverItem.lastUpdatedWhen < clientItem.lastUpdatedWhen) {
				keysForServer = keysForServer.push(key);
			}
		}
	}

	const toClient = keysForClient.map((key) => fromServer.find((tes) => keyComparer(keyGetter(tes), key))!);
	const toServer = keysForServer.map((key) => fromClient.find((tes) => keyComparer(keyGetter(tes), key))!);

	if (toServer.count() > 0) console.log("To server", toServer);
	if (toClient.count() > 0) console.log("To client", toClient);

	return { toClient, toServer };
}
const SYNC_BUFFER_MINUTES = 10;
export class SyncManager {
	private TriggerSyncDebounced: () => Promise<void>;
	private TriggerSyncOnOtherClientsDebounced: () => Promise<void>;
	private SyncDoneEvent = new Event("syncdone");
	private CurrentSyncTimeout?: NodeJS.Timeout = undefined;
	private SyncRunning = false;
	private CurrentSyncPromise: Promise<[boolean, boolean]> | undefined = undefined;
	private SyncInterval = DEFAULT_SYNC_INTERVAL_MS;

	constructor(
		private readonly timeApi: TimeApiConnection,
		private readonly integrationApi: IntegrationApiConnection,
		private readonly timeSource: TimeSource
	) {
		this.TriggerSyncDebounced = _.debounce(() => this.TriggerSync(), 50);
		this.TriggerSyncOnOtherClientsDebounced = _.debounce(() => InstanceManager.timeHub.TriggerSyncOnOtherClients(), 50);

		TheClaw.Groups.Subscribe({
			callback: () => this.TriggerSyncDebounced(),
			source: nameof(SyncManager),
			excludeSpecialCauses: [SpecialCause.UpdateLastSyncedWhen],
		});
		TheClaw.Tasks.Subscribe({
			callback: () => this.TriggerSyncDebounced(),
			source: nameof(SyncManager),
			excludeSpecialCauses: [SpecialCause.UpdateLastSyncedWhen],
		});
		TheClaw.Tags.Subscribe({
			callback: () => this.TriggerSyncDebounced(),
			source: nameof(SyncManager),
			excludeSpecialCauses: [SpecialCause.UpdateLastSyncedWhen],
		});
		TheClaw.TaskTagLinks.Subscribe({
			callback: () => this.TriggerSyncDebounced(),
			source: nameof(SyncManager),
			excludeSpecialCauses: [SpecialCause.UpdateLastSyncedWhen],
		});
		TheClaw.TimeEntries.Subscribe({
			callback: () => this.TriggerSyncDebounced(),
			source: nameof(SyncManager),
			excludeSpecialCauses: [SpecialCause.UpdateLastSyncedWhen],
		});
		TheClaw.TaskMetadatas.Subscribe({
			callback: () => this.TriggerSyncDebounced(),
			source: nameof(SyncManager),
			excludeSpecialCauses: [SpecialCause.UpdateLastSyncedWhen],
		});
	}

	private async TriggerSync() {
		await this.SyncImmediately();
	}

	public SyncImmediately(): Promise<[boolean, boolean] | undefined> {
		if (this.CurrentSyncTimeout) clearTimeout(this.CurrentSyncTimeout);
		return this.Sync();
	}

	public SyncLoop() {
		// Checking that there isn't already a timeout ID means that
		// the loop can't be run concurrently
		if (!this.CurrentSyncTimeout) this.Sync();
	}

	public async Sync(): Promise<[boolean, boolean] | undefined> {
		if (InstanceManager.auth.Token === null) return;

		if (this.SyncRunning) {
			// If we're here, it means that a user interaction has caused
			// another sync while one was already running. Therefore we need
			// to cause another sync shortly after this one has finished so those
			// changes will be synced
			console.log("Sync already running. Scheduling next sync in " + SHORT_SYNC_INTERVAL_MS / 1000 + "s");
			this.SyncInterval = SHORT_SYNC_INTERVAL_MS;
			return this.CurrentSyncPromise;
		}

		if (this.SyncInterval === SHORT_SYNC_INTERVAL_MS) {
			this.SyncInterval = DEFAULT_SYNC_INTERVAL_MS;
		}

		this.SyncRunning = true;

		this.CurrentSyncPromise = this.SyncInternal();

		const [clientDataUpdated, serverDataUpdated] = await this.CurrentSyncPromise;

		if (serverDataUpdated) {
			console.log("Triggering sync on other clients");
			await this.TriggerSyncOnOtherClientsDebounced();
		}

		// Using setTimeout instead of setInterval means that the next sync will be timed
		// from the resolution of the Promise returned from Sync().
		// Therefore, the next sync will be 5s after the last sync finished
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		this.CurrentSyncTimeout = setTimeout(() => this.Sync(), this.SyncInterval);

		return [clientDataUpdated, serverDataUpdated];
	}

	private async GetTimeData(
		lastSync: Instant,
		clientId: string,
		isFirstSync: boolean
	): Promise<{
		srvTimeEntrySets: List<ITimeEntrySet>;
		clnTimeEntrySets: List<ITimeEntrySet>;
		srvTimeEntries: List<ITimeEntry>;
		clnTimeEntries: List<ITimeEntry>;
		srvTaskMetadatas: List<ITaskMetadata>;
		clnTaskMetadatas: List<ITaskMetadata>;
	}> {
		const [
			srvTimeEntrySets,
			clnTimeEntrySets,
			srvTimeEntries,
			clnTimeEntries,
			srvTaskMetadatas,
			clnTaskMetadatas,
		] = await Promise.all([
			this.timeApi.GetTimeEntrySets(lastSync, clientId),
			TheClaw.Groups.All().filter(
				(x) =>
					x.createdWhen > lastSync ||
					x.lastUpdatedWhen > lastSync ||
					(!!x.queuedForExportWhen && x.queuedForExportWhen > lastSync)
			),

			this.timeApi.GetTimeEntries(lastSync, clientId),
			TheClaw.TimeEntries.All().filter((x) => x.startedWhen > lastSync || x.lastUpdatedWhen > lastSync),

			this.timeApi.GetTaskMetadata(
				isFirstSync ? Instant.fromObject({ year: 1970, month: 1, day: 1 }) : lastSync,
				clientId
			),
			TheClaw.TaskMetadatas.All().filter(
				(x) => (x.createdWhen && x.createdWhen > lastSync) || (x.lastUpdatedWhen && x.lastUpdatedWhen > lastSync)
			),
		]);

		return {
			srvTimeEntrySets: List(srvTimeEntrySets),
			clnTimeEntrySets,
			srvTimeEntries: List(srvTimeEntries),
			clnTimeEntries,
			srvTaskMetadatas: List(srvTaskMetadatas),
			clnTaskMetadatas,
		};
	}

	private async SyncInternal(): Promise<[boolean, boolean]> {
		let clientDataUpdated = false;
		let serverDataUpdated = false;
		const syncDate = this.timeSource.GetUtcTime();
		try {
			console.info("Starting sync");

			await this.timeSource.UpdateTimeOffset();

			// TODO: Check local time against server time

			const { lastSync, isFirstSync } = this.GetLastSync();

			const lastSyncWithBuffer = lastSync.minus({ minutes: SYNC_BUFFER_MINUTES });

			const integrations = this.GetIntegrations();

			const integrationLastSyncs = integrations.map((int) => {
				const intLastSync = this.GetLastSync(int.integrationGuid);

				const intLastSyncWithBuffer = intLastSync.lastSync.minus({ minutes: SYNC_BUFFER_MINUTES });

				return {
					isFirstSync: intLastSync.isFirstSync,
					lastSync: intLastSyncWithBuffer,
					integrationGuid: int.integrationGuid,
				};
			});

			const clientId = this.GetClientId();

			const dbInst = InstanceManager.db;
			if (!dbInst) {
				throw new Error("Can't sync without a database!");
			}

			await dbInst.Ready();

			// Get new/updated time entries, time entry sets & task metadatas from DB and API
			const timeData = await this.GetTimeData(lastSyncWithBuffer, clientId, isFirstSync);

			// CalculateChanges for time entries, time entry sets & task metadatas
			const [timeEntrySets, timeEntries, taskMetadatas] = [
				CalculateChanges(
					timeData.srvTimeEntrySets,
					timeData.clnTimeEntrySets,
					(item: ITimeEntrySet) => item.timeEntrySetGuid,
					TheClaw.Groups.All().toArray()
				),
				CalculateChanges(
					timeData.srvTimeEntries,
					timeData.clnTimeEntries,
					(item: ITimeEntry) => item.timeEntryGuid,
					TheClaw.TimeEntries.All().toArray()
				),
				CalculateChanges(
					timeData.srvTaskMetadatas,
					timeData.clnTaskMetadatas,
					(item: ITaskMetadata) => [item.integrationGuid, item.externalId],
					TheClaw.TaskMetadatas.All().toArray()
				),
			];

			// Write new/updated time entries, time entry sets & task metadatas to DB
			this.BulkSet(taskMetadatas.toClient, (tm) => KeyHelper.GetTaskKey(tm), TheClaw.TaskMetadatas);
			this.BulkSet(timeEntrySets.toClient, (tes) => tes.timeEntrySetGuid, TheClaw.Groups);
			this.BulkSet(timeEntries.toClient, (te) => te.timeEntryGuid, TheClaw.TimeEntries);

			clientDataUpdated =
				taskMetadatas.toClient.count() > 0 || timeEntrySets.toClient.count() > 0 || timeEntries.toClient.count() > 0;
			serverDataUpdated =
				taskMetadatas.toServer.count() > 0 || timeEntrySets.toServer.count() > 0 || timeEntries.toServer.count() > 0;

			let sendTaskDataPromise = Promise.resolve([] as void[]);

			try {
				// Get new/updated tasks from DB and API
				const taskData = await this.GetAllTasks(integrationLastSyncs, clientId);

				// CalculateChanges for tasks
				const tasks = await Promise.all(
					taskData
						.filter((integrationTasks) => integrationTasks.tasks.fromServer !== null)
						.map((integrationTasks) => {
							return {
								...integrationTasks,
								changes: CalculateChanges(
									List(integrationTasks.tasks.fromServer!.tasks),
									integrationTasks.tasks.fromClient,
									(item: ITask) => [item.integrationGuid, item.externalId],
									TheClaw.Tasks.All()
										.filter((x) => x.integrationGuid === x.integrationGuid)
										.toArray()
								),
							};
						})
				);

				clientDataUpdated = clientDataUpdated || tasks.some((x) => x.changes.toClient.count() > 0);
				serverDataUpdated = serverDataUpdated || tasks.some((x) => x.changes.toServer.count() > 0);

				sendTaskDataPromise = Promise.all(
					tasks
						.filter((integrationTasks) => integrationTasks.tasks.fromServer !== null)
						.map(async (integrationTasks) => {
							const changes = integrationTasks.changes;
							const tasks = integrationTasks.tasks;

							// Write items to DB
							for (const toClient of changes.toClient) {
								TheClaw.Tasks.Set(KeyHelper.GetTaskKey(toClient), toClient, nameof(SyncManager));
								this.updateTags(tasks.fromServer!, toClient);
							}

							// Write new & updated items to API
							const toServerWithUnknowns = changes.toServer.concat(
								tasks.fromServer!.unknownTaskIds.map(
									(unknExtId) => tasks.fromClient.find((clientTask) => clientTask.externalId === unknExtId)!
								)
							);

							if (toServerWithUnknowns.count() > 0) {
								console.debug(`New Tasks to server: ${changes.toServer.count()}`, changes.toServer);
							}

							await this.integrationApi.UpdateTasks(
								integrationTasks.integration,
								toServerWithUnknowns.toArray().filter((x) => !!x),
								clientId
							);

							this.WriteLastSync(syncDate, integrationTasks.integration.integrationGuid);
						})
				);
			} catch (err) {
				console.error("Unable to sync tasks: " + err);
			}

			await Promise.all([
				this.timeApi.UpdateTimeData({
					taskMetadatas: taskMetadatas.toServer.map(ensureTaskMetadataDates).toArray(),
					timeEntrySets: timeEntrySets.toServer.map(ensureTimeEntrySetDates).toArray(),
					timeEntries: timeEntries.toServer.map(ensureTimeEntryDates).toArray(),
					clientId,
				}),
				sendTaskDataPromise,
			]);

			this.UpdateLastSyncedWhen(timeEntrySets, timeEntries);

			// Update lastSync in local storage
			this.WriteLastSync(syncDate);
		} catch (err) {
			console.error("Sync error", err);
		}

		console.info("Sync done");
		if (clientDataUpdated) window.dispatchEvent(this.SyncDoneEvent);
		this.SyncRunning = false;
		return [clientDataUpdated, serverDataUpdated];
	}

	private BulkSet<T>(
		data: List<T>,
		keyGetter: (item: T) => string,
		subscribable: SubscribableCollection<T>,
		modifier: (item: T) => T = (item) => item,
		cause?: SpecialCause
	) {
		const toStore = data.reduce((acc, tes) => acc.set(keyGetter(tes), modifier(tes)), Map<string, T>());

		subscribable.BulkSet(toStore, nameof(SyncManager), true, cause);
	}

	private UpdateLastSyncedWhen(
		timeEntrySets: { toClient: List<ITimeEntrySet>; toServer: List<ITimeEntrySet> },
		timeEntries: { toClient: List<ITimeEntry>; toServer: List<ITimeEntry> }
	) {
		this.BulkSet(
			timeEntrySets.toServer,
			(tes) => tes.timeEntrySetGuid,
			TheClaw.Groups,
			(tes) => {
				return { ...TheClaw.Groups.Get(tes.timeEntrySetGuid)!, lastSyncedWhen: this.timeSource.GetUtcTime() };
			},
			SpecialCause.UpdateLastSyncedWhen
		);
		this.BulkSet(
			timeEntries.toServer,
			(te) => te.timeEntryGuid,
			TheClaw.TimeEntries,
			(te) => {
				return { ...TheClaw.TimeEntries.Get(te.timeEntryGuid)!, lastSyncedWhen: this.timeSource.GetUtcTime() };
			},
			SpecialCause.UpdateLastSyncedWhen
		);
	}

	private WriteLastSync(syncDate: Instant, integrationGuid?: Guid) {
		localStorage.setItem(this.GetLastSyncKey(integrationGuid), syncDate.toISO());
	}

	private GetLastSync(integrationGuid?: Guid) {
		const lastSyncLocStor = localStorage.getItem(this.GetLastSyncKey(integrationGuid));
		const isFirstSync = !lastSyncLocStor;
		const lastSync: Instant = lastSyncLocStor
			? Instant.fromISO(lastSyncLocStor)
			: this.timeSource.GetUtcTime().minus({ months: 4 });
		return { lastSync, isFirstSync };
	}

	private GetLastSyncKey(integrationGuid?: Guid): string {
		return LocalStorageKeys.lastSyncKey + (integrationGuid ? integrationGuid : "");
	}

	private GetClientId() {
		const clientIdLocStor = localStorage.getItem(LocalStorageKeys.clientIdKey);
		const clientId = clientIdLocStor ? clientIdLocStor : uuid();
		if (!clientIdLocStor) {
			localStorage.setItem(LocalStorageKeys.clientIdKey, clientId);
		}
		return clientId;
	}

	private async GetAllTasks(
		integrationLastSyncs: { lastSync: Instant; isFirstSync: boolean; integrationGuid: string }[],
		clientId: string
	) {
		const integrations = this.GetIntegrations();

		return await Promise.all(
			integrations.map(async (integration) => {
				return {
					integration,
					tasks: await this.GetIntegrationTasks(
						integration,
						integrationLastSyncs.find((x) => x.integrationGuid === integration.integrationGuid)!.lastSync,
						clientId
					),
				};
			})
		);
	}

	private GetIntegrations() {
		const integrations = InstanceManager.auth.GetIntegrations();
		if (!integrations) {
			throw new Error("Unable to get integrations");
		}
		return integrations;
	}

	private async GetIntegrationTasks(integration: ITokenIntegrationModel, lastSync: Instant, clientId: string) {
		const [dbTasks, dbGroups, dbTaskMetadatas] = await this.GetAllTasksAndRelatedDataForIntegration(integration);
		const allTaskKeys = dbTasks.map((task) => {
			return {
				externalId: task.externalId,
			} as ITaskRequestModelV2;
		});
		const missingClientGroupTasks = dbGroups
			.filter((grp) => !!grp.taskExternalId && !allTaskKeys.some((t) => t.externalId === grp.taskExternalId))
			.map((grp) => {
				return { externalId: grp.taskExternalId, force: true } as ITaskRequestModelV2;
			});
		const missingClientTaskMetadataTasks = dbTaskMetadatas
			.filter((grp) => !!grp.externalId && !allTaskKeys.some((t) => t.externalId === grp.externalId))
			.map((grp) => {
				return { externalId: grp.externalId, force: true } as ITaskRequestModelV2;
			});
		const taskIds = allTaskKeys
			.concat(missingClientGroupTasks)
			.concat(missingClientTaskMetadataTasks)
			.sortBy((x) => x.externalId);

		let serverTasks = null;
		try {
			serverTasks = await this.integrationApi.GetTasks(integration, taskIds.toArray(), lastSync, clientId);
		} catch (err) {
			console.error("Error getting tasks from integration", err);
		}
		const updatedTasks = dbTasks.filter((x) => x.lastUpdatedWhen > lastSync);

		return { fromClient: updatedTasks, fromServer: serverTasks };
	}

	private async GetAllTasksAndRelatedDataForIntegration(integration: ITokenIntegrationModel) {
		return await Promise.all([
			TheClaw.Tasks.All().filter((x) => x.integrationGuid === integration.integrationGuid),
			TheClaw.Groups.All().filter((x) => x.taskIntegrationGuid === integration.integrationGuid),
			TheClaw.TaskMetadatas.All().filter((x) => x.integrationGuid === integration.integrationGuid),
		]);
	}

	private updateTags(tasks: IGetTasksResponseModel, serverItem: ITask) {
		const tags = tasks.links
			.filter((link) => serverItem.externalId === link.taskExternalId)
			.map((link) => {
				return { link, tag: tasks.tags.find((tag) => tag.externalId === link.tagExternalId) };
			});
		for (const tag of tags) {
			if (tag.tag) {
				TheClaw.Tags.Set(KeyHelper.GetTagKey(tag.tag), tag.tag, nameof(SyncManager));
				TheClaw.TaskTagLinks.Set(KeyHelper.GetTaskTagLinkKey(tag.link), tag.link, nameof(SyncManager));
			}
		}
	}
}
