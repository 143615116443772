import { useMemo } from "react";
import { useTime } from "../../../Hooks/useTime";
import { useTimeControls } from "../../../Hooks/useTimeControls";
import { useTimerCalulatedData } from "../../../Hooks/useTimerCalulatedData";
import { useGroups } from "../../../Hooks/useGroups";
import { Duration } from "luxon";

export function useGroupTime(timeEntrySetGuid: string, groupsDayOffset: number, source: string) {
	const time = useTime();
	const { isPlaying } = useTimerCalulatedData(source);
	const groups = useGroups(source);
	const timeControls = useTimeControls(source);

	const timeWhenPlaying = isPlaying ? time : undefined;
	return useMemo(() => {
		const timeInSeconds = timeControls.GetTotalGroupTime(timeEntrySetGuid, groupsDayOffset);
		return formatDurationElements(timeInSeconds);
	}, [isPlaying, timeWhenPlaying, groupsDayOffset, timeEntrySetGuid, groups.timestamp, timeControls]);
}

export function formatDuration(timeInSeconds: Duration) {
	const { timeUnitIndicator, totalTime } = formatDurationElements(timeInSeconds);
	return `${totalTime} ${timeUnitIndicator}`;
}

export function formatDurationElements(duration: Duration) {
	let totalTime = "";
	let timeUnitIndicator = "";
	const timeInSeconds = duration.as("seconds");
	if (timeInSeconds < 3600) {
		if (timeInSeconds < 60) {
			totalTime = Math.floor(timeInSeconds).toFixed(0);
			timeUnitIndicator = "s";
		} else {
			totalTime = Math.floor(timeInSeconds / 60).toFixed(0);
			timeUnitIndicator = "m";
		}
	} else {
		totalTime = (timeInSeconds / 3600).toFixed(1);
		timeUnitIndicator = "h";
	}
	return { totalTime, timeUnitIndicator };
}
