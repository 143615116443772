import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import { InstanceManager } from "./Data/InstanceManager";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: "27034327-3e04-40e5-8523-af7ebcb2ff9c",
		extensions: [reactPlugin],
		disableFetchTracking: false,
		enableUnhandledPromiseRejectionTracking: true,
		enableCorsCorrelation: true,
		cookieDomain: "chronometric.app",
	},
});
appInsights.loadAppInsights();
appInsights.trackPageView();

export const AppWithAITracking = withAITracking(reactPlugin, App);

ReactDOM.render(<AppWithAITracking />, document.getElementById("app-container"));

setInterval(() => {
	if (InstanceManager.serviceWorker && InstanceManager.serviceWorker.state === "activated")
		InstanceManager.serviceWorker.postMessage({ action: "check" });
}, 1000);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
	onReady: onReady,
	onSuccess: onReady,
	onUpdate: onReady,
});

function onReady(registration: ServiceWorkerRegistration) {
	console.log("setting service worker instance", registration.active);
	InstanceManager.serviceWorker = registration.active;

	if (InstanceManager.serviceWorker) {
		console.log(`adding message event listener`);
		window.navigator.serviceWorker.addEventListener("message", (ev) => {
			console.log(`got message`, ev);
			if (ev instanceof MessageEvent) {
				console.log(`Got ${ev.data.action} from ${ev.data.senderId}`, ev.data);
				switch (ev.data.action) {
					case "check":
						alert("Please close other tabs. Chronometric can only run on one tab in a browser");
						break;
					default:
						break;
				}
			}
		});
	}
}
