import React from "react";
import { TaskSummaryProps } from "./TaskSummary.Interface";
import "./TaskSummary.scss";
import { JiraTagTypeCodeNames } from "../../../../Hooks/useTags";

export const TaskSummaryDetails = (props: TaskSummaryProps) => {
	const customerName = props.tags.find((x) => x.tagTypeCodeName === JiraTagTypeCodeNames.customer)?.value;

	return (
		<>
			{props.Task ? (
				<div className="task-info">
					{customerName ? <div className="task-info-section customer-name">Customer: {customerName}</div> : <></>}
					{props.Task.dueWhen ? (
						<div className="task-info-section task-due-date">Due: {props.Task.dueWhen.toLocaleString()}</div>
					) : (
						<></>
					)}

					<div className="task-info-section status">Status: {props.Task.status}</div>
					<div className="task-info-section description">{props.Task.description}</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
