import React from "react";

export enum FieldTypes {
	input = "input",
	textarea = "textarea",
}

export function Field({
	fieldType,
	label,
	name,
	value,
	onChange,
	errors,
	setErrors,
	pristine,
	validating,
	validate,
	formSubmitted,
	placeholder,
	...other
}: any) {
	const showErrors = (!pristine || formSubmitted) && !!errors.length;

	// TODO: Make ctrl+enter optional
	function HandleKeyPress(e: React.KeyboardEvent<HTMLTextAreaElement>) {
		if (e.ctrlKey && e.key === "Enter") {
			e.preventDefault();
			e.stopPropagation();
			(e.target as HTMLTextAreaElement).form!.dispatchEvent(new Event("submit", { bubbles: false, cancelable: true }));
		}
	}

	return (
		<div className="field">
			<label htmlFor={name}>{label}</label>
			{fieldType && fieldType === FieldTypes.textarea ? (
				<textarea
					id={name}
					value={value}
					onChange={onChange}
					onBlur={() => !pristine && validate()}
					onKeyPress={HandleKeyPress}
					{...other}
				/>
			) : (
				<input
					id={name}
					value={value}
					onChange={onChange}
					onBlur={() => !pristine && validate()}
					{...other}
					placeholder={placeholder}
					onKeyPress={HandleKeyPress}
				/>
			)}
			<div>{showErrors && errors.map((errorMsg: any) => <div key={errorMsg}>{errorMsg}</div>)}</div>
		</div>
	);
}
