import { ITimeEntry } from "./Models/ITimeEntry";
import Dexie from "dexie";
import { Guid } from "./Guid";
import nameof from "ts-nameof.macro";
import { ITask } from "./Models/ITask";
import { ITimeEntrySet } from "./Models/ITimeEntrySet";
import { IntegrationSyncHistoryModel } from "./IntegrationSyncHistoryModel";
import { TimeSyncHistoryModel } from "./TimeSyncHistoryModel";
import { DataActionEnum, SubscribableCollection } from "./Subscribable";
import { TheClaw } from "./TheClaw";
import { ITag } from "./Models/ITag";
import { ITaskTagLink } from "./Models/ITaskTagLink";
import { setTimeout } from "timers";
import { KeyHelper } from "./KeyHelper";
import { ITaskMetadata } from "./Models/ITaskMetadata";
import { DateTime } from "luxon";
import { SyncManager } from "./SyncManager";
import { Map, Set } from "immutable";
import { Instant } from "./Instant";

export const ITimeEntryType = {} as ITimeEntryStorable;
export const ITaskType = {} as ITask;
export const ITagType = {} as ITag;
export const ITaskTagLinkType = {} as ITaskTagLink;
export const ITimeEntrySetType = {} as ITimeEntrySet;
export const ITaskMetadataType = {} as ITaskMetadata;

export const integrationSyncHistoryModel = {} as IntegrationSyncHistoryModel;
export const timeSyncHistoryModel = {} as TimeSyncHistoryModel;

type ITimeEntryStorableBase = Omit<
	ITimeEntry,
	"startedWhen" | "endedWhen" | "lastUpdatedWhen" | "deletedWhen" | "lastSyncedWhen"
>;
interface ITimeEntryStorable extends ITimeEntryStorableBase {
	startedWhen: Date;
	endedWhen?: Date;
	lastUpdatedWhen: Date;
	deletedWhen?: Date;
	lastSyncedWhen?: Date;
}

type ITimeEntrySetStorableBase = Omit<
	ITimeEntrySet,
	| "createdWhen"
	| "lastUpdatedWhen"
	| "deletedWhen"
	| "lastSyncedWhen"
	| "lastExportedWhen"
	| "queuedForExportWhen"
	| "lastExportErrorOccurredWhen"
>;
interface ITimeEntrySetStorable extends ITimeEntrySetStorableBase {
	createdWhen: Date;
	lastExportErrorOccurredWhen?: Date;
	deletedWhen?: Date;
	lastUpdatedWhen: Date;
	lastSyncedWhen?: Date;
	lastExportedWhen?: Date;
	queuedForExportWhen?: Date;
}

type ITaskMetadataStorableBase = Omit<ITaskMetadata, "createdWhen" | "lastUpdatedWhen" | "usageCountCalculatedWhen">;
interface ITaskMetadataStorable extends ITaskMetadataStorableBase {
	createdWhen?: Date;
	lastUpdatedWhen?: Date;
	usageCountCalculatedWhen?: Date;
}

type ITaskStorableBase = Omit<ITask, "createdWhen" | "lastUpdatedWhen" | "dueWhen" | "externalCreatedWhen">;
interface ITaskStorable extends ITaskStorableBase {
	createdWhen: Date;
	lastUpdatedWhen: Date;
	dueWhen?: Date;
	externalCreatedWhen: Date;
}

type ITagStorableBase = Omit<ITag, "createdWhen" | "lastUpdatedWhen">;
interface ITagStorable extends ITagStorableBase {
	createdWhen: Date;
	lastUpdatedWhen: Date;
}

interface ITaskTagLinkStorable extends ITaskTagLink {}

export function ensureTimeEntryDates(obj: ITimeEntryStorable | ITimeEntry): ITimeEntry {
	return {
		...obj,
		lastUpdatedWhen: toUtcDate(obj.lastUpdatedWhen)!,
		deletedWhen: obj.deletedWhen && cleanLocalDate(obj.deletedWhen),
		endedWhen: obj.endedWhen && cleanLocalDate(obj.endedWhen),
		startedWhen: cleanLocalDate(obj.startedWhen)!,
		lastSyncedWhen: obj.lastSyncedWhen && toUtcDate(obj.lastSyncedWhen),
	};
}

export function ensureTaskDates(obj: ITaskStorable | ITask): ITask {
	return {
		...obj,
		lastUpdatedWhen: toUtcDate(obj.lastUpdatedWhen)!,
		createdWhen: toUtcDate(obj.createdWhen)!,
		dueWhen: obj.dueWhen && toUtcDate(obj.dueWhen),
		externalCreatedWhen: toUtcDate(obj.externalCreatedWhen)!,
	};
}

export function ensureTagDates(obj: ITagStorable | ITag): ITag {
	return {
		...obj,
		lastUpdatedWhen: toUtcDate(obj.lastUpdatedWhen)!,
		createdWhen: toUtcDate(obj.createdWhen)!,
	};
}

export function ensureTimeEntrySetDates(obj: ITimeEntrySetStorable | ITimeEntrySet): ITimeEntrySet {
	return {
		...obj,
		lastUpdatedWhen: toUtcDate(obj.lastUpdatedWhen)!,
		deletedWhen: obj.deletedWhen && toUtcDate(obj.deletedWhen),
		createdWhen: toUtcDate(obj.createdWhen)!,
		lastExportedWhen: obj.lastExportedWhen && toUtcDate(obj.lastExportedWhen),
		lastExportErrorOccurredWhen: obj.lastExportErrorOccurredWhen && toUtcDate(obj.lastExportErrorOccurredWhen),
		queuedForExportWhen: obj.queuedForExportWhen && toUtcDate(obj.queuedForExportWhen),
		lastSyncedWhen: obj.lastSyncedWhen && toUtcDate(obj.lastSyncedWhen),
	};
}

export function ensureTaskMetadataDates(obj: ITaskMetadataStorable | ITaskMetadata): ITaskMetadata {
	return {
		...obj,
		lastUpdatedWhen: obj.lastUpdatedWhen && toUtcDate(obj.lastUpdatedWhen),
		createdWhen: obj.createdWhen && toUtcDate(obj.createdWhen),
		usageCountCalculatedWhen: obj.usageCountCalculatedWhen && toUtcDate(obj.usageCountCalculatedWhen),
	};
}

export function toUtcDate(val: DateTime | Date | string) {
	const momVal = typeof val === "string" ? DateTime.fromISO(val) : val instanceof Date ? DateTime.fromJSDate(val) : val;

	if (!DateTime.isDateTime(momVal)) {
		throw new Error(`Unable to convert '${val}' to DateTime`);
	}
	return momVal.toUTC();
}

export function cleanLocalDate(val: DateTime | Date | string) {
	const momVal = typeof val === "string" ? DateTime.fromISO(val) : val instanceof Date ? DateTime.fromJSDate(val) : val;

	if (!DateTime.isDateTime(momVal)) {
		throw new Error(`Unable to convert '${val}' to DateTime`);
	}
	return momVal.toLocal();
}

export class ChronometricDB extends Dexie {
	public TimeEntries: Dexie.Table<ITimeEntryStorable, Guid>;
	public Tasks: Dexie.Table<ITaskStorable, [Guid, string]>;
	public Tags: Dexie.Table<ITagStorable, [Guid, string]>;
	public TaskTagLinks: Dexie.Table<ITaskTagLinkStorable, [Guid, string, string]>;
	public Groups: Dexie.Table<ITimeEntrySetStorable, Guid>;
	public TaskMetadatas: Dexie.Table<ITaskMetadataStorable, [Guid, string]>;

	public IntegrationSyncHistory: Dexie.Table<IntegrationSyncHistoryModel, number>;
	public TimeSyncHistory: Dexie.Table<TimeSyncHistoryModel, number>;

	private isReady = false;

	constructor() {
		super(`ChronometricDB`);

		// tslint:disable: no-object-literal-type-assertion

		this.version(1).stores({
			TimeEntries: `&${nameof(ITimeEntryType.timeEntryGuid)}, ${nameof(ITimeEntryType.timeEntrySetGuid)}, ${nameof(
				ITimeEntryType.deletedWhen
			)}`,

			Tasks: `[${nameof(ITaskType.integrationGuid)}+${nameof(ITaskType.externalId)}], ${nameof(
				ITaskType.integrationGuid
			)}, ${nameof(ITaskType.name)}`,

			Tags: `[${nameof(ITagType.integrationGuid)}+${nameof(ITagType.externalId)}], ${nameof(
				ITagType.integrationGuid
			)}, ${nameof(ITagType.value)}`,

			TaskTagLinks: `[${nameof(ITaskTagLinkType.integrationGuid)}+${nameof(ITaskTagLinkType.taskExternalId)}+${nameof(
				ITaskTagLinkType.tagExternalId
			)}], ${nameof(ITaskTagLinkType.integrationGuid)}`,

			Groups: `&${nameof(ITimeEntrySetType.timeEntrySetGuid)}, [${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}+${nameof(ITimeEntrySetType.taskExternalId)}], ${nameof(ITimeEntrySetType.deletedWhen)}`,

			IntegrationSyncHistory: `${nameof(integrationSyncHistoryModel.SyncHistoryId)}++`,

			TimeSyncHistory: `${nameof(timeSyncHistoryModel.SyncHistoryId)}++`,

			TaskMetadatas: `[${nameof(ITaskMetadataType.integrationGuid)}+${nameof(ITaskMetadataType.externalId)}], ${nameof(
				ITaskMetadataType.integrationGuid
			)}, ${nameof(ITaskMetadataType.isFavorite)}, ${nameof(ITaskMetadataType.usageCount)}`,
		});

		this.version(2).stores({
			TimeEntries: `&${nameof(ITimeEntryType.timeEntryGuid)}, ${nameof(ITimeEntryType.timeEntrySetGuid)}, ${nameof(
				ITimeEntryType.deletedWhen
			)}`,

			Tasks: `[${nameof(ITaskType.integrationGuid)}+${nameof(ITaskType.externalId)}], ${nameof(
				ITaskType.integrationGuid
			)}, ${nameof(ITaskType.name)}`,

			Tags: `[${nameof(ITagType.integrationGuid)}+${nameof(ITagType.externalId)}], ${nameof(
				ITagType.integrationGuid
			)}, ${nameof(ITagType.value)}`,

			TaskTagLinks: `[${nameof(ITaskTagLinkType.integrationGuid)}+${nameof(ITaskTagLinkType.taskExternalId)}+${nameof(
				ITaskTagLinkType.tagExternalId
			)}], ${nameof(ITaskTagLinkType.integrationGuid)}`,

			Groups: `&${nameof(ITimeEntrySetType.timeEntrySetGuid)}, [${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}+${nameof(ITimeEntrySetType.taskExternalId)}], ${nameof(ITimeEntrySetType.deletedWhen)}, ${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}`,

			IntegrationSyncHistory: `${nameof(integrationSyncHistoryModel.SyncHistoryId)}++`,

			TimeSyncHistory: `${nameof(timeSyncHistoryModel.SyncHistoryId)}++`,

			TaskMetadatas: `[${nameof(ITaskMetadataType.integrationGuid)}+${nameof(ITaskMetadataType.externalId)}], ${nameof(
				ITaskMetadataType.integrationGuid
			)}, ${nameof(ITaskMetadataType.isFavorite)}, ${nameof(ITaskMetadataType.usageCount)}`,
		});

		this.version(3).stores({
			TimeEntries: `&${nameof(ITimeEntryType.timeEntryGuid)}, ${nameof(ITimeEntryType.timeEntrySetGuid)}, ${nameof(
				ITimeEntryType.deletedWhen
			)}`,

			Tasks: `[${nameof(ITaskType.integrationGuid)}+${nameof(ITaskType.externalId)}], ${nameof(
				ITaskType.integrationGuid
			)}, ${nameof(ITaskType.name)}`,

			Tags: `[${nameof(ITagType.integrationGuid)}+${nameof(ITagType.externalId)}], ${nameof(
				ITagType.integrationGuid
			)}, ${nameof(ITagType.value)}`,

			TaskTagLinks: `[${nameof(ITaskTagLinkType.integrationGuid)}+${nameof(ITaskTagLinkType.taskExternalId)}+${nameof(
				ITaskTagLinkType.tagExternalId
			)}], ${nameof(ITaskTagLinkType.integrationGuid)}`,

			Groups: `&${nameof(ITimeEntrySetType.timeEntrySetGuid)}, [${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}+${nameof(ITimeEntrySetType.taskExternalId)}], ${nameof(ITimeEntrySetType.deletedWhen)}, ${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}, ${nameof(ITimeEntrySetType.lastUpdatedWhen)}`,

			IntegrationSyncHistory: `${nameof(integrationSyncHistoryModel.SyncHistoryId)}++`,

			TimeSyncHistory: `${nameof(timeSyncHistoryModel.SyncHistoryId)}++`,

			TaskMetadatas: `[${nameof(ITaskMetadataType.integrationGuid)}+${nameof(ITaskMetadataType.externalId)}], ${nameof(
				ITaskMetadataType.integrationGuid
			)}, ${nameof(ITaskMetadataType.isFavorite)}, ${nameof(ITaskMetadataType.usageCount)}`,
		});

		this.version(4).stores({
			TimeEntries: `&${nameof(ITimeEntryType.timeEntryGuid)}, ${nameof(ITimeEntryType.timeEntrySetGuid)}, ${nameof(
				ITimeEntryType.deletedWhen
			)}, ${nameof(ITimeEntryType.lastUpdatedWhen)}`,

			Tasks: `[${nameof(ITaskType.integrationGuid)}+${nameof(ITaskType.externalId)}], ${nameof(
				ITaskType.integrationGuid
			)}, ${nameof(ITaskType.name)}`,

			Tags: `[${nameof(ITagType.integrationGuid)}+${nameof(ITagType.externalId)}], ${nameof(
				ITagType.integrationGuid
			)}, ${nameof(ITagType.value)}`,

			TaskTagLinks: `[${nameof(ITaskTagLinkType.integrationGuid)}+${nameof(ITaskTagLinkType.taskExternalId)}+${nameof(
				ITaskTagLinkType.tagExternalId
			)}], ${nameof(ITaskTagLinkType.integrationGuid)}`,

			Groups: `&${nameof(ITimeEntrySetType.timeEntrySetGuid)}, [${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}+${nameof(ITimeEntrySetType.taskExternalId)}], ${nameof(ITimeEntrySetType.deletedWhen)}, ${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}, ${nameof(ITimeEntrySetType.lastUpdatedWhen)}`,

			IntegrationSyncHistory: `${nameof(integrationSyncHistoryModel.SyncHistoryId)}++`,

			TimeSyncHistory: `${nameof(timeSyncHistoryModel.SyncHistoryId)}++`,

			TaskMetadatas: `[${nameof(ITaskMetadataType.integrationGuid)}+${nameof(ITaskMetadataType.externalId)}], ${nameof(
				ITaskMetadataType.integrationGuid
			)}, ${nameof(ITaskMetadataType.isFavorite)}, ${nameof(ITaskMetadataType.usageCount)}`,
		});

		this.version(5).stores({
			TimeEntries: `&${nameof(ITimeEntryType.timeEntryGuid)}, ${nameof(ITimeEntryType.timeEntrySetGuid)}, ${nameof(
				ITimeEntryType.deletedWhen
			)}, ${nameof(ITimeEntryType.lastUpdatedWhen)}, syncedToServer`,

			Tasks: `[${nameof(ITaskType.integrationGuid)}+${nameof(ITaskType.externalId)}], ${nameof(
				ITaskType.integrationGuid
			)}, ${nameof(ITaskType.name)}`,

			Tags: `[${nameof(ITagType.integrationGuid)}+${nameof(ITagType.externalId)}], ${nameof(
				ITagType.integrationGuid
			)}, ${nameof(ITagType.value)}`,

			TaskTagLinks: `[${nameof(ITaskTagLinkType.integrationGuid)}+${nameof(ITaskTagLinkType.taskExternalId)}+${nameof(
				ITaskTagLinkType.tagExternalId
			)}], ${nameof(ITaskTagLinkType.integrationGuid)}`,

			Groups: `&${nameof(ITimeEntrySetType.timeEntrySetGuid)}, [${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}+${nameof(ITimeEntrySetType.taskExternalId)}], ${nameof(ITimeEntrySetType.deletedWhen)}, ${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}, ${nameof(ITimeEntrySetType.lastUpdatedWhen)}, syncedToServer`,

			IntegrationSyncHistory: `${nameof(integrationSyncHistoryModel.SyncHistoryId)}++`,

			TimeSyncHistory: `${nameof(timeSyncHistoryModel.SyncHistoryId)}++`,

			TaskMetadatas: `[${nameof(ITaskMetadataType.integrationGuid)}+${nameof(ITaskMetadataType.externalId)}], ${nameof(
				ITaskMetadataType.integrationGuid
			)}, ${nameof(ITaskMetadataType.isFavorite)}, ${nameof(ITaskMetadataType.usageCount)}`,
		});

		this.version(6).stores({
			TimeEntries: `&${nameof(ITimeEntryType.timeEntryGuid)}, ${nameof(ITimeEntryType.timeEntrySetGuid)}, ${nameof(
				ITimeEntryType.deletedWhen
			)}, ${nameof(ITimeEntryType.lastUpdatedWhen)}, syncedToServer`,

			Tasks: `[${nameof(ITaskType.integrationGuid)}+${nameof(ITaskType.externalId)}], ${nameof(
				ITaskType.integrationGuid
			)}, ${nameof(ITaskType.name)}`,

			Tags: `[${nameof(ITagType.integrationGuid)}+${nameof(ITagType.externalId)}], ${nameof(
				ITagType.integrationGuid
			)}, ${nameof(ITagType.value)}`,

			TaskTagLinks: `[${nameof(ITaskTagLinkType.integrationGuid)}+${nameof(ITaskTagLinkType.taskExternalId)}+${nameof(
				ITaskTagLinkType.tagExternalId
			)}], ${nameof(ITaskTagLinkType.integrationGuid)}`,

			Groups: `&${nameof(ITimeEntrySetType.timeEntrySetGuid)}, [${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}+${nameof(ITimeEntrySetType.taskExternalId)}], ${nameof(ITimeEntrySetType.deletedWhen)}, ${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}, ${nameof(ITimeEntrySetType.lastUpdatedWhen)}, syncedToServer`,

			IntegrationSyncHistory: `${nameof(integrationSyncHistoryModel.SyncHistoryId)}++`,

			TimeSyncHistory: `${nameof(timeSyncHistoryModel.SyncHistoryId)}++`,

			TaskMetadatas: `[${nameof(ITaskMetadataType.integrationGuid)}+${nameof(ITaskMetadataType.externalId)}], ${nameof(
				ITaskMetadataType.integrationGuid
			)}, ${nameof(ITaskMetadataType.isFavorite)}, ${nameof(ITaskMetadataType.usageCount)}, ${nameof(
				ITaskMetadataType.lastUpdatedWhen
			)}, syncedToServer`,
		});

		this.version(7).stores({
			TimeEntries: `&${nameof(ITimeEntryType.timeEntryGuid)}, ${nameof(ITimeEntryType.timeEntrySetGuid)}, ${nameof(
				ITimeEntryType.deletedWhen
			)}, ${nameof(ITimeEntryType.lastUpdatedWhen)}`,

			Tasks: `[${nameof(ITaskType.integrationGuid)}+${nameof(ITaskType.externalId)}], ${nameof(
				ITaskType.integrationGuid
			)}, ${nameof(ITaskType.name)}`,

			Tags: `[${nameof(ITagType.integrationGuid)}+${nameof(ITagType.externalId)}], ${nameof(
				ITagType.integrationGuid
			)}, ${nameof(ITagType.value)}`,

			TaskTagLinks: `[${nameof(ITaskTagLinkType.integrationGuid)}+${nameof(ITaskTagLinkType.taskExternalId)}+${nameof(
				ITaskTagLinkType.tagExternalId
			)}], ${nameof(ITaskTagLinkType.integrationGuid)}`,

			Groups: `&${nameof(ITimeEntrySetType.timeEntrySetGuid)}, [${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}+${nameof(ITimeEntrySetType.taskExternalId)}], ${nameof(ITimeEntrySetType.deletedWhen)}, ${nameof(
				ITimeEntrySetType.taskIntegrationGuid
			)}, ${nameof(ITimeEntrySetType.lastUpdatedWhen)}`,

			IntegrationSyncHistory: `${nameof(integrationSyncHistoryModel.SyncHistoryId)}++`,

			TimeSyncHistory: `${nameof(timeSyncHistoryModel.SyncHistoryId)}++`,

			TaskMetadatas: `[${nameof(ITaskMetadataType.integrationGuid)}+${nameof(ITaskMetadataType.externalId)}], ${nameof(
				ITaskMetadataType.integrationGuid
			)}, ${nameof(ITaskMetadataType.isFavorite)}, ${nameof(ITaskMetadataType.usageCount)}, ${nameof(
				ITaskMetadataType.lastUpdatedWhen
			)}`,
		});

		this.TimeEntries = this.table("TimeEntries");
		this.Tasks = this.table("Tasks");
		this.Tags = this.table("Tags");
		this.TaskTagLinks = this.table("TaskTagLinks");
		this.Groups = this.table("Groups");
		this.IntegrationSyncHistory = this.table("IntegrationSyncHistory");
		this.TimeSyncHistory = this.table("TimeSyncHistory");
		this.TaskMetadatas = this.table("TaskMetadatas");

		TheClaw.Groups.Subscribe({
			callback: (action, key) => this.OnGroupsUpdated(action, key),
			source: nameof(ChronometricDB),
			allCauses: true,
		});
		TheClaw.Tasks.Subscribe({
			callback: (action, key) => this.OnTasksUpdated(action, key),
			source: nameof(ChronometricDB),
			allCauses: true,
		});
		TheClaw.Tags.Subscribe({
			callback: (action, key) => this.OnTagsUpdated(action, key),
			source: nameof(ChronometricDB),
			allCauses: true,
		});
		TheClaw.TaskTagLinks.Subscribe({
			callback: (action, key) => this.OnTaskTagLinksUpdated(action, key),
			source: nameof(ChronometricDB),
			allCauses: true,
		});
		TheClaw.TimeEntries.Subscribe({
			callback: (action, key) => this.OnTimeEntriesUpdated(action, key),
			source: nameof(ChronometricDB),
			allCauses: true,
		});
		TheClaw.TaskMetadatas.Subscribe({
			callback: (action, key) => this.OnTaskMetadatasUpdated(action, key),
			source: nameof(ChronometricDB),
			allCauses: true,
		});

		console.debug("Loading subscribables from DB");
		this.loadSubscribablesFromDb().then(() => {
			console.debug("DB ready");
			this.isReady = true;
			window.dispatchEvent(new Event("dbready"));
		});
	}

	public async loadSubscribablesFromDb() {
		await Promise.all([
			this.Tasks.toArray().then((tasks) => {
				TheClaw.Tasks.RemoveAll([nameof(ChronometricDB), nameof(SyncManager)]);
				TheClaw.Tasks.BulkSet(
					tasks.reduce((acc, curr) => {
						const newLocal = this.asSubscribableTask(curr);
						return acc.set(KeyHelper.GetTaskKey(newLocal), newLocal);
					}, Map<string, ITask>()),
					[nameof(ChronometricDB), nameof(SyncManager)],
					true
				);
			}),
			this.Tags.toArray().then((tags) => {
				TheClaw.Tags.RemoveAll([nameof(ChronometricDB), nameof(SyncManager)]);
				TheClaw.Tags.BulkSet(
					tags.reduce((acc, curr) => {
						const newLocal = this.asSubscribableTag(curr);
						return acc.set(KeyHelper.GetTagKey(newLocal), newLocal);
					}, Map<string, ITag>()),
					[nameof(ChronometricDB), nameof(SyncManager)],
					true
				);
			}),
			this.TaskTagLinks.toArray().then((tags) => {
				TheClaw.TaskTagLinks.RemoveAll([nameof(ChronometricDB), nameof(SyncManager)]);
				TheClaw.TaskTagLinks.BulkSet(
					tags.reduce(
						(acc, curr) => {
							return acc.set(KeyHelper.GetTaskTagLinkKey(curr), curr);
						},

						Map<string, ITaskTagLink>()
					),
					[nameof(ChronometricDB), nameof(SyncManager)],
					true
				);
			}),
			this.TimeEntries.toArray().then((tasks) => {
				TheClaw.TimeEntries.RemoveAll([nameof(ChronometricDB), nameof(SyncManager)]);
				TheClaw.TimeEntries.BulkSet(
					tasks
						.filter((task) => !task.deletedWhen)
						.reduce((acc, curr) => {
							const newLocal = this.storableToSubscribableTimeEntry(curr);
							return acc.set(curr.timeEntryGuid, newLocal);
						}, Map<string, ITimeEntry>()),
					[nameof(ChronometricDB), nameof(SyncManager)],
					true
				);
			}),
			this.Groups.toArray().then((tasks) => {
				TheClaw.Groups.RemoveAll([nameof(ChronometricDB), nameof(SyncManager)]);
				TheClaw.Groups.BulkSet(
					tasks
						.filter((task) => !task.deletedWhen)
						.reduce((acc, curr) => {
							const newLocal = this.asSubscribableTimeEntrySet(curr);
							return acc.set(curr.timeEntrySetGuid, newLocal);
						}, Map<string, ITimeEntrySet>()),
					[nameof(ChronometricDB), nameof(SyncManager)],
					true
				);
			}),
			this.TaskMetadatas.toArray().then((tasks) => {
				TheClaw.TaskMetadatas.RemoveAll([nameof(ChronometricDB), nameof(SyncManager)]);
				TheClaw.TaskMetadatas.BulkSet(
					tasks.reduce((acc, curr) => {
						const newLocal = this.asSubscribableTaskMetadata(curr);
						return acc.set(KeyHelper.GetTaskKey(newLocal), newLocal);
					}, Map<string, ITaskMetadata>()),
					[nameof(ChronometricDB), nameof(SyncManager)],
					true
				);
			}),
		]);
	}

	// tslint:disable-next-line: member-ordering
	public async Ready() {
		if (this.isReady) {
			return;
		}
		await this.Pause(100);
		await this.Ready();
	}

	private Pause(timeout: number) {
		return new Promise((resolve) => {
			setTimeout(resolve, timeout);
		});
	}

	private asStorableTimeEntry(item: ITimeEntry): ITimeEntryStorable {
		return {
			...item,
			startedWhen: item.startedWhen.toJSDate(),
			endedWhen: item.endedWhen?.toJSDate(),
			deletedWhen: item.deletedWhen?.toJSDate(),
			lastUpdatedWhen: item.lastUpdatedWhen.toJSDate(),
			lastSyncedWhen: item.lastSyncedWhen?.toJSDate(),
		};
	}
	private storableToSubscribableTimeEntry(item: ITimeEntryStorable): ITimeEntry {
		return {
			...item,
			startedWhen: DateTime.fromJSDate(item.startedWhen),
			lastUpdatedWhen: Instant.fromJSDate(item.lastUpdatedWhen),
			endedWhen: item.endedWhen && DateTime.fromJSDate(item.endedWhen),
			deletedWhen: item.deletedWhen && DateTime.fromJSDate(item.deletedWhen),
			lastSyncedWhen: item.lastSyncedWhen && Instant.fromJSDate(item.lastSyncedWhen),
		};
	}

	private asStorableTimeEntrySet(item: ITimeEntrySet): ITimeEntrySetStorable {
		return {
			...item,
			createdWhen: item.createdWhen.toJSDate(),
			lastExportErrorOccurredWhen: item.lastExportErrorOccurredWhen?.toJSDate(),
			deletedWhen: item.deletedWhen?.toJSDate(),
			lastUpdatedWhen: item.lastUpdatedWhen.toJSDate(),
			lastSyncedWhen: item.lastSyncedWhen?.toJSDate(),
			lastExportedWhen: item.lastExportedWhen?.toJSDate(),
			queuedForExportWhen: item.queuedForExportWhen?.toJSDate(),
		};
	}
	private asSubscribableTimeEntrySet(item: ITimeEntrySetStorable): ITimeEntrySet {
		return {
			...item,
			createdWhen: Instant.fromJSDate(item.createdWhen),
			lastUpdatedWhen: Instant.fromJSDate(item.lastUpdatedWhen),
			deletedWhen: item.deletedWhen && Instant.fromJSDate(item.deletedWhen),
			lastSyncedWhen: item.lastSyncedWhen && Instant.fromJSDate(item.lastSyncedWhen),
			lastExportedWhen: item.lastExportedWhen && Instant.fromJSDate(item.lastExportedWhen),
			queuedForExportWhen: item.queuedForExportWhen && Instant.fromJSDate(item.queuedForExportWhen),
			lastExportErrorOccurredWhen:
				item.lastExportErrorOccurredWhen && Instant.fromJSDate(item.lastExportErrorOccurredWhen),
		};
	}

	private asStorableTaskMetadata(item: ITaskMetadata): ITaskMetadataStorable {
		return {
			...item,
			createdWhen: item.createdWhen?.toJSDate(),
			lastUpdatedWhen: item.lastUpdatedWhen?.toJSDate(),
			usageCountCalculatedWhen: item.usageCountCalculatedWhen?.toJSDate(),
		};
	}
	private asSubscribableTaskMetadata(item: ITaskMetadataStorable): ITaskMetadata {
		return {
			...item,
			createdWhen: item.createdWhen && Instant.fromJSDate(item.createdWhen),
			lastUpdatedWhen: item.lastUpdatedWhen && Instant.fromJSDate(item.lastUpdatedWhen),
			usageCountCalculatedWhen: item.usageCountCalculatedWhen && Instant.fromJSDate(item.usageCountCalculatedWhen),
		};
	}

	private asStorableTask(item: ITask): ITaskStorable {
		return {
			...item,
			createdWhen: item.createdWhen.toJSDate(),
			lastUpdatedWhen: item.lastUpdatedWhen.toJSDate(),
			dueWhen: item.dueWhen?.toJSDate(),
			externalCreatedWhen: item.externalCreatedWhen.toJSDate(),
		};
	}
	private asSubscribableTask(item: ITaskStorable): ITask {
		return {
			...item,
			createdWhen: Instant.fromJSDate(item.createdWhen),
			lastUpdatedWhen: Instant.fromJSDate(item.lastUpdatedWhen),
			dueWhen: item.dueWhen && Instant.fromJSDate(item.dueWhen),
			externalCreatedWhen: Instant.fromJSDate(item.externalCreatedWhen),
		};
	}

	private asStorableTag(item: ITag): ITagStorable {
		return {
			...item,
			createdWhen: item.createdWhen.toJSDate(),
			lastUpdatedWhen: item.lastUpdatedWhen?.toJSDate(),
		};
	}
	private asSubscribableTag(item: ITagStorable): ITag {
		return {
			...item,
			createdWhen: Instant.fromJSDate(item.createdWhen),
			lastUpdatedWhen: Instant.fromJSDate(item.lastUpdatedWhen),
		};
	}

	private asStorableTaskTagLink(item: ITaskTagLink): ITaskTagLinkStorable {
		return item;
	}

	private isTimeEntry(item: any): item is ITimeEntry {
		if (item && nameof(ITimeEntryType.timeEntryGuid) in item) {
			return true;
		}
		return false;
	}
	private isTimeEntrySet(item: any): item is ITimeEntrySet {
		if (item && nameof(ITimeEntrySetType.timeEntrySetGuid) in item && nameof(ITimeEntrySetType.userId) in item) {
			return true;
		}
		return false;
	}
	private isTask(item: any): item is ITask {
		if (
			item &&
			nameof(ITaskType.integrationGuid) in item &&
			nameof(ITaskType.externalId) in item &&
			nameof(ITaskType.linkUrl) in item
		) {
			return true;
		}
		return false;
	}
	private isTaskMetadata(item: any): item is ITaskMetadata {
		if (
			item &&
			nameof(ITaskMetadataType.integrationGuid) in item &&
			nameof(ITaskMetadataType.externalId) in item &&
			nameof(ITaskMetadataType.usageCount) in item
		) {
			return true;
		}
		return false;
	}
	private isTaskTagLink(item: any): item is ITaskTagLink {
		if (
			item &&
			nameof(ITaskTagLinkType.integrationGuid) in item &&
			nameof(ITaskTagLinkType.tagExternalId) in item &&
			nameof(ITaskTagLinkType.taskExternalId) in item
		) {
			return true;
		}
		return false;
	}
	private isTag(item: any): item is ITag {
		if (
			item &&
			nameof(ITagType.integrationGuid) in item &&
			nameof(ITagType.externalId) in item &&
			nameof(ITagType.tagTypeCodeName) in item
		) {
			return true;
		}
		return false;
	}

	private async Apply<TDbModel, TSubsModel, TDbKey extends string | string[]>(
		table: Dexie.Table<TDbModel, TDbKey>,
		subscribable: SubscribableCollection<TSubsModel>,
		action: DataActionEnum,
		keys: Set<string>,
		converter: (item: TSubsModel) => TDbModel
	) {
		// console.debug(`Performing ${action} on table ${table.name}. Keys:`, keys);

		if (action !== DataActionEnum.BulkSet) {
			if (keys.count() !== 1) {
				throw Error("Single key required for all notifications but BulkAdd");
			}
		} else {
			if (keys.count() <= 0) {
				throw Error("Keys required for BulkAdd");
			}
		}

		const firstKey = keys.first(undefined)!;

		switch (action) {
			case DataActionEnum.Create:
				const newItem = subscribable.Get(firstKey);
				if (!newItem) throw Error(`Unable to load item with key '${firstKey}' to add to DB`);
				await table.add(converter(newItem));
				break;

			case DataActionEnum.Update:
				const item = subscribable.Get(firstKey);
				if (!item) throw Error(`Unable to load item with key '${firstKey}' to update in DB`);
				await table.put(converter(item));

				break;
			case DataActionEnum.Delete:
				await table.delete(this.GetDbKey<TDbKey>(firstKey));
				break;
			case DataActionEnum.BulkSet:
				const toBulkPut = keys.map((key) => {
					const item = subscribable.Get(key);

					if (!item) throw Error(`Unable to load item with key '${key}' to update in DB (BulkSet)`);

					return converter(item);
				});
				await table.bulkPut(toBulkPut.toArray());
				break;
		}
	}

	private GetDbKey<TDbKey extends string | string[]>(key?: string) {
		let dbKey: string | string[] = key!;
		if (dbKey.includes("|")) {
			dbKey = dbKey.split("|");
		}
		return dbKey as TDbKey;
	}

	private OnTimeEntriesUpdated(action: DataActionEnum, key: Set<Guid>) {
		this.Apply(this.TimeEntries, TheClaw.TimeEntries, action, key, this.asStorableTimeEntry);
	}

	private OnGroupsUpdated(action: DataActionEnum, keys: Set<Guid>) {
		this.Apply(this.Groups, TheClaw.Groups, action, keys, this.asStorableTimeEntrySet);
	}

	private OnTasksUpdated(action: DataActionEnum, key: Set<string>) {
		this.Apply(this.Tasks, TheClaw.Tasks, action, key, this.asStorableTask);
	}

	private OnTagsUpdated(action: DataActionEnum, key: Set<string>) {
		this.Apply(this.Tags, TheClaw.Tags, action, key, this.asStorableTag);
	}

	private OnTaskTagLinksUpdated(action: DataActionEnum, key: Set<string>) {
		this.Apply(this.TaskTagLinks, TheClaw.TaskTagLinks, action, key, this.asStorableTaskTagLink);
	}

	private OnTaskMetadatasUpdated(action: DataActionEnum, key: Set<string>) {
		this.Apply(this.TaskMetadatas, TheClaw.TaskMetadatas, action, key, this.asStorableTaskMetadata);
	}
}
