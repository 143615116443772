import React, { useContext, useRef, useEffect } from "react";
import { PlayheadContextModel } from "./PlayheadContextModel";
import { PlayheadContextAction } from "./PlayheadContextAction";
import { PlayheadContextDispatchActionType } from "./PlayheadContextDispatchActionType";
import { PlayheadContextProviderProps } from "./PlayheadContext.Interface";
import { DateTime, Duration } from "luxon";
import { TheClaw } from "../../Data/TheClaw";
import { TimelineContext } from "../TimelineContext/TimelineContext";
import { useMidnight } from "../../Hooks/useMidnight";
import { useSyncDoneEvent } from "../../Hooks/useSyncDoneEvent";
import { useTimeEntryUpdateEvent } from "../../Hooks/useTimeEntryUpdateEvent";
import { useTimeEntrySetUpdateEvent } from "../../Hooks/useTimeEntrySetUpdateEvent";
import nameof from "ts-nameof.macro";
import { throttle } from "lodash";

export const PlayheadContext = React.createContext({
	state: new PlayheadContextModel(),
	dispatch: {} as React.Dispatch<PlayheadContextAction>,
});

function PlayheadContextReducer(state: PlayheadContextModel, action: PlayheadContextAction) {
	switch (action.type) {
		case PlayheadContextDispatchActionType.updateLastSwitchTime:
			return {
				...state,
				lastSwitchTime: DateTime.local(),
			};
		case PlayheadContextDispatchActionType.updateCalculatedData:
			if (state.lastUpdate && state.lastUpdate.diffNow() > Duration.fromMillis(500)) {
				return state;
			}

			const runningEntry = TheClaw.TimeEntries.GetTodaysLatestEntry(action.payload.timelineStartTimeOffsetHours || 0);
			const runningTimeEntrySet = runningEntry ? TheClaw.Groups.Get(runningEntry.timeEntrySetGuid) : undefined;

			const newCurrentTimeEntryGUID =
				runningTimeEntrySet &&
				runningEntry &&
				(!runningTimeEntrySet.queuedForExportWhen ||
					runningTimeEntrySet.queuedForExportWhen < runningTimeEntrySet.lastUpdatedWhen)
					? runningEntry.timeEntryGuid
					: undefined;

			const newCurrentTimeEntrySetGUID =
				runningTimeEntrySet &&
				runningEntry &&
				(!runningTimeEntrySet.queuedForExportWhen ||
					runningTimeEntrySet.queuedForExportWhen < runningTimeEntrySet.lastUpdatedWhen)
					? runningEntry.timeEntrySetGuid
					: undefined;

			const newIsPlaying = !!runningEntry && !!runningTimeEntrySet && !runningEntry.endedWhen;

			return {
				...state,
				lastUpdate: DateTime.local(),
				isPlaying: newIsPlaying,
				currentTimeEntryGUID: newCurrentTimeEntryGUID,
				currentTimeEntrySetGUID: newCurrentTimeEntrySetGUID,
			};
		default:
			return { ...state };
	}
}

export const PlayheadContextProvider = (props: PlayheadContextProviderProps) => {
	const [state, dispatch] = React.useReducer(PlayheadContextReducer, new PlayheadContextModel());
	const value = { state, dispatch };

	const timelineContext = useContext(TimelineContext);

	const getUpdateCallback = () => () =>
		dispatch({
			type: PlayheadContextDispatchActionType.updateCalculatedData,
			payload: timelineContext.state.timelineStartTimeOffsetHours,
		});

	const updateCallbackRef = useRef(getUpdateCallback());

	useEffect(() => {
		updateCallbackRef.current = getUpdateCallback();
	}, [timelineContext.state.timelineStartTimeOffsetHours]);

	useMidnight(updateCallbackRef.current, timelineContext.state.timelineStartTimeOffsetHours);
	useSyncDoneEvent(updateCallbackRef.current);
	useTimeEntryUpdateEvent(nameof(PlayheadContext), updateCallbackRef.current);
	useTimeEntrySetUpdateEvent(nameof(PlayheadContext), updateCallbackRef.current);

	return <PlayheadContext.Provider value={value}>{props.children}</PlayheadContext.Provider>;
};
