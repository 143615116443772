import React, { useState, useEffect, useContext } from "react";
import { ExportViewHeaderProps } from "./ExportViewHeader.Interface";
import "./ExportViewHeader.scss";
import { ExportContext } from "../../../Context/ExportContext/ExportContext";

enum howManyRecordsSelected {
	all = "ALL",
	some = "SOME",
	none = "NONE",
}

export const ExportViewHeader = (props: ExportViewHeaderProps) => {
	const exportContext = useContext(ExportContext);
	const [entriesSelectedState, setEntriesSelectedState] = useState<howManyRecordsSelected>(howManyRecordsSelected.none);

	useEffect(() => {
		if (exportContext.state.selectedExportRecords.size > 0) {
			if (exportContext.state.selectedExportRecords.size >= props.groupGuids.size) {
				setEntriesSelectedState(howManyRecordsSelected.all);
			} else {
				setEntriesSelectedState(howManyRecordsSelected.some);
			}
		} else {
			setEntriesSelectedState(howManyRecordsSelected.none);
		}
	}, [exportContext.state.selectedExportRecords]);

	function ToggleSelectAll() {
		if (entriesSelectedState === howManyRecordsSelected.none) {
			props.SelectAll();
			setEntriesSelectedState(howManyRecordsSelected.all);
		} else {
			props.UnselectAll();
			setEntriesSelectedState(howManyRecordsSelected.none);
		}
	}

	return (
		<section className={"export-page-header "}>
			<input
				className="select-all"
				type="checkbox"
				checked={entriesSelectedState !== howManyRecordsSelected.none}
				onChange={ToggleSelectAll}
				disabled={props.exporting || props.groupGuids.size === 0}
			/>
			<div className="select-all-label">
				{entriesSelectedState !== howManyRecordsSelected.none ? "Unselect All" : "Select All"}
			</div>
		</section>
	);
};
