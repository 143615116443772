import { Source } from "../Data/Source";
import { TheClaw } from "../Data/TheClaw";
import { IUseSubscribableCollectionType, useSubscribableCollection } from "./useSubscribable";
import { ITag } from "../Data/Models/ITag";
export interface IUseTags extends IUseSubscribableCollectionType<ITag> {}
/**
 *
 * Causes re-render when any item in Tasks changes
 * @param source
 */

export enum JiraTagTypeCodeNames {
	project = "PROJECT",
	customer = "CUSTOMER", // Not sure if this one is correct
	fixver = "FIXVER",
}

export function useTags(source?: Source) {
	const subscribable = useSubscribableCollection(TheClaw.Tags, source);
	return {
		...subscribable,
	};
}
