import { DateTime } from "luxon";

export class PlayheadContextModel {
	lastSwitchTime?: DateTime;
	isPlaying = false;
	currentTimeEntryGUID?: string;
	currentTimeEntrySetGUID?: string;
	lastUpdate?: DateTime;

	constructor(mcm?: Partial<PlayheadContextModel>) {
		Object.assign(this, mcm);
	}
}
