import React from "react";
import "./LiveTimeLabel.scss";
import { LiveTimeLabelForSetsProps } from "./LiveTimeLabelForSets.Interface";
import nameof from "ts-nameof.macro";
import { useGroupTime } from "./useGroupTime";

export const LiveTimeLabelForSets = (props: LiveTimeLabelForSetsProps) => {
	const { totalTime, timeUnitIndicator } = useGroupTime(
		props.timeEntrySetGuid,
		props.groupsDayOffset,
		nameof(LiveTimeLabelForSets)
	);

	return (
		<>
			<span className="total-time">{totalTime}</span>
			<span className="time-unit-indicator">{timeUnitIndicator}</span>
		</>
	);
};
