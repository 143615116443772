import React, { useContext, useEffect, useRef } from "react";
import "./EditModeTimeline.scss";
import { TimeEntryRepeater } from "../../Repeaters/TimeEntryRepeater/TimeEntryRepeater";
import { Timescale } from "../../Components/Library/Timescale/Timescale";
import { EditModeContext } from "../../Context/EditModeContext/EditModeContext";
import {
	TimelineContext,
	defaultScale,
	IncreaseScaleByStep,
	DecreaseScaleByStep,
} from "../../Context/TimelineContext/TimelineContext";
import { TimelineContextDispatchActionType } from "../../Context/TimelineContext/TimelineContextDispatchActionType";
import { DateTime } from "luxon";
import nameof from "ts-nameof.macro";
import { HistoryModel } from "../../Hooks/useHistory/HistoryModel";
import { useHistory } from "../../Hooks/useHistory/useHistory";
import { TheHook } from "../../Hooks/TheHook/TheHook";
import { ITimeEntry } from "../../Data/Models/ITimeEntry";
import { ViewContext } from "../../Context/ViewContext/ViewContext";
import { ViewContextDispatchActionType } from "../../Context/ViewContext/ViewContextDispatchActionType";
import { ModalContext } from "../../Context/ModalContext/ModalContext";
import { ModalContextDispatchActionType } from "../../Context/ModalContext/ModalContextDispatchActionType";
import { ContextMenu, useContextMenuParams } from "../../Components/Library/ContextMenu/ContextMenu";
import { GetRelativeClickCoordinates } from "../../GlobalUtils/PositionUtils";
import { CalcTimePosition, CalcPositionTime } from "../../GlobalUtils/TimeUtils";
import { InstanceManager } from "../../Data/InstanceManager";

export function EditModeTimeline() {
	const editModeContext = useContext(EditModeContext);
	const timelineContext = useContext(TimelineContext);
	const viewContext = useContext(ViewContext);
	const modalContext = useContext(ModalContext);
	const history: HistoryModel = useHistory(nameof(EditModeTimeline));
	const theHook = TheHook(nameof(EditModeTimeline), true);

	const scrollRef = useRef<HTMLElement>(null);

	useEffect(() => {
		theHook.SetupEditMode();

		// If this is the modal version of the edit timeline, set a custom close function
		if (modalContext.state.showModal) {
			modalContext.dispatch({
				type: ModalContextDispatchActionType.SetCustomCloseCallback,
				payload: CancelEditMode,
			});
		}
	}, []);

	useEffect(() => {
		// Update the values used in CancelEditMode
		modalContext.dispatch({
			type: ModalContextDispatchActionType.SetCustomCloseCallback,
			payload: CancelEditMode,
		});
	}, [history.canUndo, history.canRedo]);

	useEffect(() => {
		if (timelineContext.state.ScrollToDate) {
			ScrollToDate(timelineContext.state.ScrollToDate);
		} else if (timelineContext.state.ScrollToTimeEntry) {
			ScrollToTimeEntry(timelineContext.state.ScrollToTimeEntry);
		} else {
			ScrollToBottom();
		}
	}, [timelineContext.state.ScrollToDate, timelineContext.state.ScrollToTimeEntry]);

	function ScrollToBottom() {
		if (scrollRef && scrollRef.current) {
			scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
		}
	}

	function ScrollToSetHeight(height: number) {
		if (scrollRef && scrollRef.current) {
			let offset = 0;
			if (scrollRef.current.getBoundingClientRect) {
				offset = scrollRef.current.getBoundingClientRect().height / 2;
			}

			scrollRef.current.scrollTop = height - offset;
		}
	}

	function ScrollToDate(date: DateTime) {
		const height = CalcTimePosition(
			date,
			timelineContext.state.timelineStartTimeOffsetHours,
			timelineContext.state.currentScale,
			timelineContext.state.currentDayOffset
		);
		ScrollToSetHeight(height);
	}

	function ScrollToTimeEntry(timeEntry: ITimeEntry) {
		console.log("scroll to time entry ");

		const endTime = timeEntry.endedWhen ? timeEntry.endedWhen : InstanceManager.timeSource.GetLocalTime();
		const midTime = endTime.minus({
			seconds: endTime.diff(timeEntry.startedWhen, "seconds").seconds / 2,
		});

		const height = CalcTimePosition(
			midTime,
			timelineContext.state.timelineStartTimeOffsetHours,
			timelineContext.state.currentScale,
			timelineContext.state.currentDayOffset
		);

		ScrollToSetHeight(height);
	}

	function IncreaseScale() {
		const newScale = IncreaseScaleByStep(timelineContext.state.currentScale);
		ChangeScale(newScale);
	}

	// `tempScale` purely serves as a way of modifying the scale inside the current
	// render. Otherwise we have to wait for the context update, which is asynchronous
	// and there's no way of knowing when that'll complete
	let tempScale = timelineContext.state.currentScale;

	function ChangeScale(newScale: number) {
		if (!scrollRef.current) throw new Error("scrollRef not available");
		const middleHeight = scrollRef.current.offsetHeight / 2;
		const middlePos = scrollRef.current.scrollTop + middleHeight;
		const scrolledToTime = CalcPositionTime(
			middlePos,
			timelineContext.state.timelineStartTimeOffsetHours,
			timelineContext.state.currentScale,
			timelineContext.state.currentDayOffset
		);

		const newScrollTop =
			CalcTimePosition(
				scrolledToTime,
				timelineContext.state.timelineStartTimeOffsetHours,
				newScale,
				timelineContext.state.currentDayOffset
			) - middleHeight;

		// If we can't scroll to the position we want to because the scroll area isn't tall enough yet,
		// we'll have to wait for the render to complete before setting the new scrollTop
		if (newScrollTop + scrollRef.current.offsetHeight < scrollRef.current.scrollHeight)
			scrollRef.current.scrollTop = newScrollTop;
		else
			setImmediate(() => {
				if (scrollRef.current) scrollRef.current.scrollTop = newScrollTop;
			});

		tempScale = newScale;
		timelineContext.dispatch({
			type: TimelineContextDispatchActionType.SetCurrentScale,
			payload: newScale,
		});
	}

	function DecreaseScale() {
		const newScale = DecreaseScaleByStep(timelineContext.state.currentScale);
		ChangeScale(newScale);
	}

	const { contextMenuParams, setMenuCoords, setMenuOptions, setShowContextMenu } = useContextMenuParams();

	function HandleContextMenu(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		e.preventDefault();
		e.stopPropagation();
		if (!scrollRef.current) return;
		const _menuCoords = GetRelativeClickCoordinates(e.nativeEvent, scrollRef.current);

		setMenuOptions([{ display: "Insert time entry", callback: () => handleInsertTimeEntry(_menuCoords.y) }]);

		setMenuCoords(_menuCoords);
		setShowContextMenu(true);
	}

	// function EnterEditMode() {
	// 	theHook.SetupEditMode();
	// }

	function handleInsertTimeEntry(yPosition: number) {
		const timeForPos = CalcPositionTime(
			yPosition,
			timelineContext.state.currentDayOffset || 0,
			timelineContext.state.currentScale || 1,
			timelineContext.state.currentDayOffset
		);
		console.debug("Adding new time entry", yPosition, timeForPos);
		theHook.InsertNewTimeEntry(timeForPos, timeForPos.plus({ minutes: 10 }));
	}

	function CancelEditMode() {
		if ((!history.canRedo && !history.canUndo) || window.confirm("Leave edit mode without saving changes?")) {
			theHook.CancelEditMode();
			Cleanup();

			return true;
		}
		return false;
	}

	function SaveEditMode() {
		theHook.SaveEditMode();

		Cleanup();
	}

	function CurrentDay() {
		let currentDay = "TODAY";

		if (editModeContext.state.editModeDayOffset !== 0) {
			if (editModeContext.state.editModeDayOffset === 1) {
				currentDay = "YESTERDAY";
			} else {
				currentDay = InstanceManager.timeSource
					.GetLocalTime()
					.minus({ days: editModeContext.state.editModeDayOffset })
					.toLocaleString(DateTime.DATE_MED);
			}
		}

		return currentDay;
	}

	function Cleanup() {
		timelineContext.dispatch({
			type: TimelineContextDispatchActionType.ScrollToTimeEntry,
			payload: undefined,
		});

		timelineContext.dispatch({
			type: TimelineContextDispatchActionType.ScrollToDate,
			payload: undefined,
		});

		if (modalContext.state.showModal) {
			modalContext.dispatch({
				type: ModalContextDispatchActionType.SetCustomCloseCallback,
				payload: undefined,
			});
			modalContext.dispatch({
				type: ModalContextDispatchActionType.CloseModal,
				payload: undefined,
			});
		} else {
			// If this is the edit timeline view then open to the comments view on close
			viewContext.dispatch({
				type: ViewContextDispatchActionType.CommentsView,
			});
		}
	}

	return (
		<section id="timeline-editor-wrapper">
			<div className="timeline-editor-container">
				<section className="timeline-header-wrapper">
					<div className="timeline-zoom-controller-wrapper">
						<button aria-label="Zoom out" onClick={DecreaseScale} className="pill sz-2 zoom-out" />
						<span className="zoom-scale">{(tempScale / defaultScale) * 100 + "%"}</span>
						<button aria-label="Zoom in" onClick={IncreaseScale} className="pill sz-2 zoom-in" />
					</div>
					<div className="timeline-date-calander">
						<h2>{CurrentDay()}</h2>
					</div>
				</section>

				<section className="timeline-content-wrapper mode-timeline-default" ref={scrollRef}>
					<div className="timeline-content-container">
						<TimeEntryRepeater
							HandleContextMenu={HandleContextMenu}
							// LinkGroupToActiveTask={props.LinkGroupToActiveTask}
							// GroupUnlink={props.GroupUnlink}
							scrollRef={scrollRef.current!}
						/>
						{/* <TreeLines currentScrollY={currentScrollY} /> */}
						<Timescale />
						{ContextMenu(contextMenuParams)}
					</div>
				</section>

				<section className="timeline-footer-wrapper">
					<div className="timeline-undoRedo-controller-wrapper">
						<button aria-label="Undo" onClick={history.undo} disabled={!history.canUndo} className="undo pill sz-2">
							<span className="icon" aria-hidden="true" />
							<span className="label">Undo</span>
						</button>
						<button aria-label="Redo" onClick={history.redo} disabled={!history.canRedo} className="redo pill sz-2">
							<span className="icon" aria-hidden="true" />
							<span className="label">Redo</span>
						</button>
					</div>
					<button
						aria-label="Save changes and close"
						onClick={SaveEditMode}
						className="save-edit-mode-button pill sz-2"
						disabled={!history.canUndo && !history.canRedo}
					>
						<span className="icon" aria-hidden="true" />
						<span className="label">Save & close</span>
					</button>
				</section>
			</div>
		</section>
	);
}
