import React from "react";
import "../GroupIndicators/group-indicators.scss";
import "./Playhead.scss";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import { Timer } from "../Timer/Timer";
import nameof from "ts-nameof.macro";
import { useGroups } from "../../../Hooks/useGroups";
import { TheHookModel } from "../../../Hooks/TheHook/TheHookModel";
import { TheHook } from "../../../Hooks/TheHook/TheHook";
import { useTimeControls } from "../../../Hooks/useTimeControls";
import { GroupNameLabel } from "../GroupNameLabel/GroupNameLabel";
import { useTimerCalulatedData } from "../../../Hooks/useTimerCalulatedData";
import { useCalculatedTimes } from "../../../Hooks/useCalculatedTimes";

enum PlayingStatus {
	isPlaying = " is-playing",
	notPlaying = " not-playing",
}

export const Playhead = () => {
	const TimeEntries = useTimeEntries(nameof(Playhead));
	const Groups = useGroups(nameof(Playhead));
	const theHook: TheHookModel = TheHook(nameof(Playhead));
	const timeControls = useTimeControls(nameof(Playhead));

	const { isPlaying, currentTimeEntryGUID, currentTimeEntrySetGUID } = useTimerCalulatedData(nameof(Playhead));
	const group = currentTimeEntrySetGUID ? Groups.Get(currentTimeEntrySetGUID) : undefined;
	const dayStarted = !!currentTimeEntryGUID;
	const { groupTotalTime } = useCalculatedTimes(nameof(Playhead));

	function OpenEditModeTimeline() {
		if (currentTimeEntryGUID) {
			const currentTimeEntry = TimeEntries.Get(currentTimeEntryGUID);
			theHook.OpenEditTimeline(0, currentTimeEntry);
		}
	}

	return (
		<div className={"playhead-container "}>
			<div className="timer-controls-wrapper">
				<div className="timer-controls-container">
					{/* EDIT BUTTON */}
					<button
						className={"edit circle icon-only"}
						onClick={OpenEditModeTimeline}
						aria-label="Edit time entry"
						title="Edit time entry"
						disabled={!dayStarted} // Should this be diabled if there is no active time entry?
					></button>

					{/* PLAY/PAUSE BUTTON */}
					<button
						onClick={() => timeControls.TogglePlay()}
						className={"play circle icon-only" + (isPlaying ? PlayingStatus.isPlaying : PlayingStatus.notPlaying)}
						aria-label={isPlaying ? "Pause task" : "Play task"}
						title={isPlaying ? "Pause task" : "Play task"}
					/>

					{/* TIME SWITCH BUTTON */}
					<button
						onClick={() => timeControls.SwitchTo()}
						className={"switch circle icon-only"}
						aria-label="Switch to new task"
						title="Switch to new task"
						disabled={!dayStarted}
					/>
				</div>
			</div>

			<div className="playhead-info-wrapper">
				<div className={"playhead-task-info"}>
					{/* <button
						className={"playhead-group-indicator group-indicator group-indicator-" + GetGroupIndicator().colour}
						onClick={SetModalGroup}
						disabled={editModeContext.state.editModeStatus}
					/> */}
					<div className="task-name">
						<GroupNameLabel group={group} />
					</div>
				</div>
				<Timer timeToDisplay={groupTotalTime} className={"playhead-timer"} />
			</div>
		</div>
	);
};
