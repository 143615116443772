export class ModalContextModel {
	modalComponent?: JSX.Element | JSX.Element[];
	showModal?: boolean;
	preventClose?: boolean;
	customCloseCallback?: () => boolean; // Checks for return true to account for checks before closing modal
	customClassName?: string;
	constructor(mcm?: Partial<ModalContextModel>) {
		Object.assign(this, mcm);
	}
}
