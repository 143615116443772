import React, { useContext } from "react";
import { TasklistProps } from "./Tasklist.Interface";
import "./Tasklist.scss";
import { TaskRepeater } from "../../Repeaters/TaskRepeater/TaskRepeater";
import { TaskSearch } from "../../Components/Library/TaskSearch/TaskSearch";
import { SearchContext } from "../../Context/SearchContext/SearchContext";

export function Tasklist(props: TasklistProps) {
	const searchContext = useContext(SearchContext);

	return (
		<section id="search-list-wrapper">
			<section id="search-list-container" className={props.modalVersion ? "modal-version " : ""}>
				<TaskSearch />
				<section className="task-list-wrapper">
					<TaskRepeater
						modalVersion={props.modalVersion}
						timeEntrySetGuid={props.timeEntrySetGuid}
						linkingTimeEntries={props.linkingTimeEntries}
						isSearching={searchContext.state.isSearching}
					/>
				</section>
			</section>
		</section>
	);
}
