import React, { useContext } from "react";
import "./CommentsPage.scss";
import nameof from "ts-nameof.macro";
import { GroupSummary } from "../../Components/Library/GroupSummary/GroupSummary";
import { TimelineContext, timelineNotesSortModeEnum } from "../../Context/TimelineContext/TimelineContext";
import { GroupNotesDropdown } from "../../Components/Library/GroupNotesDropdown/GroupNotesDropdown";
import { TheHook } from "../../Hooks/TheHook/TheHook";

export function CommentsPage() {
	const theHook = TheHook(nameof(CommentsPage));
	const timelineContext = useContext(TimelineContext);

	return (
		<section id="comments-view-content-wrapper">
			<section className="timeline-notes-wrapper mode-timeline-default">
				<section className="timeline-notes-container">
					{timelineContext.state.timelineSortMode === timelineNotesSortModeEnum.sortByTime ? (
						<section className="orderby-time-wrapper">
							<GroupSummary DayOffset={timelineContext.state.currentDayOffset} />
						</section>
					) : (
						// TODO: Sort groups so they come out in alphabetical or chonological order
						<section className="orderby-group-wrapper">
							{theHook.GetOffestDaysGroups(timelineContext.state.currentDayOffset).map((group) => {
								return (
									<GroupNotesDropdown
										key={"group-summary-dropdown-" + group.timeEntrySetGuid}
										myGroup={group}
										DayOffset={timelineContext.state.currentDayOffset}
									/>
								);
							})}
						</section>
					)}
				</section>
			</section>
		</section>
	);
}

// GetTodaysGroups().forEach((timeEntrySetGuid)=>
// <GroupNotesDropdown TimeEntrySetGuid={timeEntrySetGuid} />
// )
