export interface menuCoords {
	x: number;
	y: number;
}

export interface menuOption {
	display: string;
	callback: (e?: any) => void;
	disabled?: boolean;
}

export interface ContextMenuProps {
	onClose: () => void;
	contextMenuCoords: menuCoords;
	menuOptions: menuOption[];
	showContextMenu: boolean;
	origin: ContextMenuOrigin;
}

export enum ContextMenuOrigin {
	TopLeft,
	TopRight,
	BottomLeft,
	BottomRight,
}
