import React from "react";
import { MenuOptionProps } from "./MenuOption.Interface";
import "./MenuOption.scss";

export const MenuOption = (props: MenuOptionProps) => {
	return (
		/* Should this be a button or a div? */
		<div className={"menu-option " + props.icon} onClick={props.onClick}>
			{props.label}
		</div>
	);
};
