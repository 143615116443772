import React, { useState, useEffect, useRef } from "react";
import { EditableLabelProps } from "./EditableLabel.Interface";
import "./EditableLabel.scss";

export const EditableLabel = (props: EditableLabelProps) => {
	const [newValue, setNewValue] = useState<string>("");
	const [editing, setEditing] = useState<boolean>(() => (props.StartInEditing ? props.StartInEditing : false));
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		// setEditing(false);
		setNewValue(props.value);
	}, [props.value]);
	const labelContents = props.value !== "" ? props.value : props.placeholderLabel;
	const labelProps = {
		className: "name-label " + (props.value !== "" ? "" : "placeholder"),
		onClick: HandleOnClick,
		children: labelContents,
	};

	let labelElement = <p {...labelProps}></p>;
	switch (props.labelElement) {
		case "h1":
			labelElement = <h1 {...labelProps}></h1>;
			break;
		case "h2":
			labelElement = <h2 {...labelProps}></h2>;
			break;
	}

	function HandleOnClick() {
		if (!props.disableEditing) {
			setEditing(true);

			if (inputRef.current) {
				inputRef.current.focus();
			}
			if (props.SetEditingCallback) {
				props.SetEditingCallback(true);
			}
		}
	}

	function HandleOnBlur() {
		if (newValue !== props.value) {
			props.setValue(newValue);
		}
		setEditing(false);
		if (props.SetEditingCallback) {
			props.SetEditingCallback(false);
		}
	}

	function HandleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === "Enter") {
			e.preventDefault();
			e.target.dispatchEvent(new Event("blur", { cancelable: true }));
		}
	}

	// Click label to turn it into text box, which allows you to edit the label text.
	// NOTE: This is not at all accessible...
	return (
		<div className={"editable-label" + " " + props.className + " " + (editing ? "is-editing" : "")}>
			{editing && !(props.disableEditing === true) ? (
				<div className="editable-label-input-wrapper">
					<input
						className="editable-label-input-box"
						type="text"
						ref={inputRef}
						value={newValue}
						onChange={(event) => setNewValue(event.target.value)}
						placeholder={props.placeholderLabel}
						required
						onBlur={HandleOnBlur}
						onKeyPress={HandleKeyPress}
					/>
				</div>
			) : (
				labelElement
			)}
		</div>
	);
};
