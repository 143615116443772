import React from "react";
import { GlobalSettingsContextModel } from "./GlobalSettingsContextModel";
import { GlobalSettingsContextAction } from "./GlobalSettingsContextActions";
import { GlobalSettingsContextDispatchActionType } from "./GlobalSettingsContextDispatchActionType";
import { GlobalSettingsContextProviderProps } from "./GlobalSettingsContext.Interface";

const GlobalSettingsReducer = (state: GlobalSettingsContextModel, action: GlobalSettingsContextAction) => {
	switch (action.type) {
		case GlobalSettingsContextDispatchActionType.setCompactComments:
			return { ...state, compactComments: action.payload };
		case GlobalSettingsContextDispatchActionType.setDefaultBreakMinutes:
			return { ...state, defaultMinutesInBreak: action.payload };
		default:
			return { ...state };
	}
};

export const GlobalSettingsContext = React.createContext({
	state: new GlobalSettingsContextModel(),
	dispatch: {} as React.Dispatch<GlobalSettingsContextAction>,
});

export const GlobalSettingsContextProvider = (props: GlobalSettingsContextProviderProps) => {
	const [state, dispatch] = React.useReducer(GlobalSettingsReducer, {
		compactComments: false, // Change to true once compact comments details are sorted out
		defaultMinutesInBreak: 5,
		defaultGroupName: "Unlinked",
	});

	const value = { state, dispatch };

	return <GlobalSettingsContext.Provider value={value}>{props.children}</GlobalSettingsContext.Provider>;
};
