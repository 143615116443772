import React, { useState, useContext } from "react";
import "./TabMenu.scss";
import { TabOption } from "./TabOption/TabOption";
import { ViewContext } from "../../../Context/ViewContext/ViewContext";
import { ViewContextDispatchActionType } from "../../../Context/ViewContext/ViewContextDispatchActionType";
import { TimelineContext } from "../../../Context/TimelineContext/TimelineContext";
import { TimelineContextDispatchActionType } from "../../../Context/TimelineContext/TimelineContextDispatchActionType";

enum TabTypes {
	dashboard = "DASHBOARD",
	timeline = "TIMELINE",
	export = "EXPORT",
}

export const TabMenu = () => {
	const viewContext = useContext(ViewContext);
	const timelineContext = useContext(TimelineContext);
	const [activeTab, setActiveTab] = useState<TabTypes>(TabTypes.dashboard);

	function HomeTab() {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.DashboardView,
		});

		// This is also being done on dashboard render, but having it here as well means there is much less visual delay for the timers
		timelineContext.dispatch({
			type: TimelineContextDispatchActionType.SetCurrentDayOffset,
			payload: 0,
		});

		setActiveTab(TabTypes.dashboard);
	}

	function TimelineTab() {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.CommentsView,
		});

		setActiveTab(TabTypes.timeline);
	}

	function ExportTab() {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.ExportView,
		});

		setActiveTab(TabTypes.export);
	}

	return (
		<div className="tab-menu">
			<TabOption
				label="Dashboard"
				icon="fad fa-circle-notch"
				onClick={HomeTab}
				active={activeTab === TabTypes.dashboard}
			/>
			<TabOption
				label="Comments"
				icon="fas fa-comment"
				onClick={TimelineTab}
				active={activeTab === TabTypes.timeline}
			/>
			<TabOption label="Export" icon="fas fa-cloud" onClick={ExportTab} active={activeTab === TabTypes.export} />
		</div>
	);
};
