import React, { useContext } from "react";
import { DashboardTaskSummaryProps } from "./DashboardTaskSummary.Interface";
import "./DashboardTaskSummary.scss";
import { useGroups } from "../../../Hooks/useGroups";
import nameof from "ts-nameof.macro";
import { useTasks } from "../../../Hooks/useTasks";
import { KeyHelper } from "../../../Data/KeyHelper";
import { TheHook } from "../../../Hooks/TheHook/TheHook";
import { TaskSummaryHeader } from "./TaskSummaryHeaders/TaskSummaryHeader";
import { TaskSummaryDetails } from "./TaskSummary/TaskSummaryDetails";
import { useTimeEntries } from "../../../Hooks/useTimeEntries";
import { ModalContextDispatchActionType } from "../../../Context/ModalContext/ModalContextDispatchActionType";
import { ModalContext } from "../../../Context/ModalContext/ModalContext";
import { TasklistModal } from "../TasklistModal/TasklistModal";
import { useTimerCalulatedData } from "../../../Hooks/useTimerCalulatedData";
import { List } from "immutable";

export const DashboardTaskSummary = (props: DashboardTaskSummaryProps) => {
	const Groups = useGroups(nameof(DashboardTaskSummary));
	const TimeEntries = useTimeEntries(nameof(DashboardTaskSummary));
	const Tasks = useTasks(nameof(DashboardTaskSummary));
	const theHook = TheHook(nameof(DashboardTaskSummary));
	const modalContext = useContext(ModalContext);

	function GetTask() {
		if (props.groupGuid) {
			const myGroup = Groups.Get(props.groupGuid);

			if (myGroup) {
				return Tasks.Get(KeyHelper.GetTimeEntrySetTaskKey(myGroup));
			}
		}
	}

	function UnlinkTask() {
		if (props.groupGuid) {
			theHook.UnlinkGroup(props.groupGuid);
		}
	}

	function CheckIfLinked() {
		if (props.groupGuid) {
			const myGroup = Groups.Get(props.groupGuid);

			if (myGroup && myGroup.taskExternalId) {
				return true;
			}
		}

		return false;
	}

	function GetTags() {
		const task = GetTask();

		if (task) {
			return theHook.GetTaskTags(task);
		}
		return List();
	}

	const { currentTimeEntryGUID } = useTimerCalulatedData(nameof(DashboardTaskSummary));

	function OpenTaskListModal() {
		if (currentTimeEntryGUID) {
			const currentTimeEntry = TimeEntries.Get(currentTimeEntryGUID);

			if (currentTimeEntry) {
				const currentGroup = Groups.Get(currentTimeEntry.timeEntrySetGuid);

				if (currentGroup) {
					modalContext.dispatch({
						type: ModalContextDispatchActionType.SetModalComponent,
						payload: <TasklistModal timeEntrySetGuid={currentGroup.timeEntrySetGuid} />,
					});

					modalContext.dispatch({
						type: ModalContextDispatchActionType.OpenModal,
						payload: undefined,
					});
				}
			}
		}
	}

	return (
		<>
			{props.groupGuid ? (
				<>
					{CheckIfLinked() ? (
						<section className="task-header-wrapper">
							<div className="task-header-container">
								<TaskSummaryHeader Task={GetTask()} OpenTaskListModal={OpenTaskListModal} />
							</div>
						</section>
					) : (
						<button className="select-task" onClick={props.openTaskList}>
							<span className="icon"></span>
							Select a task
						</button>
					)}
				</>
			) : (
				<></>
			)}

			<section className="task-details-wrapper">
				{props.groupGuid ? (
					<div className="task-details-container">
						{CheckIfLinked() ? <TaskSummaryDetails Task={GetTask()} UnlinkTask={UnlinkTask} tags={GetTags()} /> : <></>}
					</div>
				) : (
					<></>
				)}
			</section>
		</>
	);
};
