import { Source } from "../Data/Source";
import { TheClaw } from "../Data/TheClaw";
import { IUseSubscribableCollectionType, useSubscribableCollection } from "./useSubscribable";
import { ITaskTagLink } from "../Data/Models/ITaskTagLink";
export interface IUseTaskTagLinks extends IUseSubscribableCollectionType<ITaskTagLink> {}
/**
 *
 * Causes re-render when any item in Tasks changes
 * @param source
 */
export function useTaskTagLinks(source?: Source) {
	const subscribable = useSubscribableCollection(TheClaw.TaskTagLinks, source);
	return {
		...subscribable,
	};
}
