import React from "react";
import "./ExportView.scss";
import { ExportPage } from "../../../Containers/ExportPage/ExportPage";

export function ExportView() {
	return (
		<>
			<section className="export-page">
				<ExportPage />
			</section>
		</>
	);
}
