export function GetRelativeClickCoordinates(e: MouseEvent, ancestorElem: HTMLElement) {
	const elem = e.target as HTMLElement;
	const boundingRect = elem.getBoundingClientRect();
	const inElemClickCoords = { x: e.pageX - boundingRect.x, y: e.pageY - boundingRect.y };
	const offset = GetElementOffsetDiff(elem, ancestorElem);
	return { x: offset.left + inElemClickCoords.x, y: offset.top + inElemClickCoords.y };
}

export function GetElementOffsetDiff(elem: HTMLElement, ancestorElem: HTMLElement) {
	let currentElem = elem;
	let left = 0;
	let top = 0;
	while (!currentElem.contains(ancestorElem) && currentElem.offsetParent !== null) {
		left += currentElem.offsetLeft;
		top += currentElem.offsetTop;
		currentElem = currentElem.offsetParent as HTMLElement;
	}
	return { left, top };
}
