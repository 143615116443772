import { DateTime } from "luxon";
import { useState } from "react";
import { useInterval } from "./useInterval";
import { useWindowFocus } from "./useWindowFocus";
import { InstanceManager } from "../Data/InstanceManager";

export function useMidnight(callback: () => unknown, dayStartOffsetHours: number) {
	const [newDate, setNewDate] = useState<DateTime>(() => InstanceManager.timeSource.GetLocalTime()); // After reaching midnight it starts the loop again.

	const check = () => {
		if (
			InstanceManager.timeSource.GetLocalTime() >
			newDate
				.toLocal()
				.endOf("day")
				.plus({ hours: dayStartOffsetHours })
		) {
			callback();
			setNewDate(InstanceManager.timeSource.GetLocalTime());
		}
	};

	useWindowFocus(check);
	useInterval(check, 60000);
}
