import { EditHistoryModel } from "../../Hooks/useHistory/useHistory";

export class UndoRedoContextModel {
	public past?: EditHistoryModel[];
	public present?: EditHistoryModel | undefined;
	public future?: EditHistoryModel[];
	constructor(mcm?: Partial<UndoRedoContextModel>) {
		Object.assign(this, mcm);
	}
}
