import { ITimeEntrySet } from "../../Data/Models/ITimeEntrySet";
import { ISearchResult } from "../../Data/Models/ISearchResult";

export class GroupTaskModalContextModel {
	modalGroup?: ITimeEntrySet;
	modalTask?: ISearchResult;
	modalDayOffset?: number;
	constructor(mcm?: Partial<GroupTaskModalContextModel>) {
		Object.assign(this, mcm);
	}
}
