import React, { useContext, useState, useEffect } from "react";
import "./ExportPage.scss";
import { ExportViewHeader } from "../../Components/Library/ExportViewHeader/ExportViewHeader";
import { TheHook } from "../../Hooks/TheHook/TheHook";
import nameof from "ts-nameof.macro";
import { GroupExportRecord } from "../../Components/Library/GroupExportRecord/GroupExportRecord";
import { useTasks } from "../../Hooks/useTasks";
import { KeyHelper } from "../../Data/KeyHelper";
import { useTimeEntries } from "../../Hooks/useTimeEntries";
import { ExportContext } from "../../Context/ExportContext/ExportContext";
import { ITimeEntrySet } from "../../Data/Models/ITimeEntrySet";
import { DateTime } from "luxon";
import { ExportContextDispatchActionType } from "../../Context/ExportContext/ExportContextActionType";
import { Guid } from "../../Data/Guid";
import { ITask } from "../../Data/Models/ITask";
import { useTimeControls } from "../../Hooks/useTimeControls";
import { useGroups } from "../../Hooks/useGroups";
import { ViewContext } from "../../Context/ViewContext/ViewContext";
import { TimelineContext } from "../../Context/TimelineContext/TimelineContext";
import { ViewContextDispatchActionType } from "../../Context/ViewContext/ViewContextDispatchActionType";
import { TimelineContextDispatchActionType } from "../../Context/TimelineContext/TimelineContextDispatchActionType";
import { DateSeparator } from "./DateSeparator/DateSeparator";
import { useTimerCalulatedData } from "../../Hooks/useTimerCalulatedData";
import { List } from "immutable";
import { InstanceManager } from "../../Data/InstanceManager";

// TODO: Don't add Guids of exporting groups to selected groups for exporting.
// TODO: Check for last changed date and use that to put groups that have been changed since last export back into export page.

export function ExportPage() {
	const exportContext = useContext(ExportContext);
	const viewContext = useContext(ViewContext);
	const timelineContext = useContext(TimelineContext);
	const Tasks = useTasks(nameof(ExportPage));
	const Groups = useGroups(nameof(ExportPage));
	const theHook = TheHook(nameof(ExportPage));
	const TimeEntries = useTimeEntries(nameof(ExportPage));
	const [exportableGroupGuids] = useState<Set<Guid>>(new Set());
	const [exporting] = useState(false);
	const timeControls = useTimeControls(nameof(ExportPage));

	const { isPlaying, currentTimeEntryGUID } = useTimerCalulatedData(nameof(ExportPage));

	const [exportableTimeEntrySets, setExportableTimeEntrySets] = useState<List<ITimeEntrySet> | undefined>();
	const currentTimeEntry = currentTimeEntryGUID ? TimeEntries.Get(currentTimeEntryGUID) : undefined;
	let previousGroupStartedWhen: DateTime;

	useEffect(() => {
		setExportableTimeEntrySets(
			Groups.AllNotDeleted()
				.filter(
					(group) =>
						!group.lastExportedWhen ||
						group.lastExportedWhen < group.lastUpdatedWhen ||
						(group.queuedForExportWhen && group.queuedForExportWhen > group.lastExportedWhen)
				)
				.sort((a, b) => GetGroupStartedWhen(a).diff(GetGroupStartedWhen(b)).milliseconds)
		);
	}, []);

	useEffect(() => {
		if (currentTimeEntry) {
			UnselectExportRecord(currentTimeEntry.timeEntrySetGuid);

			if (isPlaying) {
				exportableGroupGuids.delete(currentTimeEntry.timeEntrySetGuid);
			} else {
				exportableGroupGuids.add(currentTimeEntry.timeEntrySetGuid);
			}
		}
	}, [isPlaying, currentTimeEntry]);

	function SelectAll() {
		exportableGroupGuids.forEach((er) => {
			SelectExportRecord(er);
		});
	}

	function UnselectAll() {
		exportableGroupGuids.forEach((er) => {
			UnselectExportRecord(er);
		});
	}

	function SelectExportRecord(groupGuid: Guid) {
		exportContext.dispatch({
			type: ExportContextDispatchActionType.AddExportRecord,
			payload: groupGuid,
		});
	}

	function UnselectExportRecord(groupGuid: Guid) {
		exportContext.dispatch({
			type: ExportContextDispatchActionType.RemoveExportRecord,
			payload: groupGuid,
		});
	}

	function GetCombinedGroupComments(timeEntrySet: ITimeEntrySet) {
		return TimeEntries.AllNotDeleted()
			.filter((timeEntry) => timeEntry.timeEntrySetGuid === timeEntrySet.timeEntrySetGuid)
			.sort((a, b) => a.startedWhen.hour + a.startedWhen.minute / 60 - (b.startedWhen.hour + b.startedWhen.minute / 60))
			.map((x) => x.comment)
			.filter((x) => x && x.length > 0)
			.join("\n")
			.replace(/\n{3,}/g, "\n\n");
	}

	function ExportSelected() {
		// setExporting(!exporting);

		exportContext.state.selectedExportRecords.forEach((groupGuid) => {
			theHook.MarkGroupForExport(groupGuid, timeControls.GetTotalGroupTime(groupGuid, -1, true));
		});

		exportContext.dispatch({
			type: ExportContextDispatchActionType.ClearExportRecord,
			payload: "",
		});

		// Need to wait for the export then re-enable selecting
	}

	function ExportThisGroup(groupGuid: Guid) {
		theHook.MarkGroupForExport(groupGuid, timeControls.GetTotalGroupTime(groupGuid, -1, true));

		exportContext.dispatch({
			type: ExportContextDispatchActionType.RemoveExportRecord,
			payload: groupGuid,
		});
	}

	function MarkGroupAsExported(groupGuid: Guid) {
		theHook.MarkGroupAsExported(groupGuid);
	}

	function OpenCommentsViewAtGroup(groupGuid: Guid) {
		const groupsEntries = TimeEntries.GetForGroup(groupGuid);

		const firstGroup = groupsEntries.first(undefined);
		if (firstGroup) {
			timelineContext.dispatch({
				type: TimelineContextDispatchActionType.SetCurrentDayOffset,
				payload: InstanceManager.timeSource
					.GetLocalTime()
					.startOf("day")
					.diff(firstGroup.startedWhen.toLocal().startOf("day"), "days").days,
			});

			viewContext.dispatch({
				type: ViewContextDispatchActionType.CommentsView,
			});
		}
	}

	function GetTags(task: ITask) {
		return theHook.GetTaskTags(task);
	}

	function GetGroupStartedWhen(group: ITimeEntrySet) {
		const groupTimeEntries = TimeEntries.GetForGroup(group.timeEntrySetGuid);
		const earliestEntry = groupTimeEntries
			.sort((a, b) => a.startedWhen.diff(b.startedWhen).milliseconds)
			.first(undefined);
		return earliestEntry?.startedWhen || InstanceManager.timeSource.GetLocalTime();
	}

	return (
		<>
			<ExportViewHeader
				SelectAll={SelectAll}
				UnselectAll={UnselectAll}
				groupGuids={exportableGroupGuids}
				exporting={exporting}
			/>
			<section className="export-content-wrapper">
				{exportableTimeEntrySets
					? exportableTimeEntrySets.map((group) => {
							const task = Tasks.Get(KeyHelper.GetTimeEntrySetTaskKey(group));

							// Only groups with tasks can be exported, for now no unlinked groups will show up in the export page (this will likely change)
							// Currently this is very redundant, the same checks are being made here, and within the group export record component, this will be fixed.
							if (task) {
								if (theHook.CheckGroupIsExportable(group)) {
									exportableGroupGuids.add(group.timeEntrySetGuid);
								}

								const groupExportRecord = (
									<GroupExportRecord
										MyTimeEntrySetGuid={group.timeEntrySetGuid}
										MyTask={task}
										currentTimeEntry={currentTimeEntry}
										SelectExportRecord={SelectExportRecord}
										UnselectExportRecord={UnselectExportRecord}
										comment={GetCombinedGroupComments(group)}
										ExportThisGroup={ExportThisGroup}
										MarkGroupAsExported={MarkGroupAsExported}
										OpenCommentsViewAtDate={OpenCommentsViewAtGroup}
										tags={GetTags(task)}
									/>
								);

								const groupStartTime = GetGroupStartedWhen(group);
								let separator;
								// Issue is that it's not using the indexs of only the relevant groups, but all groups. So I need to separate out the previous created when.
								// Beware: hasSame uses the timezone of the left-hand value
								// See https://github.com/moment/luxon/issues/584
								if (
									groupStartTime &&
									(!previousGroupStartedWhen || !previousGroupStartedWhen.hasSame(groupStartTime, "day"))
								) {
									previousGroupStartedWhen = groupStartTime;

									separator = (
										<DateSeparator
											groupGuid={group.timeEntrySetGuid}
											OpenCommentsViewAtGroup={OpenCommentsViewAtGroup}
											groupStartTime={groupStartTime}
										/>
									);
								}

								return (
									<React.Fragment key={"task-export-record-" + group.timeEntrySetGuid}>
										{separator} {groupExportRecord}
									</React.Fragment>
								);
							}
					  })
					: undefined}
			</section>
			<button
				className="export-button"
				onClick={ExportSelected}
				disabled={exportContext.state.selectedExportRecords.size === 0 || exporting}
			>
				{exporting ? <div className="spinner"></div> : <div className="export-button-label">EXPORT</div>}
			</button>
		</>
	);
}
