import React from "react";
import { UndoRedoContextModel } from "./UndoRedoContextModel";
import { UndoRedoContextAction } from "./UndoRedoContextAction";
import { UndoRedoContextDispatchActionType } from "./UndoRedoContextDispatchActionType";
import { UndoRedoContextProviderProps } from "./UndoRedoContext.Interface";
import { EditHistoryModel } from "../../Hooks/useHistory/useHistory";

export const UndoRedoContext = React.createContext({
	state: new UndoRedoContextModel(),
	dispatch: {} as React.Dispatch<UndoRedoContextAction>,
});

function UndoRedoReducer(state: UndoRedoContextModel, action: UndoRedoContextAction) {
	switch (action.type) {
		case UndoRedoContextDispatchActionType.Undo:
			if (state.past) {
				const previous = state.past[state.past.length - 1];
				const newPast = state.past.slice(0, state.past.length - 1);

				return {
					...state,
					past: newPast,
					present: previous as EditHistoryModel | undefined,
					future: state.future
						? state.present
							? [state.present, ...state.future]
							: state.future
						: state.present
						? [state.present]
						: [],
				};
			}

			return state;

		case UndoRedoContextDispatchActionType.Redo:
			if (state.future) {
				const next = state.future[0];
				const newFuture = state.future.slice(1);

				return {
					...state,
					past: state.past
						? state.present
							? [...state.past, state.present]
							: state.past
						: state.present
						? [state.present]
						: [],
					present: next as EditHistoryModel | undefined,
					future: newFuture,
				};
			}

			return state;
		case UndoRedoContextDispatchActionType.Set:
			const newPresent = action.payload;

			if (newPresent) {
				if (newPresent === state.present) {
					console.debug("new state was the same as current");
					return state;
				}

				return {
					...state,
					past: state.past
						? state.present
							? [...state.past, state.present]
							: state.past
						: state.present
						? [state.present]
						: [],
					present: newPresent as EditHistoryModel | undefined,
					future: [] as EditHistoryModel[],
				};
			}

			return state;

		case UndoRedoContextDispatchActionType.Clear:
			return {
				...state,
				past: [] as EditHistoryModel[],
				present: undefined as EditHistoryModel | undefined,
				future: [] as EditHistoryModel[],
			};

		default:
			return state;
	}
}

export const UndoRedoContextProvider = (props: UndoRedoContextProviderProps) => {
	const [state, dispatch] = React.useReducer(UndoRedoReducer, {
		past: [],
		present: undefined,
		future: [],
	});

	const value = { state, dispatch };

	return <UndoRedoContext.Provider value={value}>{props.children}</UndoRedoContext.Provider>;
};
