import React from "react";
import "./LiveTimeLabel.scss";
import { LiveTimeLabelForEntriesProps } from "./LiveTimeLabelForEntries.Interface";
import nameof from "ts-nameof.macro";
import { useCompressedTimeEntryTime } from "./useCompressedTimeEntryTime";

export const LiveTimeLabelForEntries = (props: LiveTimeLabelForEntriesProps) => {
	const { totalTime, timeUnitIndicator } = useCompressedTimeEntryTime(
		props.compressedTimeEntry,
		nameof(LiveTimeLabelForEntries)
	);

	return (
		<>
			<span className="total-time">{totalTime}</span>
			<span className="time-unit-indicator">{timeUnitIndicator}</span>
		</>
	);
};
