import { TimeApiConnection } from "./TimeApiConnection";
import { IntegrationApiConnection } from "./IntegrationApiConnection";
import { TimeHubConnection } from "./TimeHubConnection";
import { TimeHubClientMethods } from "./TimeHubClientMethods";
import { SyncManager } from "./SyncManager";
import { AuthManager } from "./AuthManager";
import { ChronometricDB } from "./ChronometricDB";
import { TimeSource } from "./TimeSource";

export class InstanceManager {
	public static readonly int = new IntegrationApiConnection();
	public static readonly timeHub = new TimeHubConnection(new TimeHubClientMethods());
	public static readonly timeApi = new TimeApiConnection(InstanceManager.timeHub);
	public static readonly timeSource = new TimeSource(InstanceManager.timeApi);
	public static readonly syncManager = new SyncManager(
		InstanceManager.timeApi,
		InstanceManager.int,
		InstanceManager.timeSource
	);

	public static readonly db = new ChronometricDB();

	public static readonly auth = new AuthManager(InstanceManager.timeSource);

	public static serviceWorker: ServiceWorker | null = null;
}

declare global {
	interface Window {
		im: InstanceManager;
	}
}

window.im = InstanceManager;
