import { ITimeEntrySet } from "./Models/ITimeEntrySet";
import { ITimeEntry } from "./Models/ITimeEntry";
import { ITaskMetadata } from "./Models/ITaskMetadata";
import { TimeHubConnection } from "./TimeHubConnection";
import { IUpdateTimeDataRequestModel } from "./Models/IUpdateTimeDataRequestModel";
import { ensureTimeEntrySetDates, ensureTimeEntryDates, ensureTaskMetadataDates, toUtcDate } from "./ChronometricDB";
import { Instant } from "./Instant";

export class TimeApiConnection {
	constructor(private readonly timeHub: TimeHubConnection) {}

	public async GetTimeEntrySets(from: Instant, clientId?: string) {
		const timeEntrySets = await this.timeHub.ListTimeEntrySetsAsync(from, clientId);

		return timeEntrySets.map(ensureTimeEntrySetDates);
	}
	public async GetTimeEntrySetsV2(from: Instant) {
		const timeEntrySets = await this.timeHub.ListTimeEntrySetsAsyncV2(from);
		timeEntrySets.items.forEach(ensureTimeEntrySetDates);
		return timeEntrySets;
	}
	public async SetTimeEntrySets(items: ITimeEntrySet[]) {
		await this.timeHub.UpsertTimeEntrySetAsync({ items });
	}
	public async GetTimeEntries(from: Instant, clientId?: string) {
		const timeEntries = await this.timeHub.ListTimeEntriesAsync(from, clientId);
		return timeEntries.map(ensureTimeEntryDates);
	}
	public async SetTimeEntries(items: ITimeEntry[]) {
		await this.timeHub.UpsertTimeEntryAsync({ items });
	}

	public async GetTaskMetadata(from: Instant, clientId?: string) {
		const taskMetadatas = await this.timeHub.ListTaskMetadataAsync(from, clientId);
		return taskMetadatas.map(ensureTaskMetadataDates);
	}
	public async SetTaskMetadata(items: ITaskMetadata[]) {
		await this.timeHub.UpsertMetadataAsync({ items });
	}

	public async UpdateTimeData(request: IUpdateTimeDataRequestModel) {
		await this.timeHub.UpdateTimeDataAsync(request);
	}

	public async GetServerTime() {
		return toUtcDate(await this.timeHub.GetServerTimeAsync())!;
	}
}
