import React, { useState, useEffect, useContext } from "react";
import "./RoundingSettingsView.scss";
import { ViewContext } from "../../../Context/ViewContext/ViewContext";
import { ViewContextDispatchActionType } from "../../../Context/ViewContext/ViewContextDispatchActionType";
import { useForm } from "../../../Hooks/Forms/useForm";
import { useField } from "../../../Hooks/Forms/useField";
import { Field } from "../../Library/Field";
import { useIntegrationSettings } from "../../../Hooks/useIntegrationSettings";
import nameof from "ts-nameof.macro";
import { IntegrationButton } from "./IntegrationButton";
import { IIntegrationSetting } from "../../../Data/Models/IIntegrationSetting";

// NOTE: This view is temporary and is only until the account screen (and thus integration settings) are completed.

export function RoundingSettingsView() {
	const IntegrationSettings = useIntegrationSettings(nameof(RoundingSettingsView));
	const viewContext = useContext(ViewContext);
	const [selectedIntegrationSetting, setSelectedIntegrationSetting] = useState<IIntegrationSetting | undefined>(
		undefined
	);

	useEffect(() => {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.HideTabs,
		});
	}, []);

	function Close() {
		viewContext.dispatch({
			type: ViewContextDispatchActionType.DashboardView,
		});
	}

	function SetSelectedIntegration(integrationGuid: string | undefined) {
		if (integrationGuid) {
			setSelectedIntegrationSetting(IntegrationSettings.Get(integrationGuid));
		} else {
			setSelectedIntegrationSetting(undefined);
		}
	}

	const form = useForm({
		onSubmit: (formData, valid) => {
			if (!valid) return;
			if (selectedIntegrationSetting) {
				IntegrationSettings.Set(selectedIntegrationSetting.integrationGuid, {
					...selectedIntegrationSetting,
					roundingInterval: parseInt(formData.roundingInterval) * 60,
					roundingThreshold: parseInt(formData.roundingThreshold) * 60,
				});
			} else {
				throw Error("Should not be able to set integration setting without selecting one first");
			}

			SetSelectedIntegration(undefined);
		},
	});

	const numberRegex = new RegExp(/^\d+(\.\d{1,2})?$/);

	const roundingIntervalField = useField("roundingInterval", form, {
		defaultValue: "",
		validations: [
			(formData) => {
				return (
					(!numberRegex.test(formData.roundingInterval) || formData.roundingInterval.length === 0) &&
					"Interval must be a number"
				);
			},
		],
		fieldsToValidateOnChange: [],
	});

	const roundingThresholdField = useField("roundingThreshold", form, {
		defaultValue: "",
		validations: [
			(formData) => {
				return (
					(!numberRegex.test(formData.roundingThreshold) || formData.roundingInterval.length === 0) &&
					"Threshold must be a number"
				);
			},
		],
		fieldsToValidateOnChange: [],
	});

	return (
		<section className="rounding-settings-screen">
			<button className="rounding-settings-close" onClick={Close}>
				Close
			</button>
			<div className="integration-settings-container">
				{IntegrationSettings.All().map((i) => {
					return (
						<IntegrationButton
							integrationGuid={i.integrationGuid}
							selectedIntegration={selectedIntegrationSetting ? selectedIntegrationSetting.integrationGuid : undefined}
							SetSelectedIntegration={SetSelectedIntegration}
						/>
					);
				})}
			</div>
			{selectedIntegrationSetting ? (
				<div className="integration-fields-container">
					<div className="integration-label">
						Setting Rounding Settings for: {selectedIntegrationSetting.integrationGuid}
					</div>
					<form className="rounding-settings-form" onSubmit={form.onSubmit}>
						<Field
							type="input"
							label="Rounding Minutes Interval: "
							{...roundingIntervalField}
							formSubmitted={form.submitted}
							className="rounding-interval-input"
							placeHolder={
								selectedIntegrationSetting
									? selectedIntegrationSetting.roundingInterval
										? (selectedIntegrationSetting.roundingInterval / 60).toFixed(1).toString()
										: undefined
									: undefined
							}
							value={undefined}
						/>
						<br />
						<Field
							type="input"
							label="Rounding Minutes Threshold: "
							{...roundingThresholdField}
							formSubmitted={form.submitted}
							className="rounding-threshold-input"
							placeHolder={
								selectedIntegrationSetting
									? selectedIntegrationSetting.roundingThreshold
										? (selectedIntegrationSetting.roundingThreshold / 60).toFixed(1).toString()
										: undefined
									: undefined
							}
							value={undefined}
							disabled={!(roundingIntervalField.value && form.isValid())}
						/>
						<button
							className="rounding-settings-button button primary pill sz-2"
							type="submit"
							disabled={!form.isValid}
						>
							Save
						</button>
					</form>
				</div>
			) : (
				<></>
			)}
		</section>
	);
}
